import React , {useState} from 'react';

const LoginDataContext = React.createContext();

function LoginDataProvider({ children }) {
    const [loginData, setLoginData] = useState({
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      email: '',
      number: '',
      gender: '',
      sexAtBirth:  '',
      goBackStep: false,
      pinCode: '',
      verificationSuccess: false,
      isError: true,
      resendPin: false,
    });
  
    return (
      <LoginDataContext.Provider value={{ loginData, setLoginData }}>
        {children}
      </LoginDataContext.Provider>
    );
  }
  
  export { LoginDataContext, LoginDataProvider };
