import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { CircularProgress, styled } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { authService } from '../../../utils/auth';
import { LOGIN_ROUTE } from '../../../utils/route.name';
import { Button, Box } from '@mui/material';

const styles = () => ({
  fullscreen: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    right: '0',
    bottom: '0',
    minWidth: '100%',
    minHeight: '100%',
    backgroundColor: '#ffffff',
    margin: '0 auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    margin: '0 auto',
    height: '80px',
  },
  progress: {
    color: '#F29202',
  },
  message: {
    paddingLeft: '24px',
    color: '#ccc',
  },
});

class Loading extends Component {
  timer = null;

  state = {
    renderRestart: false,
  };

  componentDidMount = () => {
    this.timer = setTimeout(() => {
      this.setState({ renderRestart: true });
    }, 10000);
  };

  componentWillUnmount = () => {
    clearTimeout(this.timer);
  };

  handleStart = () => {
    sessionStorage.clear();
    authService.logout();
    this.props.history.push(LOGIN_ROUTE);
    window.location.reload();
  };

  render() {
    let { classes, message } = this.props;
    let { renderRestart } = this.state;

    return (
      <div className={classes.fullscreen}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <CircularProgress classes={{ circle: classes.progress }} />
          {message && <p className={classes.message}>{message}</p>}
        </Box>
        {renderRestart && (
          <Button
            sx={{ marginTop: '3rem', color: '#2D8088' }}
            onClick={this.handleStart}
            aria-label="Restart application"
          >
            Sorry there seems to be an issue. click to Restart
          </Button>
        )}
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Loading));
