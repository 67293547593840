import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { PRE_AUTH_QUINN_ROUTE } from "../../../../utils/route.name";
import { globalBloc } from "../../../global.bloc";
import { LandingOrgSelectorBloc } from "./LandingOrgSelector.bloc";
import { analyticsEventLogger } from "../../../../utils/events";
import CardTitle from "./components/CardTitle";
import WalkInAvailability from "./components/WalkInAvailability";
import ReservationAvailability from "./components/ReservationAvailability";
import DecodedButton from "../../../common/DecodedButton";
import EmergencyAction from "./components/EmergencyAction";
import { FormattedMessage } from "react-intl";
import { getInLineUtil } from "../../../../utils/getInLineUtils";
import {
  LandingOrgCardWrapper,
  LoadingCard,
  StyledCardBody,
} from "./components/StyledComponents";

const LandingOrgCard = (props) => {
  const { organisation, setSelected, isHovered } = props;

  const history = useHistory();

  const getInLineIsCutOff = getInLineUtil.isCutOff();

  const [bloc] = useState(
    () => new LandingOrgSelectorBloc({ analyticsLogger: analyticsEventLogger })
  );

  const [state, setState] = useState(() => ({
    ...bloc.subject.value,
  }));

  const {
    reservationSlot,
    walkinSlot,
    firstAvailableCapacity,
    estimatedWaitTime,
    getInLineAvailable,
    loading,
  } = state;

  useEffect(() => {
    const subscription = bloc.subscribeToState((state) => {
      setState({ ...state });
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [bloc]);

  useEffect(() => {
    globalBloc.updateGlobalBloc({ getInLine: false });

    if (organisation.getInLineEnabled) {
      bloc.getInLineSchedule(organisation.id);
    } else {
      bloc.getWalkInSchedule(organisation.id);
    }

    bloc.reservationSchedule(organisation.id);
  }, []);

  const handleMakeReservation = () => {
    const { firstAvailableCapacity, firstAvailableDate } = state;
    setSelected({
      organisation,
      firstAvailableCapacity: firstAvailableCapacity,
      firstAvailableDate: firstAvailableDate,
      metadata: "added by handleMakeReservation",
    });

    setTimeout(() => {
      history.push(PRE_AUTH_QUINN_ROUTE);
    }, 1000);
  };

  const handleGetInLine = () => {
    const { firstWalkinSlot, availablitiy, walkinFirstAvailableCapacity } =
      state;
    globalBloc.updateGlobalBloc({ getInLine: true });

    setSelected({
      organisation,
      ...firstWalkinSlot,
      availability: availablitiy,
      selectedSlot: firstWalkinSlot.slots[0],
      firstAvailableCapacity: walkinFirstAvailableCapacity,
      firstAvailableDate: firstWalkinSlot.slots[0].startDatetime,
      metadata: "added by handleGetInLine",
    });

    setTimeout(() => {
      history.push(PRE_AUTH_QUINN_ROUTE);
    }, 1000);
  };

  return (
    <LandingOrgCardWrapper ishovered={isHovered} variant="outlined">
      {loading ? (
        <LoadingCard />
      ) : (
        <>
          <CardTitle organisation={organisation} />
          <StyledCardBody>
            <WalkInAvailability
              organisation={organisation}
              walkinSlot={walkinSlot}
              getInLineAvailable={getInLineAvailable}
              estimatedWaitTime={estimatedWaitTime}
            />
            {getInLineAvailable && (
              <FormattedMessage
                id={
                  !getInLineIsCutOff
                    ? "landing.get_in_line"
                    : "landing.get_in_line.start"
                }
                values={{
                  lineBreak: <br />,
                  startTime: getInLineUtil.startTimeDisplay(),
                }}
                defaultMessage={
                  !getInLineIsCutOff
                    ? `GET IN LINE NOW...{lineBreak}WAIT WHERE YOU WANT`
                    : "Virtual line will be available tomorrow at {startTime}"
                }
              >
                {(txt) => (
                  <DecodedButton
                    variant="contained"
                    onClick={handleGetInLine}
                    children={txt}
                    disabled={getInLineIsCutOff}
                  />
                )}
              </FormattedMessage>
            )}
            {!organisation.getInLineEnabled ? (
              <>
                <ReservationAvailability reservationSlot={reservationSlot} />
                <Grid item xs={12}>
                  {firstAvailableCapacity !== "not set" && (
                    <DecodedButton
                      sx={{
                        marginTop: "auto",
                      }}
                      onClick={handleMakeReservation}
                      disabled={firstAvailableCapacity === "full"}
                    >
                      {firstAvailableCapacity === "full"
                        ? "NOT AVAILABLE"
                        : "MAKE A RESERVATION"}
                    </DecodedButton>
                  )}
                </Grid>
              </>
            ) : (
              <EmergencyAction />
            )}
          </StyledCardBody>
        </>
      )}
    </LandingOrgCardWrapper>
  );
};

export default LandingOrgCard;
