import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { notificationService } from '../../../utils/notification';
import { withRouter } from 'react-router-dom';
import PageContainer from '../../common/PageContainer';
import { ReactComponent as DirectionIcon } from '../../../assets/direction.svg';
import { Button, Grid, Link } from '@mui/material';
import { setSession, uriStorage } from '../../../utils/storage';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { authService } from '../../../utils/auth';
import { BOOKING_LIST_ROUTE, ROOT_ROUTE, routeUtil } from '../../../utils/route.name';
import { phoneUtil } from '../../../utils/phone';
import { dateUtil } from '../../../utils/date';
import { providerStorage } from '../../../utils/provider.qs';
import {
  CancellationBloc,
  CancellationBlocBlocEvent,
} from '../../shared/AppointmentCancelDialog/cancellation.bloc';
import ScrollableContainer from '../../common/ScrollableContainer';
import DecodedButton from '../../common/DecodedButton';
import DecodedComponent from '../../shared/DecodedComponent';
import { Bloc, BlocEvent } from './bloc';
import { AppointmentCard } from '../../shared/AppointmentCard';
import AppointmentCancelDialog from '../../shared/AppointmentCancelDialog';
import { FormattedMessage } from 'react-intl';
import { styles, H1TextTitle, TextBodyBoldSecondary, TextBody } from './booking-status-styles';
import GetInLineAppointmentCard from '../../shared/AppointmentCard/GetInLineAppointmentCard';
import { addMinutes, isBefore } from 'date-fns';

class BookingStatus extends DecodedComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      checkinSuccess: false,
      checkinAvailable: false,
      queueNumber: '_',
      waitTime: 'unknown',
      provider: '',
      isWalkIn: sessionStorage.getItem('currentProvider'),
      isCancelled: false,

      dialogOpen: false,
    };
    this.bloc = new Bloc(this._getCurrentAppointmentId());
    this.cancelBloc = new CancellationBloc();
    this._handleCancelEvent = this._handleCancelEvent.bind(this);
    analyticsEventLogger.log(AnalyticsEvent.BOOKING_STATUS);
  }

  componentDidMount() {
    super.componentDidMount();
    this.cancelBlocEventSubscription = this.cancelBloc.subscribeToEvents(this._handleCancelEvent);
    uriStorage.setCurrentPath(this.props.match.url);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.cancelBlocEventSubscription?.unsubscribe();
  }

  __handleEvent = (event) => {
    const { type, data } = event;

    if (BlocEvent.NAVIGATE_TO === type) {
      this.props.history.push(data.url);
    }
  };

  _handleCancelEvent = (e) => {
    const { type } = e;
    const { isFullLogin } = this.state;

    switch (type) {
      case CancellationBlocBlocEvent.APPOINTMENT_CANCELLING:
        this.setState({
          loading: true,
        });
        break;
      case CancellationBlocBlocEvent.APPOINTMENT_CANCELLED_SUCCESS:
        this.setState({
          loading: false,
          isCancelled: true,
        });
        notificationService.success('You reservation has been cancelled.');
        setTimeout(() => {
          this._handleCancelLogOut();
        }, 5000);
        break;
      case CancellationBlocBlocEvent.APPOINTMENT_CANCELLED_ERROR:
        this.setState({
          loading: false,
        });
        break;
      default:
        break;
    }
  };

  _handleCancelLogOut = () => {
    const { isFullLogin } = this.state;
    if (!isFullLogin) {
      this.props.history.replace(BOOKING_LIST_ROUTE);
    } else {
      authService.logout().then(() => {
        uriStorage.clearPath();
        providerStorage.clearProvider();
      });
      this.props.history.replace(ROOT_ROUTE);
    }
  };

  _getCurrentAppointmentId = () => {
    return this.props.match.params.appointmentId
      ? this.props.match.params.appointmentId
      : this.props.appointmentId;
  };

  _formatWaitTime = (num) => {
    const day = Math.floor(num / 1440); // 60*24
    const hour = Math.floor((num - day * 1440) / 60);
    const minute = Math.round(num % 60);

    const dayUnit = day > 1 ? 'days' : 'day';
    const hourUnit = hour > 1 ? 'hours' : 'hour';
    const minuteUnit = minute > 1 ? 'minutes' : 'minute';

    if (day > 0) {
      return `${day} ${dayUnit} ${hour}, ${hourUnit} and ${minute} ${minuteUnit}`;
    } else if (hour > 0) {
      return `${hour} ${hourUnit} and ${minute} ${minuteUnit}`;
    }
    return `${minute} ${minuteUnit}`;
  };

  handleButtonClick = () => {
    const { history } = this.props;
    let appointmentId = this._getCurrentAppointmentId();

    setSession('appt', appointmentId);

    analyticsEventLogger.log(AnalyticsEvent.CHECKIN_START, {
      appointmentId: appointmentId,
    });
    history.push(routeUtil.buildBookingIdentityDocument(appointmentId));
  };

  _canCheckin = () => {
    const { checkinSuccess, appointment, isWalkIn } = this.state;

    if (isWalkIn) {
      return true;
    }

    const currentTimePlus30 = addMinutes(new Date(), 30); // gets the current time plus 30 minutes

    const isStartTimeWithin30Minutes = isBefore(new Date(appointment.start), currentTimePlus30); // checks if the appointment start time is within 30 minutes

    return !checkinSuccess && isStartTimeWithin30Minutes;
  };

  _renderReservationTimeAndWait = () => {
    const { classes } = this.props;
    let { startTime, timezone } = this.state;

    return (
      <Typography variant="h4" className={classes.estimationText}>
        <FormattedMessage
          id={'checkin.reservation.time'}
          defaultMessage={'Estimated time for the reservation: '}
        />
        <br />
        <span className={classes.waitingTime}>
          {dateUtil.formatDateTimeWithTimeZone(startTime, timezone)}
        </span>
      </Typography>
    );
  };

  _renderReservationTime = () => {
    const { classes } = this.props;
    let { startTime, timezone } = this.state;

    return (
      <Typography variant="h4" className={classes.estimationText}>
        <FormattedMessage
          id={'checkin.reservation.time'}
          defaultMessage={'Estimated time for the reservation: '}
        />
        <br />
        <span className={classes.waitingTime}>
          {dateUtil.formatDateTimeWithTimeZone(startTime, timezone)}
        </span>
      </Typography>
    );
  };

  _renderTextContents = () => {
    const { waitTime, queueNumber } = this.state;

    if (waitTime > 0) {
      return (
        <>
          <TextBody>
            <FormattedMessage
              id={'bookingstatus.checkin.waiting.time'}
              defaultMessage={`Estimated time to see the provider: ${this._formatWaitTime(
                waitTime,
              )}`}
            />
          </TextBody>
        </>
      );
    } else if (waitTime <= 0) {
      return (
        <>
          <TextBody>
            {waitTime >= -10 ? (
              <FormattedMessage
                id={'bookingstatus.checkin.waiting.soon'}
                defaultMessage={'A provider will see you soon'}
              />
            ) : (
              <FormattedMessage
                id={'bookingstatus.checkin.waiting.late'}
                defaultMessage={
                  'We are experiencing a delay. A provider will see you as soon as one becomes available.'
                }
              />
            )}
          </TextBody>
        </>
      );
    } else if (waitTime === 'unknown') {
      return <></>;
    }
  };

  _createMapUrl = (url) => {
    let { providerDetails } = this.state;
    return providerDetails
      ? `${url || 'https://www.google.com/maps/dir/Current+Location/'}${providerDetails.lat},${
          providerDetails.lng
        }`
      : '';
  };

  _renderConfirmationDialog = (appointment) => {
    if (appointment.status === 'WAITING') {
      return (
        <>
          <AppointmentCancelDialog
            selectedAppointment={appointment.id}
            bloc={this.cancelBloc}
            // onCancelSuccess={() => {
            //   authService.logout().then(() => {
            //     uriStorage.clearPath();
            //     providerStorage.clearProvider();
            //   });
            // }}
          />
          <Grid item xs={12}>
            <TextBody
              sx={(theme) => ({
                lineHeight: '1',
                [theme.breakpoints.down('sm')]: {
                  textAlign: 'center',
                },
              })}
            >
              <FormattedMessage
                id={'appointment.status.change.text'}
                defaultMessage={'Do you need to cancel your booking?'}
              />
            </TextBody>
          </Grid>
          <Grid item xs={12}>
            <DecodedButton
              onClick={() => {
                this.cancelBloc.setCancelDialogOpen(true);
              }}
            >
              Cancel
            </DecodedButton>
          </Grid>
        </>
      );
    } else if (appointment.status === 'RESERVED') {
      return (
        <>
          <AppointmentCancelDialog
            selectedAppointment={appointment.id}
            bloc={this.cancelBloc}
            // onCancelSuccess={() => {
            //   authService.logout().then(() => {
            //     uriStorage.clearPath();
            //     providerStorage.clearProvider();
            //   });
            // }}
          />
          <Grid item xs={12}>
            <TextBody
              sx={(theme) => ({
                lineHeight: '1',
                [theme.breakpoints.down('sm')]: {
                  textAlign: 'center',
                },
              })}
            >
              {appointment.isGetInLine ? (
                <FormattedMessage
                  id={'appointment.status.getinline.text'}
                  defaultMessage={'Do you need to cancel your place in line?'}
                />
              ) : (
                <FormattedMessage
                  id={'appointment.status.change.text'}
                  defaultMessage={'Do you need to cancel your booking?'}
                />
              )}
            </TextBody>
          </Grid>
          <Grid item xs={12}>
            <DecodedButton
              onClick={() => {
                this.cancelBloc.setCancelDialogOpen(true);
              }}
            >
              Cancel
            </DecodedButton>
          </Grid>
          {!appointment.isGetInLine && (
            <Grid item xs={12}>
              <DecodedButton
                onClick={() => {
                  this.bloc.rescheduleDraft();
                }}
              >
                Reschedule
              </DecodedButton>
            </Grid>
          )}
        </>
      );
    }

    return <></>;
  };

  render() {
    const { classes } = this.props;
    const {
      loading,
      startTime,
      appointment,
      provider,
      providerContactNumber,
      checkinSuccess,
      isCancelled,
      isFullLogin,
    } = this.state;

    if (!appointment) {
      return <PageContainer loading={true} hideBack={true}></PageContainer>;
    }

    if (isCancelled) {
      return this.__renderCancelled();
    }

    if (checkinSuccess) {
      return this.__renderCheckedIn();
    }

    return (
      <PageContainer
        loading={loading}
        paperBackground={true}
        hideBack={!isFullLogin}
        onBack={
          !isFullLogin
            ? undefined
            : () => {
                this.props.history.replace(BOOKING_LIST_ROUTE);
              }
        }
      >
        <ScrollableContainer applyFormat={true}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <H1TextTitle>
                {appointment.isGetInLine ? (
                  <FormattedMessage
                    id={'appointment.status.getinline.title'}
                    defaultMessage={'YOUR CURRENT PLACE IN LINE'}
                  />
                ) : (
                  <FormattedMessage
                    id={'appointment.status.title'}
                    defaultMessage={'YOUR UPCOMING RESERVATION'}
                  />
                )}
              </H1TextTitle>
            </Grid>
            <Grid item xs={12}>
              {appointment.isGetInLine ? (
                <GetInLineAppointmentCard
                  appointment={appointment}
                  organisation={provider}
                  visitTime={startTime}
                />
              ) : (
                <AppointmentCard
                  appointment={appointment}
                  organisation={provider}
                  visitTime={startTime}
                />
              )}
            </Grid>
            {appointment && this.buildCheckinInformation(appointment)}
            {appointment && this._renderConfirmationDialog(appointment)}
            <Grid item xs={12}>
              {!checkinSuccess && (
                <Grid
                  sx={(theme) => ({
                    [theme.breakpoints.down('sm')]: {
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    },
                  })}
                  item
                  xs={12}
                >
                  <Link
                    sx={(theme) => ({
                      fontWeight: '700',
                      fontSize: '18px !important',
                      lineHeight: '22px',
                      display: 'flex',
                      marginTop: '24px',
                      marginBottom: '24px',
                      color: theme.palette.primary.main,
                      [theme.breakpoints.down('sm')]: {
                        textAlign: 'center',
                      },
                      ['& p']: {
                        margin: '2px 0 0 0',
                      },
                    })}
                    rel="noopener noreferrer"
                    target="_blank"
                    href={this._createMapUrl()}
                    className={classes.directionLinkText}
                    underline="hover"
                  >
                    <DirectionIcon className={classes.getDirectionIcon} />
                    <FormattedMessage
                      id={'checkin.direction.button'}
                      defaultMessage={'Get Directions'}
                    />
                  </Link>
                </Grid>
              )}
              <TextBody
                sx={(theme) => ({
                  lineHeight: '1',
                  [theme.breakpoints.down('sm')]: {
                    textAlign: 'center',
                  },
                })}
              >
                <FormattedMessage
                  id={'appointment.status.clinic.message'}
                  defaultMessage={'For any queries, please call the clinic:'}
                />
                <Link
                  sx={(theme) => ({
                    color: theme.palette.secondary.main,
                  })}
                  href={`tel:+1${phoneUtil.stripCharacters(providerContactNumber)}`}
                >
                  &nbsp; {providerContactNumber}
                </Link>
              </TextBody>
            </Grid>
          </Grid>
        </ScrollableContainer>
      </PageContainer>
    );
  }

  howToCheckIn = (isGetInLine) => {
    return [
      {
        message: isGetInLine
          ? '1. Arrive about 30 minutes early and bring your ID and Proof of Insurance.'
          : '1. Arrive about 15 minutes early and bring your ID and Proof of Insurance.',
        messageId: isGetInLine
          ? 'appointment.status.instructions.1gil'
          : 'appointment.status.instructions.1',
      },
      {
        message: '2. Scan QR Code in lobby to check-in.',
        messageId: 'appointment.status.instructions.2',
      },
      {
        message: '3. Take a seat, we’ll be with you soon!',
        messageId: 'appointment.status.instructions.3',
      },
    ];
  };

  buildCheckinInformation(appointment) {
    const { inClinic, checkinAvailable } = this.state;

    if (appointment?.status === 'WAITING') {
      return <></>;
    }

    return (
      <>
        {!inClinic && (
          <Grid item xs={12}>
            <H1TextTitle>
              <FormattedMessage
                id={'appointment.status.instructions.title'}
                defaultMessage={'HOW TO CHECK IN:'}
              />
            </H1TextTitle>
            {this.howToCheckIn(appointment.isGetInLine).map((instruction, index) => (
              <FormattedMessage
                key={index}
                id={instruction.messageId}
                defaultMessage={instruction.message}
              >
                {(txt) => <TextBody key={index}>{txt}</TextBody>}
              </FormattedMessage>
            ))}
          </Grid>
        )}
        {checkinAvailable && inClinic && (
          <Grid item xs={12}>
            <H1TextTitle>
              <FormattedMessage
                id={'appointment.status.instruction.walkin.text'}
                defaultMessage={'READY TO CHECKIN:'}
              />
            </H1TextTitle>
            {this._canCheckin() ? (
              <Button variant="contained" onClick={this.handleButtonClick}>
                Check in
              </Button>
            ) : (
              <Button variant="outlined" disabled={true}>
                Check in 30 minutes before your appointment
              </Button>
            )}
          </Grid>
        )}
      </>
    );
  }

  __renderCheckedIn = () => {
    const { loading, startTime, appointment, provider, providerContactNumber, isFullLogin } =
      this.state;

    return (
      <PageContainer
        loading={loading}
        paperBackground={true}
        hideBack={!isFullLogin}
        onBack={false}
      >
        <ScrollableContainer applyFormat={true}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <H1TextTitle>
                <FormattedMessage
                  id={'appointment.status.checkedin.title'}
                  defaultMessage={'YOUR RESERVATION'}
                />
              </H1TextTitle>
            </Grid>
            <Grid item xs={12}>
              {appointment && (
                <AppointmentCard
                  appointment={appointment}
                  organisation={provider}
                  visitTime={startTime}
                ></AppointmentCard>
              )}
            </Grid>
            <Grid item xs={12}>
              {appointment && this._renderTextContents()}
            </Grid>
            {appointment && this._renderConfirmationDialog(appointment)}
            <Grid item xs={12}>
              <TextBody
                sx={(theme) => ({
                  lineHeight: '1',
                  [theme.breakpoints.down('sm')]: {
                    textAlign: 'center',
                  },
                })}
              >
                <FormattedMessage
                  id={'appointment.status.clinic.message'}
                  defaultMessage={'For any queries, please call the clinic:'}
                />
                <Link
                  sx={(theme) => ({
                    color: theme.palette.secondary.main,
                  })}
                  href={`tel:+1${phoneUtil.stripCharacters(providerContactNumber)}`}
                >
                  &nbsp; {providerContactNumber}
                </Link>
              </TextBody>
            </Grid>
          </Grid>
        </ScrollableContainer>
      </PageContainer>
    );
  };

  __renderCancelled = () => {
    const { loading, startTime, appointment, provider } = this.state;

    return (
      <PageContainer loading={loading} paperBackground={true} hideBack={true}>
        <ScrollableContainer applyFormat={true}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <H1TextTitle>
                <FormattedMessage
                  id={'appointment.status.cancelled.title'}
                  defaultMessage={'YOUR RESERVATION'}
                />
              </H1TextTitle>
            </Grid>
            <Grid item xs={12}>
              {appointment && (
                <AppointmentCard
                  appointment={appointment}
                  organisation={provider}
                  visitTime={startTime}
                ></AppointmentCard>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextBodyBoldSecondary>
                <FormattedMessage
                  id={'checkin.cancelled.message'}
                  defaultMessage={'Your reservation has been cancelled.'}
                />
              </TextBodyBoldSecondary>
            </Grid>
          </Grid>
        </ScrollableContainer>
      </PageContainer>
    );
  };
}

export default withStyles(styles)(withRouter(BookingStatus));
