import { userInfoUtil } from '../../../utils/user';
import { phoneUtil } from '../../../utils/phone';

const createEmergencyContactRequest = (emergencyData) => {
  if (!emergencyData?.relationship) {
    return { relationship: 'SELF' };
  }

  const { relationship, firstName, lastName, phoneNumber, address, city, postCode, state } =
    emergencyData;

  if (relationship === 'SELF') return { relationship: relationship };

  return {
    relationship: relationship,
    name: userInfoUtil.formatName(firstName, lastName),
    contactNumber: phoneUtil.formatPhoneNumberForRegistration(phoneNumber, '+1', 'US'),
    address: {
      type: '',
      line1: address,
      line2: '',
      line3: '',
      city: city,
      postcode: postCode,
      administrativeArea: state,
      country: 'US',
    },
  };
};

const createGuarantorRequest = (guarantorData) => {
  if (!guarantorData?.relationship) {
    return { relationship: 'SELF' };
  }

  const {
    relationship,
    firstName,
    lastName,
    phoneNumber,
    address,
    city,
    postCode,
    state,
    gender,
    dateOfBirth,
  } = guarantorData;

  if (relationship === 'SELF') return { relationship: relationship };

  return {
    relationship: relationship,
    name: userInfoUtil.formatName(firstName, lastName),
    dob: dateOfBirth ? userInfoUtil.formatDate(dateOfBirth) : null,
    gender: gender,
    contactNumber: phoneUtil.formatPhoneNumberForRegistration(phoneNumber, '+1', 'US'),
    address: {
      type: '',
      line1: address,
      line2: '',
      line3: '',
      city: city,
      postcode: postCode,
      administrativeArea: state,
      country: 'US',
    },
  };
};

export const createAccountRequest = (emergencyData, state) => {
  let {
    firstName,
    lastName,
    gender,
    dateOfBirth,
    ssn,
    acceptTerms,
    number,
    email,
    code,
    codeCountry,
    idVerificationReference,
    addressLine1,
    addressLine2,
    addressCity,
    addressAdministrativeArea,
    addressPostcode,
    addressCountry,
    race,
    ethnicity,
    hearAboutUs,
  } = state;

  let externalReferences = [];

  if (ssn) {
    externalReferences.push({
      code: 'SS',
      reference: ssn,
    });
  }

  if (idVerificationReference) {
    externalReferences.push({
      code: 'verificationId',
      reference: idVerificationReference,
    });
  }

  let properties = [];
  if (hearAboutUs) {
    properties.push({
      code: 'marketingChannel',
      value: hearAboutUs,
    });
  }

  let demographic = [];
  if (race)
    demographic.push({
      code: 'race',
      value: race,
    });
  if (ethnicity)
    demographic.push({
      code: 'ethnicity',
      value: ethnicity,
    });

  const emergency = createEmergencyContactRequest(emergencyData);
  const responsibleParty = createGuarantorRequest(state.responsibleParty);

  return {
    code: 'enrollment.register',
    type: 'client',
    payload: {
      name: userInfoUtil.formatName(firstName, lastName),
      dob: userInfoUtil.formatDate(dateOfBirth),
      gender: gender,
      externalReferences: externalReferences,
      contactDetails: {
        address: {
          type: '',
          line1: addressLine1,
          line2: addressLine2,
          line3: '',
          city: addressCity,
          postcode: addressPostcode,
          administrativeArea: addressAdministrativeArea,
          country: addressCountry,
        },
        number: phoneUtil.formatPhoneNumberForRegistration(number, code, codeCountry),
        email: email,
      },
      acceptTerms: acceptTerms,
      communicationConsent: acceptTerms,
      acceptLocationTerm: true,
      emergency,
      guarantor: responsibleParty,
      demographic: demographic,
      properties: properties,
    },
  };
};
