import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import { checkSession, setSession, uriStorage } from '../../../utils/storage';
import BasicInformation from './BasicInformation';
import { Link, FormControlLabel } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import Address from './AddressForm';
import { registrationApi } from '../../../utils/services/register.api';
import { authService } from '../../../utils/auth';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { notificationService } from '../../../utils/notification';
import { ErrorMessage, errorResolver } from '../../../utils/error.resolver';
import { userInfoUtil } from '../../../utils/user';
import { phoneUtil } from '../../../utils/phone';
import {
  ACCOUNT_PAYMENT_DETAILS_ROUTE,
  BOOKING_LIST_ROUTE,
  QUINN_ROUTE,
  REMOTE_CONFIRMATION,
  routeUtil,
} from '../../../utils/route.name';
import {
  InvalidPhoneNumberModal,
  NotConnectedPhoneNumberModal,
} from './PhoneNumberVerificationModals';
import { Checkbox } from '../../shared/components/Checkbox';
import { providerStorage } from '../../../utils/provider.qs';
import DecodedComponent from '../../shared/DecodedComponent';
import { Bloc } from './bloc';
import { pharmacyApi } from '../../../utils/services/pharmacy.api';
import { consumerApi } from '../../../utils/services/consumers.api';
import { logger } from '../../../utils/logging';

const styles = (theme) => ({
  grid: {
    flex: '1',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  checkBox: {
    transform: 'scale(2)',
    marginLeft: '1em',
    marginRight: '1em',
  },
  button: {
    width: '100%',
    padding: '3em',
    textAlign: 'center',
  },
});

const initState = {
  loading: true,
  activeStep: 0,

  firstName: '',
  lastName: '',
  addressLine1: '',
  addressLine2: '',
  addressAdministrativeArea: '',
  addressCity: '',
  addressPostcode: '',
  addressCountry: 'US',
  codeCountry: 'US',
  code: '+1',
  number: '',
  email: '',
  gender: '',
  ssn: '',
  dateOfBirth: '',

  hearAboutUs: '',
  race: '',
  ethnicity: '',
  language: '',

  acceptTerms: true,

  updateSuccess: true,

  invalidPhoneNumber: false,
  notConnectedPhoneNumber: false,

  isPatientMyself: 'true',
  isFirstTimeVisit: 'true',

  pharmacy: {},
  responsibleParty: { relationship: 'SELF' },
  emergency: {},

  dirty: false,
  isPharmacyDirty: false,
};

class UserUpdate extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = new Bloc({});
    this.state = initState;
  }

  componentDidMount() {
    logger.info('<UserUpdate/> mounted');
    super.componentDidMount();

    //! @ v=== PREVENT USER FROM GOING BACK TO PREVIOUS PAGE ===v
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };

    // uriStorage.setCurrentPath(this.props.match.url);

    analyticsEventLogger.log(AnalyticsEvent.PERSON_DETAILS_UPDATE_OPEN);

    consumerApi
      .getPersonDetails()
      .then((result) => {
        analyticsEventLogger.log(AnalyticsEvent.PERSON_DETAILS_RETRIEVAL_SUCCESS);

        this._mapUserData(result.data);
        this._mapUserGuarantorData(result.data);
        this._mapUserEmergencyData(result.data);

        this.getPreferredPharmacy();
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.PERSON_DETAILS_RETRIEVAL_ERROR, {
          reason: `${error}`,
        });

        notificationService.error(
          'Unable to get your personal information.' + ErrorMessage.CALL_SUPPORT,
        );
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    logger.info('<UserUpdate/> unmounted');
  }

  getPreferredPharmacy = () => {
    pharmacyApi
      .getPatientPharmacy()
      .then((result) => {
        analyticsEventLogger.log(AnalyticsEvent.PHARMACY_RETRIEVAL_SUCCESS);
        if (result.data.items != null && result.data.items.length > 0) {
          this._mapUserPharmacyData(result.data.items[0]);
        }
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.PHARMACY_RETRIEVAL_ERROR, {
          reason: `${error}`,
        });
        notificationService.error(
          'Unable to get your preferred pharmacy.' + ErrorMessage.CALL_SUPPORT,
        );
      });
  };

  _mapUserPharmacyData = (pharmacyInfo) => {
    let address = pharmacyInfo.contactInformation.address;

    let data = {
      id: pharmacyInfo.id,
      name: pharmacyInfo.name,
      group: pharmacyInfo.group,
      address: {
        line1: address.line1,
        administrativeArea: address.administrativeArea,
        city: address.city,
        postcode: address.postcode,
      },
    };

    this.setState({ pharmacy: data });
  };

  _mapUserData = (userInfo) => {
    let name = userInfo.name;
    let address = userInfo.address;

    let ssn = userInfo.externalReferences.filter((reference) => {
      return reference.code === 'SS';
    });

    let data = {
      firstName: name.given,
      lastName: name.family,
      addressLine1: address.line1,
      addressLine2: address.line2,
      addressAdministrativeArea: address.administrativeArea,
      addressCity: address.city,
      addressPostcode: address.postcode,
      number: phoneUtil.formatPhoneNumberForDisplay(userInfo.contactNumber),
      email: userInfo.email,
      gender: userInfo.gender,
      ssn: ssn.length === 1 ? ssn[0].reference : '',
      dateOfBirth: userInfoUtil.formatToUSDate(userInfo.dob),
      race: userInfo.demographic
        ?.filter((data) => data.code === 'race')
        .map((data) => data.value)[0],
      ethnicity: userInfo.demographic
        ?.filter((data) => data.code === 'ethnicity')
        .map((data) => data.value)[0],
      hearAboutUs: userInfo.properties
        ?.filter((data) => data.code === 'marketingChannel')
        .map((data) => data.value)[0],
    };

    this.setState({ ...data });
  };

  _mapUserGuarantorData = (userInfo) => {
    let guarantorInfo = userInfo.guarantor;
    let guarantorDetails = userInfo.guarantor?.details;

    this.setState({
      patientGivenName: userInfo.name.given.trim().toLowerCase(),
      patientFamilyName: userInfo.name.family.trim().toLowerCase(),
      patientDOB: userInfo.dob,
      patientGender: userInfo.gender,
    });

    let data = {};

    if (guarantorDetails) {
      let name = guarantorDetails.name;
      data = {
        relationship: guarantorInfo.relationship,
        firstName: name.given,
        lastName: name.family,
        gender: guarantorDetails.gender,
        dateOfBirth: userInfoUtil.formatToUSDate(userInfo.dob),

        address: guarantorDetails.address.line1,
        state: guarantorDetails.address.administrativeArea,
        city: guarantorDetails.address.city,
        postCode: guarantorDetails.address.postcode,
        phoneNumber: phoneUtil.formatPhoneNumberForDisplay(guarantorDetails.contactNumber),
      };
    } else {
      data = {
        relationship: guarantorInfo.relationship,
        editable: true,
      };
    }

    this.setState({
      responsibleParty: data,
    });
  };

  _mapUserEmergencyData = (userInfo) => {
    let emergencyInfo = userInfo.relatedPerson;
    let emergencyDetails = userInfo.relatedPerson?.details;

    this.setState({
      patientGivenName: userInfo.name.given.trim().toLowerCase(),
      patientFamilyName: userInfo.name.family.trim().toLowerCase(),
      patientDOB: userInfo.dob,
      patientGender: userInfo.gender,
    });

    let data = {};

    if (emergencyDetails) {
      let name = emergencyDetails.name;
      data = {
        relationship: emergencyInfo.relationship,
        firstName: name.given,
        lastName: name.family,
        gender: emergencyDetails.gender,
        dateOfBirth: userInfoUtil.formatToUSDate(userInfo.dob),

        address: emergencyDetails.address.line1,
        state: emergencyDetails.address.administrativeArea,
        city: emergencyDetails.address.city,
        postCode: emergencyDetails.address.postcode,
        phoneNumber: phoneUtil.formatPhoneNumberForDisplay(emergencyDetails.contactNumber),
      };
    } else {
      data = {
        relationship: emergencyInfo.relationship,
        editable: true,
      };
    }

    this.setState({
      emergency: data,
    });
  };

  setDirty = () => {
    this.setState({ dirty: true });
  };

  handleNext = () => {
    this.setState((state) => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    const { activeStep } = this.state;
    if (activeStep === 0) {
      this.props.history.goBack();
      return;
    }

    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleTextChange = (event) => {
    let change = {};
    change[event.target.name] = event.target.value;
    this.setState(change);
  };

  handleDataChange = (data) => {
    this.setState(data);
  };

  handleCodeChange = (callingCode, addressCountry, countryCode) => {
    this.setState({
      code: callingCode,
      codeCountry: countryCode,
      ...(addressCountry ? { addressCountry } : {}),
      number: '',
    });
  };

  handleCheckboxChange = (event) => {
    let change = {};
    change[event.target.name] = event.target.checked;
    this.setState(change);
  };

  handleGenderChange = (event) => {
    this.setState({
      gender: event.target.value,
    });
  };

  handlePhoneVerification = () => {
    const { loading } = this.state;

    if (loading) return;

    this.setState({
      loading: true,
    });
    const formattedNumber = phoneUtil.formatPhoneNumberForRegistration(
      this.state.number,
      this.state.code,
      this.state.codeCountry,
    );
    const number =
      formattedNumber.country +
      formattedNumber.area +
      formattedNumber.prefix +
      formattedNumber.line;
    registrationApi
      .verifyPhoneNumber(number)
      .then((response) => {
        const verificationStatus = response.data;
        if (verificationStatus.status === 3) {
          this.setState({
            invalidPhoneNumber: true,
          });
          return;
        } else if (verificationStatus.status === 0) {
          if (verificationStatus.current_carrier.network_type !== 'mobile') {
            this.setState({
              notConnectedPhoneNumber: true,
            });
            return;
          }
        }
        analyticsEventLogger.log(AnalyticsEvent.REGISTER_PHONE_VERIFICATION_SUCCESS);
        this.handleNext();
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.REGISTER_PHONE_VERIFICATION_ERROR, {
          reason: error,
        });

        this.handleNext();
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  handleNextPersonalVerification = (submitWithPhoneVerification = false) => {
    //TODO why ??
    if (submitWithPhoneVerification) {
      console.log('submitWithPhoneVerification');
      this.handlePhoneVerification(); //TODO why ??
    } else {
      console.log('submitWithoutPhoneVerification');
      this.handleNext();
    }
  };

  doSubmit = (emergencyData) => {
    const { loading, dirty } = this.state;

    if (loading) return;

    this.setState({ loading: true });

    const formRequest = this.updateRequest(emergencyData);

    if (!dirty) {
      logger.debug('update person not dirty');

      //! @ v===USER IS IN MANAGE APPT FLOW, PUSH TO *QUINN* ===v
      if (checkSession('action', 'manage-appointments')) {
        this.setState({ updateSuccess: true });
        this.props.history.push(QUINN_ROUTE);

        //! @v=== *BOOKING LIST NEW RESERVATION* ===v
      } else if (sessionStorage.getItem('appt') === null && !providerStorage.hasProvider()) {
        this.setState({ updateSuccess: true });
        this.props.history.push(QUINN_ROUTE);

        //! @ v===USER IS MAKING NEW RESERVATION DURING CHECK IN ===v
      } else if (
        sessionStorage.getItem('appt') === null &&
        checkSession('task', 'new-reservation')
      ) {
        this.setState({ updateSuccess: true });
        this.props.history.push(QUINN_ROUTE);

        //! @ v===USER IS IN ANON FLOW, PUSH TO *CONFIRMATION* ===v
      } else if (!providerStorage.hasProvider() && sessionStorage.getItem('appt') !== null) {
        this.setState({ updateSuccess: true });
        setSession('task', 'confirm_booking');
        this.props.history.push(
          routeUtil.buildBookingRouteWithDraftAppointmentID(
            sessionStorage.getItem('appt'),
            'IN_PERSON',
          ),
        );

        //! @ v===USER IS IN KIOSK/WALKIN FLOW, PUSH TO *BOOKING LIST* ===v
      } else if (providerStorage.isKiosk() || providerStorage.isWalkin()) {
        this.setState({ updateSuccess: true });
        logger.debug('update person not dirty to booking list');
        setSession('task', 'checkin');
        if (checkSession('checkin', 'true')) {
          this.props.history.push(BOOKING_LIST_ROUTE);
        } else {
          this.props.history.push(QUINN_ROUTE);
        }
      }
      return;
    }

    consumerApi
      .updatePerson(formRequest)
      .then((value) => {
        logger.debug('update person success');
        analyticsEventLogger.log(AnalyticsEvent.PERSON_DETAILS_UPDATE_SUCCESS);
        this.setState({ updateSuccess: true });
      })
      .catch((error) => {
        sessionStorage.setItem('task', 'existing-confirm');

        analyticsEventLogger.log(AnalyticsEvent.PERSON_DETAILS_UPDATE_ERROR, {
          reason: error,
        });

        notificationService.error('Unable to update your information.' + ErrorMessage.CALL_SUPPORT);
      })
      .finally(() => {
        logger.debug('update person finally');
        //! @ v===USER IS IN MANAGE APPT FLOW, PUSH TO *QUINN* ===v
        if (checkSession('action', 'manage-appointments')) {
          this.setState({ updateSuccess: true });
          this.props.history.push(QUINN_ROUTE);
          logger.debug('update person not dirty to quinn 1');

          //! @v=== *BOOKING LIST NEW RESERVATION* ===v
        } else if (sessionStorage.getItem('appt') === null && !providerStorage.hasProvider()) {
          this.setState({ updateSuccess: true });
          logger.debug('update person not dirty to quinn 2');
          this.props.history.push(QUINN_ROUTE);
          //! @ v===USER IS MAKING NEW RESERVATION DURING CHECK IN ===v
        } else if (
          sessionStorage.getItem('appt') === null &&
          checkSession('task', 'new-reservation')
        ) {
          this.setState({ updateSuccess: true });
          logger.debug('update person not dirty to quinn 3');
          this.props.history.push(QUINN_ROUTE);
          //! @ v===USER IS IN ANON FLOW, PUSH TO *CONFIRMATION* ===v
        } else if (!providerStorage.hasProvider() && sessionStorage.getItem('appt') !== null) {
          this.setState({ updateSuccess: true });
          setSession('task', 'confirm_booking');
          this.props.history.push(
            routeUtil.buildBookingRouteWithDraftAppointmentID(
              sessionStorage.getItem('appt'),
              'IN_PERSON',
            ),
          );

          //! @ v===USER IS IN KIOSK/WALKIN FLOW, PUSH TO *BOOKING LIST* ===v
        } else if (providerStorage.isKiosk() || providerStorage.isWalkin()) {
          this.setState({ updateSuccess: true });
          logger.debug('update person not dirty to booking list');
          setSession('task', 'checkin');
          if (checkSession('checkin', 'true')) {
            this.props.history.push(BOOKING_LIST_ROUTE);
          } else {
            this.props.history.push(QUINN_ROUTE);
          }
        }
        return;
      });
  };

  submitPharmacyRequest = () => {
    const pharmacy = this.createPharmacyRequest();

    if (!this.state.isPharmacyDirty) {
      this.setState({ updateSuccess: true });
      return;
    }

    pharmacyApi
      .setPharmacy(pharmacy)
      .then((result) => {
        analyticsEventLogger.log(AnalyticsEvent.PHARMACY_UPDATE_SUCCESS);
        this.setState({ updateSuccess: true });
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.PHARMACY_UPDATE_ERROR, {
          reason: `${error}`,
        });

        notificationService.error(
          'Unable to update pharmacy information.' + ErrorMessage.CALL_SUPPORT,
        );
      })
      .finally(() => {
        // this.props.history.push(QUINN_ROUTE)
      });
  };

  createEmergencyContactRequest = (emergencyData) => {
    if (!emergencyData?.relationship) {
      return { relationship: 'SELF' };
    }

    const {
      relationship,
      firstName,
      lastName,
      phoneNumber,
      address,
      city,
      postCode,
      state,
      gender,
      dateOfBirth,
    } = emergencyData;

    if (relationship === 'SELF') return { relationship: relationship };

    return {
      relationship: relationship,
      name: userInfoUtil.formatName(firstName, lastName),
      dob: userInfoUtil.formatDate(dateOfBirth),
      gender: gender,
      contactNumber: phoneUtil.formatPhoneNumberForRegistration(phoneNumber, '+1', 'US'),
      address: {
        type: '',
        line1: address,
        line2: '',
        line3: '',
        city: city,
        postcode: postCode,
        administrativeArea: state,
        country: 'US',
      },
    };
  };

  createGuarantorRequest = (guarantorData) => {
    if (!guarantorData?.relationship) {
      return { relationship: 'SELF' };
    }

    const {
      relationship,
      firstName,
      lastName,
      phoneNumber,
      address,
      city,
      postCode,
      state,
      gender,
      dateOfBirth,
    } = guarantorData;

    if (relationship === 'SELF') return { relationship: relationship };

    return {
      relationship: relationship,
      name: userInfoUtil.formatName(firstName, lastName),
      dob: userInfoUtil.formatDate(dateOfBirth),
      gender: gender,
      contactNumber: phoneUtil.formatPhoneNumberForRegistration(phoneNumber, '+1', 'US'),
      address: {
        type: '',
        line1: address,
        line2: '',
        line3: '',
        city: city,
        postcode: postCode,
        administrativeArea: state,
        country: 'US',
      },
    };
  };

  createPharmacyRequest = () => {
    const { pharmacy } = this.state;
    if (pharmacy.contactInformation) {
      return pharmacy;
    }
    return {
      id: pharmacy.id,
      name: pharmacy.name,
      group: pharmacy.group,
      contactInformation: pharmacy.contactInformation,
    };
  };

  updateRequest = (emergencyData) => {
    let {
      firstName,
      lastName,
      gender,
      dateOfBirth,
      ssn,
      acceptTerms,
      number,
      email,
      code,
      codeCountry,
      idVerificationReference,
      addressLine1,
      addressLine2,
      addressCity,
      addressAdministrativeArea,
      addressPostcode,
      addressCountry,
      race,
      ethnicity,
      hearAboutUs,
    } = this.state;

    let externalReferences = [];

    if (ssn) {
      externalReferences.push({
        code: 'SS',
        reference: ssn,
      });
    }

    if (idVerificationReference) {
      externalReferences.push({
        code: 'verificationId',
        reference: idVerificationReference,
      });
    }

    let properties = [];
    if (hearAboutUs) {
      properties.push({
        code: 'marketingChannel',
        value: hearAboutUs,
      });
    }

    let demographic = [];
    if (race)
      demographic.push({
        code: 'race',
        value: race,
      });
    if (ethnicity)
      demographic.push({
        code: 'ethnicity',
        value: ethnicity,
      });

    const emergency = this.createEmergencyContactRequest(emergencyData);
    const responsibleParty = this.createGuarantorRequest(this.state.responsibleParty);

    return {
      name: userInfoUtil.formatName(firstName, lastName),
      dob: userInfoUtil.formatDate(dateOfBirth),
      gender: gender,
      externalReferences: externalReferences,
      address: {
        type: '',
        line1: addressLine1,
        line2: addressLine2,
        line3: '',
        city: addressCity,
        postcode: addressPostcode,
        administrativeArea: addressAdministrativeArea,
        country: addressCountry,
      },
      contactNumber: phoneUtil.formatPhoneNumberForRegistration(number, code, codeCountry),
      email: email,
      emergency,
      guarantor: responsibleParty,
      demographic: demographic,
      properties: properties,
    };
  };

  handleGoBackDialog = () => {
    this.setState({
      // activeStep: 0,
      notConnectedPhoneNumber: false,
      invalidPhoneNumber: false,
    });
  };

  handleContinueOnDialog = () => {
    this.setState((state, props) => ({
      activeStep: state.activeStep + 1,
      notConnectedPhoneNumber: false,
    }));
  };

  __getCorrectDob = (data, originalDob) => {
    let result;
    if (data?.dob === 'DATE_OF_BIRTH' && originalDob) {
      result = originalDob;
    } else {
      let extractedDate = data?.dob?.replace('-', '/');
      const dateRegex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;
      result = dateRegex.test(extractedDate) ? extractedDate : originalDob;
    }

    return result;
  };

  basicInformationBack = () => {
    this.setState((ps) => ({
      ...ps,
      activeStep: ps.scanSkipped ? 1 : ps.activeStep - 1,
    }));
  };

  handlePreScan = (newState) => {
    this.setState(newState);
  };

  getStepContent = (step) => {
    const providerId = providerStorage.getCurrentProvider();
    const isWalkin = providerId && providerId !== '';

    const isLoading = this.state.loading || authService.isLoading();

    switch (step) {
      case 0:
        return (
          <BasicInformation
            {...this.state}
            handleBack={this.handleBack}
            handleNext={this.handleNextPersonalVerification}
            onCodeChange={this.handleCodeChange}
            handleTextChange={this.handleTextChange}
            handleGenderChange={this.handleGenderChange}
            setDirty={this.setDirty}
          />
        );
      case 1:
        return (
          <Address
            {...this.state}
            setDirty={this.setDirty}
            isLoading={isLoading}
            handleBack={this.handleBack}
            doSubmit={(newState) => {
              this.setState(newState);
              this.doSubmit({});
            }}
            code={this.state.code}
            updateAddressChange={this.handleDataChange}
            additionalFields={[
              <FormControlLabel
                control={
                  <Checkbox
                    required
                    checked={this.state.acceptTerms}
                    onChange={this.handleCheckboxChange}
                    className={this.props.classes.checkBox}
                    name="acceptTerms"
                    color="primary"
                    disabled
                  />
                }
                label={
                  <React.Fragment>
                    <FormattedMessage
                      id="registration.user.consent"
                      defaultMessage="I acknowledge and consent to"
                    />
                    <br />
                    <FormattedMessage id="global.consent.link">
                      {(chunks) => (
                        <Link
                          rel="noopener noreferrer"
                          target="_blank"
                          href={chunks}
                          underline="hover"
                        >
                          <FormattedMessage
                            id="registration.user.tnc"
                            defaultMessage="The Terms of Use, Consent to Treatment, and Notice of Privacy Practices"
                          />
                        </Link>
                      )}
                    </FormattedMessage>
                  </React.Fragment>
                }
              />,
            ]}
          />
        );
    }
  };

  render() {
    let { activeStep, updateSuccess, invalidPhoneNumber, notConnectedPhoneNumber, email } =
      this.state;
    if (!updateSuccess) {
      logger.info(' 👉🏼 Redirecting to quinn from UserUpdate');
      return <Redirect to={QUINN_ROUTE} />;
    }

    return (
      <>
        {this.getStepContent(activeStep)}
        <InvalidPhoneNumberModal open={invalidPhoneNumber} onGoBack={this.handleGoBackDialog} />
        <NotConnectedPhoneNumberModal
          open={notConnectedPhoneNumber}
          email={email}
          onContinue={this.handleContinueOnDialog}
          onGoBack={this.handleGoBackDialog}
        />
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(UserUpdate));
