class GetInLineUtil {
  constructor() {
    this.getInLineCutOffTime = process.env.REACT_APP_GET_IN_LINE_CUTOFF_TIME;
    this.locationsString = process.env.REACT_APP_GET_IN_LINE_ENABLED_LOCATIONS;
    this.getInLineStartTime = process.env.REACT_APP_GET_IN_LINE_START_TIME;
  }

  /**
   * Retrieves a list of enabled location IDs from the environment variable `REACT_APP_GET_IN_LINE_ENABLED_LOCATIONS`.
   *
   * This function parses the environment variable string, which contains comma-separated location IDs,
   * trims any whitespace around each ID, and returns them as an array. If the environment variable is not set,
   * it returns an empty array.
   *
   * @returns {string[]} An array of enabled location IDs. Returns an empty array if no locations are specified.
   */
  enabledLocations = () => {
    return this.locationsString ? this.locationsString.split(',').map((loc) => loc.trim()) : [];
  };

  /**
   * Checks if the current provider is a "Get In Line" QR location.
   *
   * This function retrieves the current provider ID from the session storage and checks if it is included
   * in the list of enabled locations for the "Get In Line" feature.
   *
   * @returns {boolean} `true` if the current provider is a "Get In Line" QR location, `false` otherwise.
   */
  isGetInLineQRLocation = () => {
    const id = sessionStorage.getItem('currentProvider') || localStorage.getItem('currentProvider');
    console.log('id', id);
    return this.enabledLocations().includes(id);
  };

  /**
   * Determines if the current time is past the cut-off time for getting in line.
   * The cut-off time is set in the environment variable `REACT_APP_GET_IN_LINE_CUTOFF_TIME`.
   * If the current time is past the cut-off time, this function returns `true`.
   * Otherwise, it returns `false`.
   *
   * @returns {boolean} `true` if the current time is past the cut-off time; otherwise, `false`.
   */
  isCutOff = () => {
    if (!this.getInLineCutOffTime) {
      return false;
    }

    const now = new Date();
    const cutOffTime = new Date();
    const hours = parseInt(this.getInLineCutOffTime.substring(0, 2), 10);
    const minutes = parseInt(this.getInLineCutOffTime.substring(2, 4), 10);
    cutOffTime.setHours(hours);
    cutOffTime.setMinutes(minutes);
    cutOffTime.setSeconds(0);
    cutOffTime.setMilliseconds(0);

    return now >= cutOffTime;
  };

  isOpen = () => {
    if (!this.getInLineStartTime) {
      return true;
    }

    const now = new Date();
    const startTime = new Date(this.getInLineStartTime);
    return now >= startTime;
  };

  startTimeDisplay = () => {
    if (!this.getInLineStartTime) {
      return '7am';
    }
    const hours = parseInt(this.getInLineStartTime.substring(0, 2), 10);
    const minutes = parseInt(this.getInLineStartTime.substring(2, 4), 10);

    const startTime = new Date();
    startTime.setHours(hours);
    startTime.setMinutes(minutes);
    startTime.setSeconds(0);
    startTime.setMilliseconds(0);

    return startTime.toLocaleTimeString([], {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };
}

export const getInLineUtil = new GetInLineUtil();
