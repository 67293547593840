import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BookingType } from '../utils';
import { BookingBloc, BookingBlocEvent } from '../bloc';

const determineAppointmentType = (appointment, overrides) => {
  return BookingType.RESERVATION;
};


const useBookingBloc = (appointmentId, type, history) => {
  const [state, setState] = useState({});
  const [appointmentType, setAppointmentType] = useState(null);

  const bloc = useMemo(() => new BookingBloc(appointmentId, type), [appointmentId, type]);


  const subscribeToState = useCallback((newState) => {
    setState((state) => ({ ...state, ...newState }));
  }, []);

  const subscribeToEvents = useCallback((event) => {
    const { data } = event;
    switch (event.type) {
      case BookingBlocEvent.INITIALISED:
        setAppointmentType(determineAppointmentType(data.appointment, data.overrides));
        break;
      case BookingBlocEvent.NAVIGATE_TO:
        history.replace(data.url, { ...data.state });
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    const stateSub = bloc.subscribeToState(subscribeToState);
    const eventSub = bloc.subscribeToEvents(subscribeToEvents);
    return () => {
      stateSub.unsubscribe();
      eventSub.unsubscribe();
    };
  }, [bloc, subscribeToState, subscribeToEvents]);

  return {
    ...state,
    appointmentType,
    bloc,
  };
};

export default useBookingBloc;
