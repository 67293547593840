import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authService } from './auth';
import { LOGIN_ROUTE, ROOT_ROUTE } from './route.name';
import 'firebase/auth';
import Loading from '../components/shared/Loading';
import { logger } from './logging';
import { globalBloc } from '../components/global.bloc';
import { uriStorage } from './storage';

function canRedirect(pathname) {
  return (
    pathname.startsWith('/virtual-clinic') ||
    pathname.startsWith('/conversation') ||
    pathname.startsWith('/booking')
  );
}

const defaultRoute = ROOT_ROUTE;

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [attempts, setAttempts] = useState(0);

  useEffect(() => {
    const maxAttempts = 3;
    const intervalTime = 500;

    const fetchUser = () => {
      const currentUser = authService.getUser();
      if (currentUser !== null || attempts >= maxAttempts) {
        setUser(currentUser);
        setLoading(false);
      } else {
        setAttempts((prev) => prev + 1);
      }
    };

    const intervalId = setInterval(fetchUser, intervalTime);

    return () => clearInterval(intervalId);
  }, [attempts]);

  if (loading) {
    return <Loading message="Loading Virtual Front Door..." />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect
            push
            to={{
              pathname: LOGIN_ROUTE,
              search: props.location.search,
              state: {
                next: canRedirect(props.location.pathname) ? props.location.pathname : defaultRoute,
              },
            }}
          />
        )
      }
    />
  );
};

// --> for routes that require a condition to be met like /pre-auth/booking or /new-conversation
export const GuardedRoute = ({ component: Component, condition, ...rest }) => {
  if (!condition) {
    logger.info('Condition not met in <GuardedRoute/>, redirecting to login...');
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        condition ? (
          <Component {...props} />
        ) : (
          <Redirect
            push
            to={{
              pathname: LOGIN_ROUTE,
            }}
          />
        )
      }
    />
  );
};

// --> for final route pages like <PostBooking/>
export const FinalRoute = ({ component: Component, condition, ...rest }) => {
  const [allowed, setAllowed] = useState(true);

  useEffect(() => {
    // if orgSelected is false, set allowed to false to reroute to login
    if (!condition) {
      setAllowed(false);
    }

    // clear path after 2 seconds if orgSelected
    let timeoutId;

    timeoutId = setTimeout(() => {
      uriStorage.clearPath();
      authService.logout();
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, []);

  if (!condition) {
    logger.info('Condition not true in <FinalRoute/>, redirecting to login...');
  }

  return <GuardedRoute condition={allowed} component={Component} {...rest} />;
};
