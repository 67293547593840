import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import Widget from 'rasa-webchat';
import { uriStorage } from '../../../utils/storage';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import Quinn from '../../../assets/beboquinn.svg';
import './overrides.css';
import { AssistantBloc, AssistantBlocEvent } from './assistant.bloc';
import ButtonCard from './cards/ButtonCard';
import { providerStorage } from '../../../utils/provider.qs';
import { globalBloc } from '../../global.bloc';
import PageContainer from '../../common/PageContainer';
import ChatTextInput from './Chat';
import ScrollableContainer, {
  DefaultDisconnectedPageFooter,
} from '../../common/ScrollableContainer';
import { Box, Grid, styled } from '@mui/material';
import { BOOKING_LIST_ROUTE } from '../../../utils/route.name';
import { authService } from '../../../utils/auth';
import { logger } from '../../../utils/logging';
import { getInLineUtil } from '../../../utils/getInLineUtils';

const styles = (theme) => ({
  chat: {
    height: '75%',
    //positions chat window
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      top: '80px',
      bottom: '80px',
      width: '100%',
    },
  },
});

export const ChatPagePanel = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 0',
  height: '100%',
  maxWidth: '700px',
  padding: '0 0 24px 10em',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    padding: '0 2em 32px 2em',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    padding: '0 0 32px 0',
  },
}));

class AssistantChat extends Component {
  lock;
  chatRef;
  bloc;
  blocStateSubscription;
  blocEventSubscription;

  constructor(props) {
    super(props);
    this.chatRef = React.createRef();
    this.bloc = new AssistantBloc(this.chatRef);

    this.state = {
      loaded: false,
      joined: false,
      complete: false,
      objective: globalBloc.objective(),
      url: `${process.env.REACT_APP_ASSISTANT_URL}`,
      path: `${process.env.REACT_APP_ASSISTANT_PATH}`,
      viewportWidth: window.innerWidth,
    };

    this.__stateHandler = this.__stateHandler.bind(this);
    this.__eventHandler = this.__eventHandler.bind(this);
  }

  componentDidMount() {
    logger.info('AssistantChat -> componentDidMount');

    window.addEventListener('resize', this.handleResize);

    analyticsEventLogger.log(AnalyticsEvent.QUINN_MEDICAL_ELICITATION);

    this.blocStateSubscription = this.bloc.subscribeToState(this.__stateHandler);
    this.blocEventSubscription = this.bloc.subscribeToEvents(this.__eventHandler);

    const currentPath = uriStorage.getCurrentPath();

    if (currentPath?.trim().startsWith('/booking/schedule')) {
      this.props.history.push(currentPath);
      return;
    }

    const { getInLine, walkinGetInLine, booking } = globalBloc.subject.value;
    const { organisation } = booking;

    const enabledLocations = getInLineUtil.enabledLocations();

    const location = organisation.id;

    const isGetInLineEnabled = enabledLocations.includes(location);

    const appointmentType =
      isGetInLineEnabled && (getInLine || walkinGetInLine) ? 'GET_IN_LINE' : 'RESERVE';

    this.setState({
      location: location,
      appointmentType: appointmentType,
    });

    sessionStorage.removeItem('chat_session');

    setTimeout(() => this.bloc.initialise(), 1000);
  }

  componentWillUnmount() {
    this.blocStateSubscription.unsubscribe();
    this.blocEventSubscription.unsubscribe();

    sessionStorage.removeItem('chat_session');
    sessionStorage.removeItem('currentPath');

    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ viewportWidth: window.innerWidth });
  };

  __extractInitialPayload = (token, fasttrack) => {
    let entities = { token: token };

    const objective = globalBloc.objective();

    if (objective) {
      entities.forced_agenda = objective.type;
      if (objective.context) {
        for (let key in objective.context) {
          if (objective.context.hasOwnProperty(key)) {
            entities[key] = objective.context[key];
          }
        }
      }
    }

    const currentProvider = providerStorage.getCurrentProvider();
    if (currentProvider) {
      entities.scheduling_type = 'walkin';
      entities.service_provider_id = currentProvider;
    }

    if (fasttrack) {
      entities.enabled_features = '|fast_track|';
    }

    return `/join_conversation${JSON.stringify(entities)}`;
  };

  __stateHandler = (subject) => {
    if (subject.initialised) {
      const initialPayload = this.__extractInitialPayload(subject.token, subject.fastTrack);

      this.setState({
        loaded: subject.initialised,
        locale: subject.locale,
        token: subject.token,
        initialPayload: initialPayload,
      });
    }
  };
  __eventHandler = (event) => {
    if (event.type === AssistantBlocEvent.NAVIGATE_TO) {
      this.setState({
        complete: true,
      });

      setTimeout(() => {
        this.props.history.push(event.url);
      }, 3000);
    }
  };

  __connect = (event) => {
    const { joined, initialPayload } = this.state;
    if (initialPayload && !joined && !this.locked) {
      this.locked = true;
      setTimeout(() => {
        this.bloc.sendCommand(initialPayload);
        setTimeout(() => {
          this.setState({ joined: true });
        }, 1000);
      }, 1000);
    }

    logger.info('connect ->', event);
  };

  __disconnect = (event) => {
    logger.info(event);
  };

  __customComponent = (messageData) => {
    if (messageData.attachment && messageData.attachment.type) {
      const payload = messageData.attachment.payload;

      switch (messageData.attachment.type) {
        case 'dh_map': {
          return <div>Map</div>;
        }
        case 'dh_card': {
          switch (payload.type) {
            case 'buttons': {
              return <ButtonCard bloc={this.bloc} attachment={messageData.attachment} />;
            }
            default:
              return null;
          }
        }
        default:
          return null;
      }
    }

    return null;
  };

  render() {
    const { classes, theme } = this.props;
    const { loaded, locale, location, url, path, initialPayload, joined, appointmentType } =
      this.state;

    return (
      <PageContainer
        loading={!joined}
        onBack={
          !authService.isLoggedIn()
            ? false
            : () => {
                this.props.history.replace(BOOKING_LIST_ROUTE);
              }
        }
      >
        <ScrollableContainer>
          <ChatPagePanel>
            {loaded && initialPayload && (
              <div id="chat" className={classes.chat}>
                <Widget
                  ref={this.chatRef}
                  initPayload={initialPayload}
                  profileAvatar={Quinn}
                  params={{
                    storage: 'session',
                  }}
                  customData={{
                    lang: locale,
                    location: location,
                    appointmentType: appointmentType,
                  }} //Make also clinic id
                  socketUrl={url}
                  socketPath={path}
                  embedded={true}
                  hideWhenNotConnected={false}
                  title={'Title'}
                  showMessageDate={true}
                  onSocketEvent={{
                    connect: this.__connect,
                    disconnect: this.__disconnect,
                    bot_uttered: this.bloc.handleBotEvent,
                  }}
                  customComponent={this.__customComponent}
                  userBackgroundColor={theme.palette.primary.main}
                  userTextColor={'#ffffff'}
                />
              </div>
            )}
          </ChatPagePanel>
        </ScrollableContainer>
        <DefaultDisconnectedPageFooter justifyContent={'left'}>
          <Grid xs={12} item>
            <ChatTextInput bloc={this.bloc} />
          </Grid>
        </DefaultDisconnectedPageFooter>
      </PageContainer>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(AssistantChat));
