import * as React from 'react';
import { Card, CardActions, CardContent, CardHeader } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { providerUtil } from '../../../utils/provider';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { serviceUtil } from '../../../utils/service';
import { TextMediumStandard } from '../Typography';
import { dateUtil } from '../../../utils/date';
import { useStyles } from './appointment-card-styles';

export const AppointmentCard = ({ appointment, organisation, controls }) => {
  const classes = useStyles();

  const serviceName = serviceUtil.friendlyNameSync(appointment.service);

  const appointmentType =
    appointment.type === 'VIRTUAL'
      ? 'Virtual'
      : providerUtil.formatOrganisationAddress(organisation);

  return (
    <Card className={classes.card} variant="outlined">
      <CardHeader
        classes={{
          title: classes.cardHeaderTitle,
          subheader: classes.cardHeaderTitleSubtitle,
        }}
        className={classes.cardHeader}
        title={serviceName}
        subheader={`at ${organisation?.name}`}
      />
      <CardContent className={classes.cardBody}>
        <div className={classes.cardBodyLine}>
          <span className={classes.cardBodyLineIcon}>
            <LocationOnIcon
              classes={{
                fontSizeLarge: classes.iconLarge,
              }}
              fontSize={'large'}
              color={'primary'}
            />
          </span>
          <TextMediumStandard className={classes.cardBodyLineText}>
            {appointmentType}
          </TextMediumStandard>
        </div>
        <div className={classes.cardBodyLine}>
          <span className={classes.cardBodyLineIcon}>
            <QueryBuilderIcon
              classes={{
                fontSizeLarge: classes.iconLarge,
              }}
              fontSize={'large'}
              color={'primary'}
            />
          </span>
          <TextMediumStandard className={classes.cardBodyLineText}>
            {dateUtil.fullFriendlyOutputExcludeYear(new Date(appointment.originalStartTime))}{' '}
            {dateUtil.getUserTimeZoneAbbreviation()}
          </TextMediumStandard>
        </div>
        <div className={classes.cardBodyLine}></div>
      </CardContent>
      {controls && <CardActions>{controls}</CardActions>}
    </Card>
  );
};
