import React, { useEffect } from "react";
import GoogleMapReact from "google-map-react";
import { Box, Grid, useTheme } from "@mui/material";
import { useBookingWizardContext } from "../BookingWizard/booking-wizard-context";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import OrganisationCard from "../../shared/OrganisationCard";
import { TextMediumStandard } from "../../shared/Typography";
import { FormattedMessage } from "react-intl";
import { globalBloc } from "../../global.bloc";
import GetInLineCard from "./GetInLineCard";

const OrgSelector = () => {
  const theme = useTheme();

  const { booking, bloc, availableOrganisations, loadingData } =
    useBookingWizardContext();
  const { getInLine } = globalBloc.subject.value;

  const Marker = ({ lat, lng, color }) => (
    <div style={{ position: "absolute", transform: "translate(-50%, -50%)" }}>
      <LocationOnIcon sx={{ fontSize: "36px", color }} fontSize="large" />
    </div>
  );

  useEffect(() => {
    globalBloc.updateCurrentBookingStep(0);
    sessionStorage.removeItem('task')
    bloc.clearSelectedOrg();
  }, []);

  const selectedOrg = React.useMemo(() => {
    return availableOrganisations.find(
      (_org) => _org.id === booking.selectedOrg
    );
  }, [availableOrganisations, booking.selectedOrg]);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
            version: "weekly",
            libraries: ["places"],
          }}
          defaultCenter={{
            lat: 30.45537972887279,
            lng: -91.0783767180397,
          }}
          center={
            selectedOrg
              ? {
                  lat: selectedOrg?.contactInformation?.address?.geoLocation
                    ?.latitude,
                  lng: selectedOrg?.contactInformation?.address?.geoLocation
                    ?.longitude,
                }
              : {
                  lat: 30.45537972887279,
                  lng: -91.0783767180397,
                }
          }
          defaultZoom={16}
          options={{
            zoomControl: false,
            fullscreenControl: false,
          }}
        >
          {selectedOrg && (
            <Marker
              lat={
                selectedOrg?.contactInformation?.address?.geoLocation?.latitude
              }
              lng={
                selectedOrg?.contactInformation?.address?.geoLocation?.longitude
              }
              color={theme.palette.accent.main}
            />
          )}
          {availableOrganisations?.map((_org) => (
            <Marker
              key={_org.id}
              lat={_org?.contactInformation?.address?.geoLocation?.latitude}
              lng={_org?.contactInformation?.address?.geoLocation?.longitude}
              color={theme.palette.accent.main}
            />
          ))}
        </GoogleMapReact>
      </div>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        height="100%"
        sx={(theme) => ({
          paddingLeft: "10em",
          marginBottom: "12px",
          [theme.breakpoints.down("md")]: {
            paddingLeft: "24px",
          },
        })}
      >
        <Grid item container>
          <Box
            sx={{
              backgroundColor: "rgb(255, 255, 255)",
              borderRadius: "6px",
              boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.3)",
              marginLeft: "10px",
            }}
          >
            <TextMediumStandard sx={{ padding: "0 1em" }}>
              <FormattedMessage
                id={"org.selection.title"}
                defaultMessage={"Please, select a clinic"}
              />
            </TextMediumStandard>
          </Box>
        </Grid>
        <Grid
          item
          container
          flexWrap="nowrap"
          width="100%"
          sx={{
            overflowX: "scroll",
            maxWidth: "88vw !important",
            paddingLeft: "10px",
          }} //maxWidth: '88vw !important' is a hack to center
        >
          {availableOrganisations
            ? availableOrganisations.map((org) =>
                getInLine && !org.getInLineEnabled ? null : (
                  <Box
                    key={org.id}
                    sx={{
                      width: "280px",
                      minWidth: "280px",
                      maxWidth: "280px",
                      cursor: "pointer",
                      margin: "12px 24px 12px 0",
                      [theme.breakpoints.down("sm")]: {
                        width: "260px",
                        minWidth: "260px",
                        maxWidth: "260px",
                      },
                    }}
                  >
                    {getInLine ? (
                      <GetInLineCard
                        organisation={org}
                        key={org.id}
                        setSelectedOrg={(e, capacity, date) =>
                          bloc.setSelectedOrg(org.id, capacity, date)
                        }
                        isSelected={selectedOrg?.id === org.id}
                        bloc={bloc}
                      />
                    ) : (
                      <OrganisationCard
                        organisation={org}
                        key={org.id}
                        onClick={(e, capacity, date) =>
                          bloc.setSelectedOrg(org.id, capacity, date)
                        }
                        isSelected={selectedOrg?.id === org.id}
                        bloc={bloc}
                      />
                    )}
                  </Box>
                )
              )
            : null}
        </Grid>
      </Grid>
    </>
  );
};

export default OrgSelector;
