import { CardContent, styled, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  fill: {
    flex: '1 1 auto',
  },
  card: {
    width: '320px',
    minWidth: '320px',
    maxWidth: '320px',
    margin: '12px 24px 12px 0',
    backgroundColor: '#F6F6F6',
    border: '0 solid #ffffff',
    borderRadius: '6px',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '85vw',
      minWidth: '85vw',
      margin: '0',
    },
  },

  cardHeader: {},

  cardHeaderTitle: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '32px',
    color: '#000000',
  },

  cardHeaderTitleSubtitle: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '32px',
    color: '#777777',
  },
  cardSelectedHeaderTitleSubtitle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#FFF',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  cardBody: {
    paddingTop: '24px',
    paddingBottom: '6px !important',
  },

  cardBodyLine: {
    display: 'flex',
    marginBottom: '14px',
  },

  cardBodyLineIcon: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#5C5D5F',
  },
  cardSelectedBodyLineIcon: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#FFF',
  },
  cardBodyLineText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#5C5D5F',
    paddingLeft: '18px',
  },
  cardSelectedBodyLineText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#FFF',
    paddingLeft: '18px',
  },

  buttonHolder: {
    marginTop: '18px',
  },
  button: {
    [theme.breakpoints.up('xs')]: {
      width: '40%',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
  iconLarge: {
    fontSize: '18px',
  },
  iconLargeWhite: {
    fontSize: '18px',
    color: '#fff',
  },
  documents: {
    paddingTop: '14px',
    paddingBottom: '14px',
    lineHeight: '14px',
  },
  checkBox: {
    transform: 'scale(1.5)',
    marginRight: '1em',
    marginLeft: '0.5em',
  },
  locationTerm: {
    color: theme.palette.primary.main,
  },
}));

export const StyledCardContent = styled(CardContent)({
  paddingTop: '0px',
  paddingBottom: '6px !important',
});

export const AddressText = styled(Typography)(({ theme, isMobile, sx }) => ({
  ...sx,
  lineHeight: '1.5rem',
}));

export const YouAreInLineText = styled(Typography)(({ theme, isMobile, sx }) => ({
  ...sx,
  color: 'green',
  fontWeight: 'bold',
}));

export const AppointmentInfoText = styled(Typography)(({ theme, isMobile, sx }) => ({
  ...sx,
  fontWeight: 'bold',
}));
