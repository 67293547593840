import React from 'react';
import Avatar from '@mui/material/Avatar';
import { FormattedMessage } from 'react-intl';
import { Box, Grid, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { TextChatBubble } from '../../components/shared/Typography'

const Bubble = ({ messageId, message, hasError, avatar, avatarName = 'Emma', showTime = false }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: "700px",
      }}
    >
      <Box
        sx={{
          alignSelf: 'flex-start',
          width: '100%',
          padding: '0em 0 0',
          marginBottom: '20px',
          [theme.breakpoints.down('sm')]: {
            padding: '0 0 0',
            marginBottom: '25px',
          },
        }}
      >
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Grid container direction={'row'} alignItems="flex-end">
            <Grid item>
              <Avatar
                variant="square"
                sx={{ top: '10px', left: '0', width: '33px', height: '33px' }}
                alt={avatarName}
                src={avatar}
              />
            </Grid>
            <Grid
              item
              sx={{
                paddingLeft: '0.5em',
                fontWeight: '600',
                fontSize: '1.1em',
              }}
            >
              {avatarName}
            </Grid>
            {showTime && <Grid item sx={{ paddingLeft: '0.5em' }}>
              at {format(new Date(), 'hh:mm aaa')}
            </Grid>}
          </Grid>
          <Grid
            sx={{
              background: hasError ? '#EB5757' : theme.palette.primary.light,
              color: hasError ? '#FFFFFF' : '#1D1D1D',
              padding: '1.325em 2.5625em 1.125em 1.375em',
              minHeight: '4.75em',
              borderRadius: '1em',
            }}
          >
            <TextChatBubble >
              <FormattedMessage
                id={messageId}
                defaultMessage={message}
                values={{
                  b: (chunks) => <b>{chunks}</b>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />,
                }}
              />
            </TextChatBubble>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Bubble;
