import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { authService } from '../../../utils/auth';
import { uriStorage } from '../../../utils/storage';
import { Box, Grid } from '@mui/material';
import { appointmentApi } from '../../../utils/services/appointments.api';
import { VIRTUAL_SCHEDULE } from '../../../utils/route.name';
import { dateUtil } from '../../../utils/date';
import { notificationService } from '../../../utils/notification';
import { ErrorMessage } from '../../../utils/error.resolver';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { CheckDevicePage } from './CheckDevice';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import PageContainer from '../../common/PageContainer';
import { ScrollableQuinnContainer } from '../../common/ScrollableContainer';
import { providerStorage } from '../../../utils/provider.qs';
import { H2TextTitleSecondaryContent, TextBody1Standard } from '../../shared/Typography';
import { globalBloc } from '../../global.bloc';
import { organisationsApi } from '../../../utils/services/organisations.api';
import { appointmentUtil } from '../../../utils/appointment';
import GetInLineAppointmentCard from '../../shared/AppointmentCard/GetInLineAppointmentCard';
import { AppointmentCard } from '../../shared/AppointmentCard';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  wrapper: {
    margin: 'auto',
    marginLeft: '0',
    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto',
    },
  },
  text: {
    marginTop: '1em',
    textAlign: 'left',
    fontWeight: '700',
    fontSize: '4.2em',
    lineHeight: '1',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      fontSize: '3.8em',
      textAlign: 'center',
    },
  },
  thank: {
    marginTop: '1em',
    textAlign: 'left',
    fontSize: '40px',
    lineHeight: '1',
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: '3em',
      margin: '0',
      padding: '0',
    },
  },
  confirmation: {
    marginTop: '1em',
    textAlign: 'left',
    fontWeight: '500',
    fontSize: '2.2em',
    lineHeight: '1',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8em',
      textAlign: 'center',
    },
  },
  linkBox: {
    marginTop: '4em',
    marginBottom: '4em',
    textAlign: 'left',
    lineHeight: '1',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  link: {
    textDecoration: 'underline',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
});

class PostBooking extends Component {
  constructor(props) {
    super(props);

    const provider = providerStorage.getCurrentProvider();
    const { getInLine } = globalBloc.subject.value;

    this.state = {
      loading: true,
      visitTime: '',
      atLocation: provider?.length > 0,
      appointment: {},
      isGetInLine: getInLine,
    };

    this.getInLineServiceType = 'INW-UC';
  }

  componentDidMount() {
    let { appointmentId } = this.props.match.params;

    if (!authService.getUser()) {
      uriStorage.clearPath();
      this.props.history.push('/');
      return;
    }

    this._fetchAppointmentData(appointmentId).then((data) => this._handleAppointmentData(data));
  }

  _fetchAppointmentData = (appointmentId) => {
    return this._fetchAppointmentStatus(appointmentId).then((results) => {
      const data = { ...results };

      organisationsApi.organisation(results.provider).then((orgResponse) => {
        this.setState({
          organisation: orgResponse.data,
        });
      });

      if (data.service === this.getInLineServiceType) {
        return this._fetchQueueStatus(appointmentId)
          .then((queueStatusData) => {
            data.queuePosition = queueStatusData;
            return data;
          })
          .catch((error) => {
            console.error('Error fetching queue status', error);
            return data;
          });
      }

      return data;
    });
  };

  _fetchAppointmentStatus = (appointmentId) => {
    return appointmentApi
      .getAppointmentStatus(appointmentId)
      .then((response) => response.data)
      .catch((error) => this._handleError(error, appointmentId));
  };

  _fetchQueueStatus = (appointmentId) => {
    return appointmentApi
      .getAppointmentQueueStatus(appointmentId)
      .then((response) => response.data);
  };

  _handleAppointmentData = (data) => {
    if (data?.status !== 'DRAFT') {
      uriStorage.clearPath();
      authService.logout();

      data.estimatedStartTime = data.start;
      const waitTime = data.queuePosition?.waitTime || 0;
      if (this.state.isGetInLine) {
        appointmentUtil.addGetInLineInformation(data, data.start, data?.queuePosition);
      } else {
        data.originalStartTime = data.start;
      }

      this.setState({
        appointment: data,
        waitTime,
        visitTime: dateUtil.fullFriendlyOutputExcludeYear(dateUtil.parseDate(data?.start)),
        loading: false,
      });
    } else {
      this.setState({ appointment: data, loading: false });
    }
  };

  _handleError = (error, appointmentId) => {
    analyticsEventLogger.log(AnalyticsEvent.BOOKING_STATUS_RETRIEVAL_ERROR, {
      appointmentId: appointmentId,
      reason: error,
    });
    notificationService.error('Error getting appointment status. ' + ErrorMessage.CALL_SUPPORT);

    // Log out after 5 seconds if the appointment status cannot be retrieved due to logged out user refreshing the page
    setTimeout(() => {
      uriStorage.clearPath();
      authService.logout();
      this.props.history.push('/');
    }, 5000);
  };

  render() {
    const { classes } = this.props;
    const {
      loading,
      visitTime,
      appointment,
      isGetInLine,
      atLocation,
      queuePosition,
      waitTime,
      organisation,
    } = this.state;
    let code = 'loading';

    if (appointment) {
      if (appointment.type && typeof appointment.type === 'string') {
        code = `${appointment.type.replace(/_-/g, '').toLowerCase()}`;
      } else {
        code = `general`;
      }

      if (appointment?.status === 'DRAFT') {
        return this.__renderDraft();
      }
    }

    if (appointment?.type === 'VIRTUAL') {
      return this.__renderVirtual();
    }

    if (atLocation) {
      return this.__renderWalkin();
    }

    return (
      <PageContainer loading={loading} hideBack={true}>
        <ScrollableQuinnContainer
          messageId={`postbooking.user.${code}.bubble`}
          message="Thank you for taking your place in line. You will receive a reminder closer to your arrival time and any updates. There are two ways to check in: Click on the TEXT LINK we send you OR Scan the QR code in the clinic."
        >
          <Grid item container spacing={0}>
            <Grid item xs={12}>
              {!loading &&
                (appointment.isGetInLine ? (
                  <GetInLineAppointmentCard
                    appointment={appointment}
                    visitTime={visitTime}
                    organisation={organisation}
                  />
                ) : (
                  <AppointmentCard
                    appointment={appointment}
                    visitTime={visitTime}
                    organisation={organisation}
                  />
                ))}
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.thank}>
                <FormattedMarkdown
                  id={`postbooking.thankyou.${code}.message`}
                  defaultMessage={`Thank You!`}
                />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <H2TextTitleSecondaryContent
                sx={(theme) => ({
                  marginTop: '-2.5rem',
                  textAlign: 'left',
                  [theme.breakpoints.down('md')]: {
                    textAlign: 'center',
                  },
                })}
              >
                <FormattedMarkdown
                  id={`postbooking.thankyou.${code}.notice`}
                  defaultMessage={`Please bring your ID and Proof of Insurance`}
                />
              </H2TextTitleSecondaryContent>
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }

  __renderDraft() {
    const { classes } = this.props;
    const { loading, appointment } = this.state;

    let code = 'loading';
    if (appointment) {
      if (appointment.service && typeof appointment.service === 'string') {
        code = `${appointment.service.replace(/-/g, '').toLowerCase()}`;
      } else {
        code = `general`;
      }
    }

    return (
      <PageContainer loading={loading} hideBack={true}>
        <ScrollableQuinnContainer
          messageId={`postbooking.user.${code}.bubble`}
          message="Please note, that your reservation has not been made yet. We will contact you shortly to finalize your reservation."
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex' }}>
                <Typography variant="bodyText" className={classes.thank}>
                  <FormattedMarkdown
                    id={`postbooking.thankyou.${code}.message`}
                    defaultMessage={`Thank You!`}
                  />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }

  __renderVirtual() {
    const { classes } = this.props;
    const { loading, visitTime, appointment } = this.state;
    const { appointmentId } = this.props.match.params;

    let code = 'loading';
    if (appointment) {
      if (appointment.service && typeof appointment.service === 'string') {
        code = `${appointment.service.replace(/-/g, '').toLowerCase()}`;
      } else {
        code = `general`;
      }
    }

    return (
      <PageContainer loading={loading} hideBack={true}>
        <CheckDevicePage appointmentId={appointmentId} />
      </PageContainer>
    );
  }

  __renderWalkin() {
    const { classes } = this.props;
    const { loading, visitTime, appointment } = this.state;

    let code = 'loading';
    if (appointment) {
      if (appointment.service && typeof appointment.service === 'string') {
        code = `${appointment.service.replace(/-/g, '').toLowerCase()}`;
      } else {
        code = `general`;
      }
    }

    return (
      <PageContainer loading={loading} hideBack={true}>
        <ScrollableQuinnContainer
          messageId={`postbooking.user.${code}.bubble`}
          message="That's it. We're done! You will be getting a reminder from us closer to your reservation."
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="bodyText" className={classes.thank}>
                <FormattedMarkdown
                  id={`postbooking.thankyou.${code}.message`}
                  defaultMessage={`Thank You!`}
                />
              </Typography>

              <TextBody1Standard
                sx={{
                  marginTop: '1em',
                  textAlign: 'left',
                  fontWeight: '500',
                  lineHeight: '1',
                }}
              >
                <FormattedMarkdown
                  id={`postbooking.reservation.${code}.message`}
                  defaultMessage={`Your visit has been reserved`}
                />
              </TextBody1Standard>

              {visitTime && (
                <TextBody1Standard
                  sx={{
                    marginTop: '1em',
                    textAlign: 'left',
                    fontWeight: '500',
                    lineHeight: '1',
                  }}
                >
                  <FormattedMarkdown
                    id="postbooking.time.message"
                    defaultMessage={`at ${visitTime}`}
                  />
                </TextBody1Standard>
              )}

              {this.props.match.params.appointmentType === VIRTUAL_SCHEDULE && (
                <TextBody1Standard
                  sx={{
                    marginTop: '1em',
                    textAlign: 'left',
                    fontWeight: '500',
                    lineHeight: '1',
                  }}
                >
                  <FormattedMarkdown
                    id="postbooking.virtual.message"
                    defaultMessage="You can close the browser. We will send you a reminder via SMS when the provider is ready to receive you."
                  />
                </TextBody1Standard>
              )}
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(PostBooking);
