export const initialState = {
  loading: false,
  activeStep: 0,
  scanSkipped: false,

  firstName: '',
  lastName: '',
  addressLine1: '',
  addressLine2: '',
  addressAdministrativeArea: '',
  addressCity: '',
  addressPostcode: '',
  addressCountry: 'US',
  codeCountry: 'US',
  code: '+1',
  number: '',
  email: '',
  gender: '',
  ssn: '',
  dateOfBirth: '',

  hearAboutUs: '',
  race: '',
  ethnicity: '',
  language: '',

  acceptTerms: false,

  registrationSuccess: false,

  invalidPhoneNumber: false,
  notConnectedPhoneNumber: false,

  frontImageValue: {},
  backImageValue: {},
  idVerificationReference: null,
  isPatientMyself: 'true',
  isFirstTimeVisit: 'true',

  pharmacy: {},
  responsibleParty: { relationship: 'SELF' },
  emergency: {},
};
