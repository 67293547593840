const secureStyles = (theme) => ({
  root: {
    display: 'flex',
    maxHeight: '100%',
    height: '100%',
    width: '100%',
    overscrollBehavior: 'contain',
    touchAction: 'none',
  },
  drawerContent: {
    width: '100%',
  },
  logoutButton: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(3),
    backgroundColor: '#878686',
    '&:hover': {
      backgroundColor: '#9d9c9c',
    },
  },
  lowerLogoutButton: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    backgroundColor: '#878686',
    '&:hover': {
      backgroundColor: '#9d9c9c',
    },
  },
});

export default secureStyles;
