import * as rxjs from 'rxjs';
import { assistantApi } from '../../../utils/services/assistant.api';
import { notificationService } from '../../../utils/notification';
import {
  LOGIN_ROUTE,
  QUINN_SCHEDULED,
  QUINN_TERMINATE_ROUTE,
  routeUtil,
} from '../../../utils/route.name';
import { dateUtil } from '../../../utils/date';
import { appointmentIntervalUtil } from '../../../utils/appointment';
import { appointmentApi } from '../../../utils/services/appointments.api';
import { providerStorage } from '../../../utils/provider.qs';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { logger } from '../../../utils/logging';
import { checkSession, setSession } from '../../../utils/storage';
import { globalBloc } from '../../global.bloc';
import { addUserDataHeap } from '../../../utils/heap/heapUtils';
import { authService } from '../../../utils/auth';

export class AssistantBloc {
  timeout;

  constructor(chatRef) {
    this.chatRef = chatRef;

    this.subject = new rxjs.BehaviorSubject({
      locale: 'en',
      chatAvailable: false,
      initialised: false,
    });

    this.events = new rxjs.Subject();
  }

  __updateSubject = (newProps) => {
    this.subject.next({
      ...this.subject.value,
      ...newProps,
    });
  };

  setChatRef = (ref) => {
    this.chatRef = ref;
  };

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  initialise = () => {
    assistantApi
      .generateToken()
      .then((value) => {
        if (providerStorage.isWalkin()) {
          this.isFastTrackAvailable().then(
            (available) => {
              this.subject.next({
                ...this.subject.value,
                token: value.data.token,
                initialised: true,
                fastTrack: available,
              });
              analyticsEventLogger.log(AnalyticsEvent.QUINN_FEATURES, {
                fasttrack: `${available}`,
              });
            },
            (reason) => {
              this.subject.next({
                ...this.subject.value,
                token: value.data.token,
                initialised: true,
              });
              analyticsEventLogger.log(AnalyticsEvent.QUINN_FEATURES, {
                fasttrack: `false`,
                error: reason,
              });
            },
          );
        } else {
          this.subject.next({
            ...this.subject.value,
            token: value.data.token,
            initialised: true,
          });
        }
      })
      .catch((reason) => notificationService.error('Error generating token for assistant.'));
  };

  isFastTrackAvailable = () => {
    const provider = providerStorage.getCurrentProvider();
    const now = new Date();
    const tomorrow = dateUtil.nextStartOfDay(now);
    const periodEnd = dateUtil.addhours(now, 2);

    return this.loadAvailability(now, tomorrow, provider, 'FAT-UC', undefined, true).then(
      (schedulingIntervals) => {
        try {
          let firstSlot = appointmentIntervalUtil.findFirstAvailableSlotForPeriod(
            schedulingIntervals,
            now,
            now,
            periodEnd,
          );

          if (firstSlot) {
            return true;
          }

          return false;
        } catch (e) {
          return false;
        }
      },
      (reason) => {
        return false;
      },
    );
  };

  loadAvailability = (start, end, organisationId, service, doctor, isWalkin) => {
    return appointmentIntervalUtil.loadAvailability(
      appointmentApi,
      start,
      end,
      organisationId,
      service,
      doctor,
      isWalkin,
    );
  };

  handleBotEvent = (event) => {
    const { chatAvailable } = this.subject.value;

    if (event.attachment) {
      this.handleAttachmentEvent(event.attachment);
    } else {
      this.enableChat();
    }

    if (event.text === 'TERMINATE_CONVERSATION|') {
      this.terminateConversation();
    } else if (!chatAvailable) {
      this.__updateSubject({ chatAvailable: true });
    }
  };

  handleAttachmentEvent = (attachment) => {
    switch (attachment.type) {
      case 'dh_card':
        this.disableChat();
        break;

      case 'dh_event':
        this.handleDhEvent(attachment.payload);
        break;

      default:
        break;
    }
  };

  handleDhEvent = (payload) => {
    if (!payload) return;

    if (payload.id) {
      this.updateSessionAndHeap(payload.id);
    }

    switch (payload.type) {
      case 'schedule.appointment.drafted':
        this.handleDraftedAppointment(payload);
        break;

      case 'redirect':
        if (payload.additionalProperties?.redirect === 'appointments') {
          this.redirectToAppointments();
        }
        break;

      case 'quinn.conversation.terminated':
        this.terminateConversation();
        break;

      default:
        break;
    }
  };

  updateSessionAndHeap = (appointmentId) => {
    setSession('appt', appointmentId);
    globalBloc.updateBooking({ appointmentId });
    addUserDataHeap({ appointmentId });
  };

  handleDraftedAppointment = (payload) => {
    this.disableChat();

    const { service, serviceCode, acuity } = payload.additionalProperties || {};

    if (acuity === 'emergent') {
      sessionStorage.setItem('acuity' + payload.id, 'emergent');
    }

    if (['workerscomp', 'occhealth'].includes(service)) {
      this.navigateTo(routeUtil.buildRoutingOcchealth(payload.id));
    } else if (['INP-UC', 'INW-UC', 'ANY-UC', 'FAT-UC'].includes(serviceCode)) {
      this.handleUrgentCare(payload);
    } else if (serviceCode === 'MEB-UC') {
      this.navigateTo(routeUtil.routeToMedicBuddy());
    } else {
      this.navigateTo(
        routeUtil.buildBookingPaymentMethodRouteWithDraftAppointmentID(payload.id, QUINN_SCHEDULED),
      );
    }
  };

  handleUrgentCare = (payload) => {
    const { service } = payload.additionalProperties || {};
    const user = authService.getUser();

    if (service === 'vip') {
      sessionStorage.setItem('service' + payload.id, 'vip');
    } else if (checkSession('appt', payload.id)) {
      user
        ? this.navigateTo(routeUtil.buildRoutingUrgentCare(payload.id))
        : this.navigateTo(routeUtil.buildPreAuthRoutingUrgentCare(payload.id));
      return;
    }

    this.navigateTo(routeUtil.buildRoutingUrgentCare(payload.id));
  };

  navigateTo = (url) => {
    this.events.next({
      type: AssistantBlocEvent.NAVIGATE_TO,
      url,
    });
  };

  redirectToAppointments = () => {
    sessionStorage.setItem('action', 'manage-appointments');
    this.navigateTo(LOGIN_ROUTE);
  };

  terminateConversation = () => {
    this.disableChat();
    this.__redirectToThankyou();
  };

  __redirectToThankyou = () => {
    setTimeout(() => {
      this.events.next({
        type: AssistantBlocEvent.NAVIGATE_TO,
        url: QUINN_TERMINATE_ROUTE,
      });
    }, 2000);
  };

  quickTextReply = (reply, text) => {
    if (this.chatRef.current && this.chatRef.current.sendMessage) {
      this.chatRef.current.sendMessage(reply, text);
    }
  };

  disableChat = () => {
    this.__updateSubject({ chatInputEnabled: false });
  };

  enableChat = () => {
    this.__updateSubject({ chatInputEnabled: true });
    clearTimeout(this.timeout);
  };

  sendCommand = (command) => {
    if (this.chatRef.current && this.chatRef.current.sendMessage) {
      this.chatRef.current.sendMessage(command);
    }
  };
  sendMessage = (text) => {
    if (this.chatRef.current && this.chatRef.current.sendMessage) {
      this.disableChat();
      this.chatRef.current.sendMessage(text, text);
    }
  };

  sendMessageWithReply = (text, reply) => {
    if (this.chatRef.current && this.chatRef.current.sendMessage) {
      this.disableChat();
      this.chatRef.current.sendMessage(text, reply);
    }
  };
}

export class AssistantBlocEvent {
  static NAVIGATE_TO = 'NAVIGATE_TO';
}
