import * as React from "react";
import { Wizard, Loading } from "../../shared-library";
import BookingWizard from "./BookingWizard/BookingWizard";
import { LOGIN_ROUTE, routeUtil } from "../../utils/route.name";
import { checkSession, setSession, uriStorage } from "../../utils/storage";
import "firebase/auth";
import { logger } from "../../utils/logging";
import { useEffect, useState } from "react";
import { globalBloc } from "../global.bloc";
import useBookingBloc from "./utils/useBookingBloc";
import { preventGoingBack } from "../../utils/preventGoingBack";
import { authService } from "../../utils/auth"

export const Booking = ({ match, history }) => {
  const { appointmentType, draftId } = match.params;

  const appointmentId = draftId;

  const { orgSelected, currentBookingStep } = globalBloc.subject.value;

  const [goBack, setGoBack] = useState(false);

  const bookingBloc = useBookingBloc(appointmentId, appointmentType, history);
  const { bloc } = bookingBloc;
  const { steps, initialising } = bloc.subject.value;

  useEffect(() => {
    logger.info("<Booking/> mounted");

    uriStorage.setCurrentPath(match.url);

    preventGoingBack();

    return () => {
      preventGoingBack(true);
    };
  }, []);

  const handleBack = React.useCallback(() => {
    uriStorage.clearPath(true);
    history.goBack();
  }, [history]);

  const handleTimeSelect = () => {
    sessionStorage.setItem("action", "login_after_setting_org");
    if (!authService.isLoggedIn()) {
      logger.debug("User not logged in, redirecting to login**");
      history.push(LOGIN_ROUTE);
    }
  };

  const handleBookingConfirmation = () => {
    const { __updateSubject, confirmAppointment, isVirtual, isWalkin } = bloc;

    __updateSubject({ loadingData: true });

    confirmAppointment()
      .then(() => {
        if (isVirtual()) {
          history.push(
            routeUtil.buildAppointmentCheckinConfirmation(appointmentId)
          );
        } else if (isWalkin()) {
          history.push(routeUtil.buildBookingIdentityDocument(appointmentId));
        } else {
          setSession("task", "confirm_booking");
          const route =
            routeUtil.buildPostBookingConfirmationRouteWithAppointmentID(
              appointmentId,
              "IN_PERSON"
            );
          history.push(route);
        }
      })
      .catch((e) => {
        logger.error("Error trying to confirm reservation: ", e.message);
        setGoBack(true);
      })
      .finally(() => {
        __updateSubject({ loadingData: false });
      });
  };

  const isTaskConfirmeBooking = checkSession("task", "confirm_booking");
  const isAtStepTwo = currentBookingStep === 2;
  const isOrgSelected = orgSelected || currentBookingStep === 1;

  const startAtStep =
    isTaskConfirmeBooking || isAtStepTwo ? 2 : isOrgSelected ? 1 : 0;

  if (!steps || initialising) {
    return <Loading isFullscreen />;
  }

  return (
    <Wizard steps={steps} startAtStep={startAtStep}>
      <BookingWizard
        isGoBack={goBack}
        setGoBack={setGoBack}
        bookingBloc={bookingBloc}
        onBack={handleBack}
        handleBookingConfirmation={handleBookingConfirmation}
        handleTimeSelect={handleTimeSelect}
      />
    </Wizard>
  );
};
