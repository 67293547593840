import { FormattedMarkdown } from "@decodedhealth/react-library";
import { Grid, Typography } from "@mui/material";
import React from "react";
import withStyles from "@mui/styles/withStyles";
import DecodedComponent from "../../shared/DecodedComponent";
import { BookingWizardConsumer } from "../BookingWizard/booking-wizard-context";
import { BookingBlocEvent } from "../bloc";
import {
  H2TextTitleContent,
  H2TextTitleSecondaryContent,
} from "../../shared/Typography";
import { Loading } from "../../../shared-library";
import { format, parse } from "date-fns";
import { AnalyticsEvent, analyticsEventLogger } from "../../../utils/events";
import { appointmentIntervalUtil } from "../../../utils/appointment";
import { globalBloc } from "../../global.bloc"
import { FormattedMessage } from "react-intl"
import { dateUtil } from "../../../utils/date"

const GridItem = (props) => <Grid {...props} item />;

const styles = (theme) => ({
  root: {},
});

class WalkinConfirmation extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = props.context.bloc;
    this.state = {
      local: { initialised: false, today: new Date() },
    };
    analyticsEventLogger.log(
      AnalyticsEvent.BOOKING_INPERSON_WALKIN_RESERVATION_OPEN,
      {}
    );

    this.__handleEvent = this.__handleEvent.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    this.bloc.estimateQueueStats();
  }

  __handleEvent = (event) => {
    switch (event.type) {
      case BookingBlocEvent.WALKIN_DETAILS_LOADED:
        const { schedulingIntervals } = this.bloc.subject.value;

        let firstSlot = appointmentIntervalUtil.findFirstAvailableSlotForDay(
          schedulingIntervals,
          new Date()
        );

        if (firstSlot?.start) {
          this.bloc.lockWalkinDetails(firstSlot?.start).then(
            (value) => {
              if (value !== "stop") {
                this.setState({
                  firstSlot: firstSlot,
                });
                this.bloc.lockedWalkinDetails();
                analyticsEventLogger.log(
                  AnalyticsEvent.BOOKING_INPERSON_WALKIN_RESERVATION_OPEN,
                  { status: "available" }
                );
              } else {
                this.setState({
                  error: true,
                });
                this.bloc.lockedWalkinDetails(true);
                analyticsEventLogger.log(
                  AnalyticsEvent.BOOKING_INPERSON_WALKIN_RESERVATION_OPEN,
                  { status: "unavailable" }
                );
              }
            },
            (reason) => {
              this.setState({
                error: true,
              });
              this.bloc.lockedWalkinDetails(true);
              analyticsEventLogger.log(
                AnalyticsEvent.BOOKING_INPERSON_WALKIN_RESERVATION_OPEN,
                { status: "error" }
              );
            }
          );
        } else {
          this.setState({
            full: true,
          });
          analyticsEventLogger.log(
            AnalyticsEvent.BOOKING_INPERSON_WALKIN_RESERVATION_OPEN,
            { status: "full" }
          );
        }
    }
  };

  _formatSlotTime = (slotTime) => {
    return format(slotTime, "pp");
  };

  componentDidCatch(error, info) {
    this.setState({ error: true });
  }

  render() {
    const { appointment, firstSlot, full, error } = this.state;

    if (error) {
      return (
        <Grid container direction="column">
          <H2TextTitleContent component={GridItem}>
            <FormattedMarkdown
              id="interaction.appointment.walkin.error.message1"
              defaultMessage="We were unable to determine the walk in capacity. Please go to the Front Desk."
            />
          </H2TextTitleContent>
        </Grid>
      );
    }

    if (!firstSlot && !full) {
      return (
        <Grid container direction="column">
          <Loading isFullscreen={false} />
        </Grid>
      );
    }

    if (full) {
      return (
        <Grid container direction="column">
          <H2TextTitleContent component={GridItem}>
            <FormattedMarkdown
              id="interaction.appointment.walkin.full.message1"
              defaultMessage="We have reached our patient capacity for the today. We apologize for the inconvenience."
            />
          </H2TextTitleContent>
          <H2TextTitleSecondaryContent component={GridItem}>
            <FormattedMarkdown
              id="interaction.appointment.walkin.full.message2"
              defaultMessage="Please go to the Front Desk if you are experiencing any of the following symptoms including but not limited to Chest Pain, Shortness of Breath, possible Stroke have an open Wound or Laceration, or a possible Broken Bone."
            />
          </H2TextTitleSecondaryContent>
        </Grid>
      );
    }

    let slotDisplay = undefined;
    if (firstSlot?.slots?.length > 0) {
      slotDisplay = firstSlot.slots[0].display;
    } else if (firstSlot?.start) {
      slotDisplay = this._formatSlotTime(firstSlot.start);
    }

    const service = appointment.service;

    const { walkinGetInLine } = globalBloc.subject.value;

    const waitTime = parseInt(sessionStorage.getItem("waitTime"), 10);

    if (service === "FAT-UC") {
      return (
        <Grid container direction="column">
          <H2TextTitleContent component={GridItem}>
            <FormattedMarkdown
              id="interaction.appointment.walkin.confirmation.message1"
              defaultMessage="Please know that we make every effort to see you promptly, however, wait times can vary. Patients with emergency conditions may be seen first."
            />
          </H2TextTitleContent>
          <H2TextTitleContent component={GridItem}>
            <FormattedMarkdown
              id="interaction.appointment.walkin.confirmation.message2"
              defaultMessage="Thanks for your understanding."
            />
          </H2TextTitleContent>
          {slotDisplay && (
            <H2TextTitleContent component={GridItem}>
              <FormattedMarkdown
                id="interaction.appointment.walkin.confirmation.message3"
                defaultMessage={`**Your approximate Fast Track reservation time is: ${slotDisplay}**`}
              />
            </H2TextTitleContent>
          )}
        </Grid>
      );
    }

    return (
      <Grid container direction="column">
        <H2TextTitleContent component={GridItem}>
          <FormattedMarkdown
            id="interaction.appointment.walkin.confirmation.message1"
            defaultMessage="Please know that we make every effort to see you promptly, however, wait times can vary. Patients with emergency conditions may be seen first."
          />
        </H2TextTitleContent>
        <H2TextTitleContent component={GridItem}>
          <FormattedMarkdown
            id="interaction.appointment.walkin.confirmation.message2"
            defaultMessage="Thanks for your understanding."
          />
        </H2TextTitleContent>
        {slotDisplay && (
          <H2TextTitleContent component={GridItem}>
            {walkinGetInLine ? (
              <FormattedMessage
                id="interaction.appointment.get_in_line.wait"
                defaultMessage="The approximate wait time is: {waitTime}"
                values={{ waitTime: dateUtil.formatWaitTimeDisplay(waitTime) }}
              >
                {(txt) => (
                  <Typography sx={{ fontWeight: "bold" }}>{txt}</Typography>
                )}
              </FormattedMessage>
            ) : (
              <FormattedMessage
                id={"interaction.appointment.walkin"}
                defaultMessage={
                  "Your approximate reservation time is: {nextSlotTime}"
                }
                values={{
                  nextSlotTime: slotDisplay,
                }}
              >
                {(txt) => (
                  <Typography sx={{ fontWeight: "bold" }}>{txt}</Typography>
                )}
              </FormattedMessage>
            )}
          </H2TextTitleContent>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)((props) => (
  <BookingWizardConsumer>
    {(value) => {
      return <WalkinConfirmation context={value} {...props} />;
    }}
  </BookingWizardConsumer>
));
