import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { deepGet, useWizardContext } from "../../../shared-library";
import PageContainer from "../../common/PageContainer";
import {
  DisconnectedPageFooter,
  PageContent,
  PagePanel,
  QuinnContent,
  ScrollableBox,
  ScrollableWrapper,
} from "../../common/ScrollableContainer";
import ChatBubble from "../../shared/ChatBubble";
import { BookingWizardProvider } from "./booking-wizard-context";
import { notificationService } from "../../../utils/notification";
import DecodedButton from "../../common/DecodedButton";
import withStyles from "@mui/styles/withStyles";
import { useHistory } from "react-router-dom";
import { globalBloc } from "../../global.bloc";
import OnBackDialog from "../components/OnBackDialog";
import { logger } from "../../../utils/logging";


const styles = (theme) => ({
  dialogButton: {
    maxWidth: "150px",
    margin: "1em",
  },
  dialogTitle: {
    color: theme.palette.primary.main,
  },
});

const BookingWizard = (props) => {
  logger.info("<BookingWizard/> mounted");

  const {
    onBack,
    bookingBloc,
    isGoBack,
    setGoBack,
    handleTimeSelect,
    handleBookingConfirmation,
  } = props;

  const { isFirstStep, activeStep, goBack, goNext, goBackTwoStep } =
    useWizardContext();

  const history = useHistory();

  const [openBackDialog, setOpenBackDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(bookingBloc.loadingData);

  const { orgSelected, getInLine } = globalBloc.subject.value;

  useEffect(() => {
    setIsLoading(bookingBloc.loadingData);
  }, [bookingBloc]);

  useEffect(() => {
    if (isGoBack) {
      goBackTwoStep();
      setGoBack(false);
    }
  }, [isGoBack]);

  const handleBack = useCallback(() => {
    if (isFirstStep) {
      onBack?.();
      return;
    }
    globalBloc.updateGlobalBloc({ orgSelected: false });
    goBack();
  }, [isFirstStep, goBack, onBack]);

  const handleSubmit = async () => {
    setIsLoading(true);
    if (activeStep.onSubmit) {
      try {
        const result = await activeStep.onSubmit();
        goNext();

        if (orgSelected) {
          handleTimeSelect();
        }

        if (result === "stop") {
          setIsLoading(false);
          return;
        }

        setIsLoading(false);
      } catch (e) {
        notificationService.error(
          "An error occurred trying to book your reservation. Please call the clinic."
        );
        setIsLoading(false);
        goBack();
      }
    } else {
      handleBookingConfirmation();
    }
  };

  const buttonLabelSubmit = () => {
    if (activeStep.submitLabel) {
      if (typeof activeStep.submitLabel == "function") {
        return activeStep.submitLabel();
      } else {
        return activeStep.submitLabel ?? "Select";
      }
    } else {
      return "Select";
    }
  };

  const currentStepValue = deepGet(bookingBloc, activeStep.dataKey, undefined);

  const handleClickOpen = () => {
    setOpenBackDialog(true);
  };

  const handleClose = () => {
    setOpenBackDialog(false);
  };

  const handleRedirect = () => {
    history.push("/");
  };

  return (
    <BookingWizardProvider
      value={{
        ...bookingBloc,
      }}
    >
      <PageContainer
        loading={isLoading}
        onBack={activeStep.id !== "org-selector" ? handleBack : handleClickOpen}
      >
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          flex="1"
        >
          <ScrollableWrapper>
            {activeStep.message && (
              <QuinnContent>
                <ChatBubble
                  messageId={
                    typeof activeStep.message.id === "string" ||
                    activeStep.message.id instanceof String
                      ? activeStep.message.id
                      : activeStep.message.id()
                  }
                  message={
                    typeof activeStep.message.text === "string" ||
                    activeStep.message.text instanceof String
                      ? activeStep.message.text
                      : activeStep.message.text()
                  }
                />
              </QuinnContent>
            )}
            <ScrollableBox>
              {activeStep.message === undefined ||
              activeStep.message.id === undefined ? (
                <>{activeStep.component}</>
              ) : (
                <PagePanel>
                  <PageContent>{activeStep.component}</PageContent>
                </PagePanel>
              )}
            </ScrollableBox>
          </ScrollableWrapper>
          {getInLine && activeStep.id === "org-selector" ? null : ( //--> hides the submit button if the user get in line and selecting a new location/slot
            <DisconnectedPageFooter>
              <DecodedButton
                loading={isLoading}
                onClick={handleSubmit}
                disabled={activeStep.dataKey ? !currentStepValue : false}
              >
                {buttonLabelSubmit()}
              </DecodedButton>
            </DisconnectedPageFooter>
          )}
        </Grid>
      </PageContainer>
      <OnBackDialog
        openBackDialog={openBackDialog}
        handleClose={handleClose}
        handleRedirect={handleRedirect}
        styles={styles}
      />
      ;
    </BookingWizardProvider>
  );
};
export default withStyles(styles)(BookingWizard);
