import { DecodedBloc } from '../../shared/DecodedComponent/bloc';
import { appointmentApi } from '../../../utils/services/appointments.api';
import { providerStorage } from '../../../utils/provider.qs';
import { appointmentIntervalUtil } from '../../../utils/appointment';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { dateUtil } from '../../../utils/date';
import {
  ANY_SERVICE_CODE,
  DEFAULT_SERVICE_CODE,
  WALK_IN_SERVICE_CODE,
} from '../../../utils/service';
import { getInLineUtil } from '../../../utils/getInLineUtils';

export class Bloc extends DecodedBloc {
  constructor(options) {
    super(options);
    this.onNext = options.onNext;

    sessionStorage.removeItem('checkin');
  }

  initialise = async () => {
    const { isWalkin } = this.subject.value;

    appointmentApi
      .getAvailableOrganisations([DEFAULT_SERVICE_CODE, ANY_SERVICE_CODE, WALK_IN_SERVICE_CODE])
      .then(
        (value) => {
          const getInLineLocations = getInLineUtil.enabledLocations();

          const organisations = value.data.items.map((org) => ({
            ...org,
            getInLineEnabled: getInLineLocations.includes(org.id),
          }));

          this.__updateSubject({
            organisations: organisations.sort((a, b) =>
              a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
            ),
          });

          if (isWalkin) {
            const walkinLocationId = this.walkinLocation();
            const organisation = organisations.find((o) => o.id === walkinLocationId);
            if (organisation?.id) {
              const service = organisation.getInLineEnabled
                ? WALK_IN_SERVICE_CODE
                : ANY_SERVICE_CODE;
              this.__loadWalkinAvailability(organisation, service).finally(() =>
                this.__makeInitialised(),
              );
            } else {
              this.hideAvailability();
              this.__makeInitialised();
            }
          } else {
            this.__makeInitialised();
          }
        },
        (reason) => {
          this.hideAvailability();
          this.__makeInitialised();
        },
      )
      .catch((reason) => {
        this.hideAvailability();
        this.__makeInitialised();
      });
  };

  __loadWalkinAvailability = (organisation, service) => {
    const now = new Date();
    let tomorrow = dateUtil.nextStartOfDay(now);

    return this.loadAvailability(now, tomorrow, organisation.id, service, undefined, true).then(
      (intervals) => {
        let firstWalkinSlot = appointmentIntervalUtil.findFirstAvailableSlotForDay(intervals, now);

        if (firstWalkinSlot) {
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_SUCCESS, {
            schedule: 'walkincheck',
            slot: firstWalkinSlot.slots[0].display,
          });

          this.__updateSubject({ firstWalkinSlot: firstWalkinSlot });
        } else {
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_SUCCESS, {
            schedule: 'full',
          });

          this.showWalkinUnavailable();
        }
      },
      (reason) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_ERROR, {
          reason: reason,
        });
        this.hideAvailability();
      },
    );
  };

  setCheckin = () => {
    sessionStorage.setItem('checkin', 'true');
  };

  unsetCheckin = () => {
    sessionStorage.removeItem('checkin');
  };

  isWalkin = () => this.subject.value.isWalkin;

  walkinLocation = () => providerStorage.getCurrentProvider() || '';

  showWalkin = () => {
    this.__updateSubject({ showWalkinTime: false, showWalkin: true });
  };

  showWalkinTime = () => {
    this.__updateSubject({ showWalkinTime: true, showWalkin: false });
  };

  hideWalkin = () => {
    this.__updateSubject({ showWalkin: false });
  };

  showWalkinUnavailable = () => {
    this.__updateSubject({ walkinFull: true });
  };

  loadAvailability = (start, end, organisationId, service, doctor, isWalkin) => {
    return appointmentIntervalUtil.loadAvailability(
      appointmentApi,
      start,
      end,
      organisationId,
      service,
      doctor,
      isWalkin,
    );
  };

  altLoadAvailability = (start, end, organisationId, service, doctor, isWalkin) => {
    return appointmentApi
      .getAvailableAppointmentScheduleBetweenAnonymous(
        start,
        end,
        organisationId,
        service,
        doctor,
        !isWalkin,
      )
      .then(
        (value) => {
          return value.data.results[0];
        },
        (reason) => {
          return reason;
        },
      );
  };

  makeReservationAvailable = () => {
    this.__updateSubject({ reservationAvailable: true });
  };
  makeSwitchAvailable = () => {
    this.__updateSubject({ switchAvailable: true });
  };

  clearProviderAndHideAvailability = () => {
    const { props } = this.subject.value;
    providerStorage.clearProviderOnly();
    props.history.replace('/login');
    this.__updateSubject({ showAvailability: false });
  };
  hideAvailability = () => {
    this.__updateSubject({ showAvailability: false });
  };

  setSelectedOrg = (org, capacity, date) => {
    const { booking } = this.subject.value;

    let newBooking = { ...booking };
    newBooking.selectedOrg = org;
    newBooking.selectedOrgCapacity = capacity;
    newBooking.selectedDate = date || new Date();
    newBooking.availability = undefined;
    newBooking.reminderTime = undefined;

    analyticsEventLogger.log(
      AnalyticsEvent.BOOKING_APPOINTMENT_BOOKING_APPOINTMENT_ORGANISATION_LOADED_SELECT,
      { organisation: org, capacity: `${capacity}` },
    );

    this.subject.next({
      ...this.subject.value,
      booking: newBooking,
    });
  };

  clearSelectedOrg = () => {
    const { booking } = this.subject.value;

    let newBooking = { ...booking };
    newBooking.selectedOrg = undefined;
    newBooking.selectedOrgCapacity = undefined;
    newBooking.selectedDate = undefined;
    newBooking.availability = undefined;
    newBooking.reminderTime = undefined;

    this.subject.next({
      ...this.subject.value,
      booking: newBooking,
    });
  };

  updateSelectedOrg(selectedOrg) {
    this.subject.next({ ...this.subject.value, selectedOrg });
  }
}

export class BlocEvent {
  static INITIALISED = 'INITIALISED';
  static NAVIGATE_TO = 'NAVIGATE_TO';
}
