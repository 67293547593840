import React from "react";
import { Button } from "@mui/material";

export const NextButton = (props) => {
  return (
    <Button {...props} variant="contained" fullWidth type="submit">
      {props.label}
    </Button>
  );
};
