import React, { useEffect, useState } from "react";
import Loading from "../shared/Loading";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { LOGIN_ROUTE } from "../../utils/route.name";


//? ---> this is a temporary kiosk for provider testing <---

const KioskTemporary = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  const history = useHistory();

  //kiosk-temp/d93560e4-c6be-11ed-8c75-42010a92401c
  //kiosk-temp-exit

  useEffect(() => {
    let timeoutId;
    if (match.params.organisationId) {
      setMessage("Loading Temporary Kiosk...");

      localStorage.setItem("currentProvider", match.params.organisationId);
      timeoutId = setTimeout(() => {
        history.push(LOGIN_ROUTE);
      }, 5000);
    } else {
      setMessage("Exiting Temporary Kiosk...");
      localStorage.setItem("currentProvider", "");
      timeoutId = setTimeout(() => {
        history.push(LOGIN_ROUTE);
      }, 5000);
    }

    return () => {
      clearTimeout(timeoutId);
      setLoading(false);
    };
  }, []);

  return <>{loading && <Loading message={message} />}</>;
};

export default KioskTemporary;
