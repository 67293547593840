import * as rxjs from 'rxjs';
import { systemApi } from '../utils/services/system.api';
import { notificationService } from '../utils/notification';
import { AnalyticsEvent, analyticsEventLogger } from '../utils/events';

export class GlobalBloc {
  constructor() {
    const savedState = JSON.parse(sessionStorage.getItem('globalState'));

    this.initialState = {
      initialising: false,
      loading: false,
      systemProperties: [],
      booking: {
        selectedSlot: {
          display: '',
        },
        firstAvailableCapacity: '',
        firstAvailableDate: '',
        organisation: {
          id: localStorage.getItem('currentProvider') || '',
        },
        currentStep: 0,
      },
      getInLine: false,
      walkinGetInLine: false,
      orgSelected: false,
      insurances: [],
      kioskRestart: false,
      currentBookingStep: 0,
    };

    this.subject = new rxjs.BehaviorSubject(savedState || this.initialState);
    this.events = new rxjs.Subject();
  }

  subscribeToEvents = (func) => this.events.subscribe(func);

  subscribeToState = (func) => {
    return this.subject.subscribe((newState) => {
      sessionStorage.setItem('globalState', JSON.stringify(newState));
      func(newState);
    });
  };

  updateGlobalBloc(update) {
    this.subject.next({
      ...this.subject.value,
      ...update,
    });
  }

  updateCurrentBookingStep = (currentBookingStep) => {
    this.subject.next({
      ...this.subject.value,
      currentBookingStep: currentBookingStep,
    });
  };

  initialise = () => {
    this.__loadProperties();
  };

  makeInitialised = () => {
    this.subject.next({
      ...this.subject.value,
      initialising: false,
    });
  };

  __loadProperties = () => {
    systemApi.systemProperties().then(
      (value) => {
        analyticsEventLogger.log(AnalyticsEvent.SYSTEM_PROPERTIES_LOADED_SUCCESS);

        const systemProperties = value.data.items;
        this.subject.next({
          ...this.subject.value,
          initialising: false,
          systemProperties: systemProperties,
        });
      },
      (reason) => {
        analyticsEventLogger.log(AnalyticsEvent.SYSTEM_PROPERTIES_LOADED_ERROR, {
          reason: reason,
        });
        notificationService.error('Unable to load system properties. Please retry later.');
      },
    );
  };

  quinnChat = () => {
    const values = this.subject.value.systemProperties.filter(
      (_property) => _property.code === 'quinn.chat',
    );
    if (values.length === 0) {
      return true;
    }

    return values[0].status === '1';
  };

  objective = () => {
    return this.subject.value.objective;
  };

  updateBooking(newBooking) {
    this.subject.next({
      ...this.subject.value,
      booking: {
        ...this.subject.value.booking,
        ...newBooking,
      },
    });
  }

  resetGlobalBloc() {
    this.subject.next(this.initialState);
  }
}

export class GlobalBlocEvent {}

export const globalBloc = new GlobalBloc();
