import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import Loading from '../shared/Loading';
import { checkSession, uriStorage } from '../../utils/storage';
import { authService } from '../../utils/auth';
import { providerStorage } from '../../utils/provider.qs';
import Welcome from '../Login/Welcome';
import { logger } from '../../utils/logging';
import { LoginDataProvider } from './LoginForm/LoginDataProvider';
import { QUINN_ROUTE, ROOT_ROUTE } from '../../utils/route.name';
import LoginForm from './LoginForm/LoginForm';

const styles = (theme) => ({
  versionTitle: {
    marginBottom: '2.2rem',
    fontStyle: 'normal',
  },
  loginTitle: {
    marginTop: '2rem',
    marginBottom: '0.1rem',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '54px',
    '& span': {
      color: theme.palette.primary.main,
    },
  },
});

const initState = {
  loading: false,
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  email: '',
  code: '+1',
  codeCountry: 'US',
  number: '',
  ssn: '',
  acceptLocationTerm: false,
  activeStep: 0,
  verificationSuccess: false,
  errorMessage: '',
  pinCode: '',
};

class Login extends Component {
  constructor(props) {
    super(props);
    const action = sessionStorage.getItem('action');
    this.state = {
      action: action,
      ...initState,
    };
  }

  componentDidMount() {
    logger.debug('<Login/> mounted');

    if (authService.isLoading()) {
      authService.registerLoadedCallback(this._initialise);
    } else {
      this._initialise();
    }

    providerStorage.setCurrentProvider(this.props.location.search);
  }

  _initialise = () => {
    const { location, history } = this.props;
    const user = authService.getUser();
    const postRegister = checkSession('task', 'post-register');

    if (location?.state?.redirectStep && !user) {
      this.setState({
        activeStep: location.state.redirectStep,
        redirectReason: location.state.redirectReason,
      });
    }

    if (!user) {
      this.setState({ loading: false });
    } else if (user && !postRegister) {
      history.push(ROOT_ROUTE);
    } else if (postRegister) {
      history.push(QUINN_ROUTE);
    } else {
      const nextRoute = location?.state?.next || ROOT_ROUTE;
      history.replace(nextRoute);
    }
  };

  loginInOnly = () => {
    const { action } = this.state;

    return (
      action === 'registration.only' ||
      action === 'manage-appointments' ||
      action === 'anonymous_schedule' ||
      action === 'get_in_line_booking' ||
      action === 'login_after_setting_org'
    );
  };

  handleCheckboxChange = (event) => {
    let change = {};
    change[event.target.name] = event.target.checked;
    this.setState(change);
  };

  handleNext = () => {
    this.setState((state) => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleBackToLast = () => {
    this.props.history.goBack();
  };

  handleTextChange = (event) => {
    let change = {};
    change[event.target.name] = event.target.value;
    this.setState(change);
  };

  handleCodeChange = (code, codeCountry) => {
    this.setState({ code, codeCountry, number: '' });
  };

  handleDateChange = (date) => {
    this.setState({
      dateOfBirth: date,
    });
  };

  setLoadingStatus = (value) => {
    this.setState({
      loading: value,
    });
  };

  setConfirmationResult = (value) => {
    this.setState({
      confirmationResult: value,
    });
  };

  getStepContent = (step) => {
    if (this.loginInOnly()) {
      switch (step) {
        case 0:
          return (
            <LoginDataProvider>
              <LoginForm
                {...this.state}
                history={this.props.history}
                handleNext={this.handleNext}
                onCodeChange={this.handleCodeChange}
                handleTextChange={this.handleTextChange}
                setLoadingStatus={this.setLoadingStatus}
                setConfirmationResult={this.setConfirmationResult}
                handleBack={this.handleBackToLast}
              />
            </LoginDataProvider>
          );

        default:
          throw new Error('Unknown step');
      }
    }

    switch (step) {
      case 0:
        return (
          <Welcome
            location={this.props.location}
            acceptLocationTerm={this.state.acceptLocationTerm}
            handleNext={this.handleNext}
            handleCheckboxChange={this.handleCheckboxChange}
          />
        );
      case 1:
        return (
          <LoginDataProvider>
            <LoginForm
              {...this.state}
              history={this.props.history}
              handleNext={this.handleNext}
              handleBack={this.handleBack}
              onCodeChange={this.handleCodeChange}
              handleTextChange={this.handleTextChange}
              setLoadingStatus={this.setLoadingStatus}
              setConfirmationResult={this.setConfirmationResult}
            />
          </LoginDataProvider>
        );

      default:
        throw new Error('Unknown step');
    }
  };

  isRedirectedToNext = () => {
    return this.props.location.state && uriStorage.getCurrentPath() != null;
  };

  render() {
    let { activeStep } = this.state;

    if (this.state.loading) {
      return <Loading />;
    }

    return this.getStepContent(activeStep);
  }
}

export default withStyles(styles)(Login);
