import React, { useCallback, useEffect, useState} from 'react';
import { dateUtil } from '../../../utils/date';
import { useBookingWizardContext } from '../BookingWizard/booking-wizard-context';
import { Grid } from '@mui/material';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import TimeBarChart from './TimeBarChart';
import { TimeIntervalButton } from './TimeIntervalButton';
import { H2TextTitleContent, TextBody2Standard } from '../../shared/Typography';
import { useMediaQueries } from '../../../utils/hooks/useMediaQueries';
import { globalBloc } from '../../global.bloc';
import { appointmentIntervalUtil } from '../../../utils/appointment';

const TimeSelector = () => {
  const [walkinStatus, setWalkinStatus] = useState(null);

  const { isSm } = useMediaQueries();

  const { schedulingIntervals, booking, bloc, intervals } = useBookingWizardContext();

  const selectedSlot = booking.selectedSlot;

  const orgID = bloc.subject.value.booking.selectedOrg;

  const { getInLine } = globalBloc.subject.value;

  useEffect(() => {
    bloc.loadSelectedDayAvailability();
  }, []);

  const walkInServiceStatus = () => {
    const now = new Date();
    const tomorrow = dateUtil.nextStartOfDay(now);
    const {availableOrganisations=[]} = bloc.subject.value
    const useGetInLineAppointmentService = availableOrganisations
      .filter(org => org.getInLineEnabled)
      .map(org => org.id)
      .includes(orgID);

    const service = useGetInLineAppointmentService ? bloc.walkInService() : bloc.service();

    //=> apply rules needs to be true to get walkin slot (later api sets to false)
    bloc.loadAvailability(now, tomorrow, orgID, service, undefined, true)
      .then((schedulingIntervals) => {
        let firstWalkinSlot = appointmentIntervalUtil.findFirstAvailableSlotForDay(schedulingIntervals, now);

        if (firstWalkinSlot) {
          setWalkinStatus(`CURRENTLY AVAILABLE`);
        } else {
          setWalkinStatus('CURRENTLY UNAVAILABLE');
        }
      })
      .catch(() => {
        setWalkinStatus('Unable to load');
      });
  }

  useEffect(() => {
    walkInServiceStatus();
  },[bloc, orgID]);



  const handleTimeSelected = useCallback(
    (slot) => () => {
      bloc.setBookingTime(slot);
    },
    [bloc],
  );

  const isAvailable = intervals && intervals.length > 0;

  return (
    <Grid>
      {isAvailable && (
        <Grid item row xs={12} sx={{ marginTop: isSm ? '-2.5em' : null }}>
          <TextBody2Standard>
            <FormattedMarkdown
              id={'interaction.appointment.reservation.selecttime.subtext.0'}
              defaultMessage={'You can see the approximated waiting on the graph below.'}
            />
          </TextBody2Standard>
        </Grid>
      )}
      {isAvailable && <TimeBarChart schedulingIntervals={schedulingIntervals} />}
      <Grid item row xs={12}>
        {intervals && intervals.length > 0 ? (
          intervals.map((time) => {
            return !(time.capacity === 0) ? (
              <TimeIntervalButton
                key={`btn-slot-${time.start}`}
                onClick={handleTimeSelected(time.start)}
                isSelected={selectedSlot === time.start}
              >
                {dateUtil.formatTimeDisplay(
                  dateUtil.parseDate(
                    dateUtil.addMinutesToUtc(time.availableSlots[0].start, time.start),
                  ),
                )}
              </TimeIntervalButton>
            ) : null;
          })
        ) : (
          <TextBody2Standard
            sx={{
              fontWeight: '500',
              lineHeight: '32px',
            }}
          >
            <FormattedMarkdown
              id={'booking.appointment.schedule.chart.unavailable'}
              defaultMessage={'No time slots are available for selected date.'}
            />
          </TextBody2Standard>
        )}
      </Grid>
      {walkinStatus && !getInLine && (
        <Grid item row xs={12}>
          <TextBody2Standard
            sx={{
              fontWeight: '500',
              lineHeight: '32px',
            }}
          >
            WALK-INS:

          </TextBody2Standard>
          <TextBody2Standard
            sx={{
              fontWeight: '500',
              lineHeight: '32px',
            }}
            color={walkinStatus === 'CURRENTLY UNAVAILABLE' ? 'secondary' : 'primary'}
          >

            {walkinStatus}
          </TextBody2Standard>
        </Grid>
      )}
      {intervals && intervals.length === 0 && (
        <Grid item row xs={12}>
          <H2TextTitleContent>
            {booking.selectedOrgCapacity === 'full' ? (
              <FormattedMarkdown
                id={`booking.timeselector.noslots.options.full.text`}
                defaultMessage={`We have reached our patient capacity for the today. We apologize for the inconvenience.\n`}
              />
            ) : (
              <FormattedMarkdown
                id={`booking.timeselector.noslots.options.text`}
                defaultMessage={`Our online reservations are currently full but we have walk-in availability.\n`}
              />
            )}
          </H2TextTitleContent>
        </Grid>
      )}
    </Grid>
  );
};

export default TimeSelector;
