import { Grid } from '@mui/material';
import { buttonGroupStyle } from '../../shared/CommonStyle';
import { useTheme } from '@mui/styles';

export const styles = (theme) => ({
  ...buttonGroupStyle(theme),
  root: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  text: {
    marginTop: '1em',
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '4.2em',
    lineHeight: '1',
    color: theme.palette.secondary.main,
  },
  confirmation: {
    marginTop: '1em',
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '2.2em',
    lineHeight: '1',
  },
  list: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  listItem: {
    paddingTop: '2em',
    paddingBottom: '2em',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  listItemComponent: {
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      width: '100%',
    },
  },
  listItemButtonComponent: {
    minWidth: '100%',
    width: '100%',
    paddingTop: '1em',
  },
  progressBox: {
    margin: '0 auto 0 auto',
  },
  progress: {
    color: theme.palette.secondary.main,
  },
  buttons: {
    paddingTop: '2em',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  button: {
    width: '100%',
    padding: '1em',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  dialogButton: {
    maxWidth: '150px',
    margin: '1em',
  },
  phoneNumberText: {
    color: '#737373',
    fontWeight: '550',
    fontSize: '1.5em',
    textAlign: 'center',
    paddingTop: '1.5em',
    paddingBottom: '1.5em',
  },
  cancelButton: {
    fontSize: '1.3em',
    minHeight: '30px',
  },
  appointmentType: {
    fontSize: '1.6em',
    fontWeight: '700',
    textAlign: 'left',
  },
  appointmentTime: {
    fontSize: '1.3em',
    paddingTop: '0.5em',
    textAlign: 'left',
  },
  dialogTitle: {
    color: theme.palette.primary.main,
  },
  cardRoot: {
    padding: '1em',
    maxWidth: '100%',
    backgroundColor: '#F6F6F6',
    borderRadius: '1em',
  },
  reservationText: {
    color: theme.palette.primary.main,
    // textAlign: "left",
    paddingTop: '2em',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'center',
    },
  },
  reservationTextSecondary: {
    color: theme.palette.error.main,
    // textAlign: "left",
    paddingTop: '1em',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'center',
    },
  },
});

export const AppointmentItemsContainer = ({ children }) => {
  const theme = useTheme();
  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      flexWrap="wrap"
      spacing={1}
      children={children}
      sx={{
        width: '90%',
        [theme.breakpoints.down('md')]: {
          width: '100%',
        },
      }}
    />
  );
};
