import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
} from '@mui/material';


const ExitLoginDialog = (props) => {
    const { open, onExit, onContinue } = props;
    return (
      <Dialog
        open={open}
        onClose={onContinue}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Are you sure you want to exit this session and return home?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={onExit} variant="contained" color="error" autoFocus>
            YES
          </Button>
          <Button onClick={onContinue} variant="outlined" color="primary" autoFocus>
            NO
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  


export default ExitLoginDialog