import React from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import { setSession } from '../../utils/storage';
import BasicInformation from './BasicInformation';
import { FormControlLabel, Link } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import Address from './AddressForm';
import { registrationApi } from '../../utils/services/register.api';
import { authService } from '../../utils/auth';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';
import { notificationService } from '../../utils/notification';
import { ErrorMessage, errorResolver } from '../../utils/error.resolver';
import { userInfoUtil } from '../../utils/user';
import { phoneUtil } from '../../utils/phone';
import {
  BOOKING_LIST_ROUTE,
  QUINN_ROUTE,
  routeUtil,
  USER_INFORMATION_ROUTE,
} from '../../utils/route.name';
import {
  InvalidPhoneNumberModal,
  NotConnectedPhoneNumberModal,
} from './PhoneNumberVerificationModals';
import { Checkbox } from '../shared/components/Checkbox';
import DecodedComponent from '../shared/DecodedComponent';
import { EmergencyContact } from './EmergencyContact';
import { Bloc } from './bloc';
import { pharmacyApi } from '../../utils/services/pharmacy.api';
import { consumerApi } from '../../utils/services/consumers.api';
import { logger } from '../../utils/logging';
import { providerStorage } from '../../utils/provider.qs';
import { addUserDataHeap } from '../../utils/heap/heapUtils';
import { styles } from './utils/styles';
import { initialState } from './utils/initialState';
import { createAccountRequest } from './utils/createAccountRequest';
import { preventGoingBack } from '../../utils/preventGoingBack';
import { appointmentApi } from '../../utils/services/appointments.api';

class Registration extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = new Bloc({});
    this.state = {
      isAtLocation: providerStorage.hasProvider(),
      appointmentId: sessionStorage.getItem('appt'),
      ...initialState,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    const { loginDetails } = this.bloc.subject.value;

    logger.debug('Registration mounted');

    preventGoingBack();

    analyticsEventLogger.log(AnalyticsEvent.REGISTER_OPEN);

    if (loginDetails) {
      this.setState({
        ...this.props.location.state,
        firstName: loginDetails?.firstName,
        lastName: loginDetails?.lastName,
        dateOfBirth: this.__getCorrectDob(
          { data: { dob: 'DATE_OF_BIRTH' } },
          loginDetails?.dateOfBirth,
        ),
        email: loginDetails?.email,
        number: loginDetails?.number,
        gender: loginDetails?.gender,
      });
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  handleNext = () => {
    if (!providerStorage.hasProvider()) {
      setSession('task', 'confirm_booking');
    }

    this.setState((state) => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleTextChange = (event) => {
    let change = {};
    change[event.target.name] = event.target.value;
    this.setState(change);
  };

  handleDataChange = (data) => {
    this.setState(data);
  };

  handleCodeChange = (callingCode, addressCountry, countryCode) => {
    this.setState({
      code: callingCode,
      codeCountry: countryCode,
      ...(addressCountry ? { addressCountry } : {}),
      number: '',
    });
  };

  handleCheckboxChange = (event) => {
    let change = {};
    change[event.target.name] = event.target.checked;
    this.setState(change);
  };

  handleGenderChange = (event) => {
    this.setState({
      gender: event.target.value,
    });
  };

  handlePhoneVerification = () => {
    const { loading } = this.state;

    if (loading) return;

    this.setState({
      loading: true,
    });
    const formattedNumber = phoneUtil.formatPhoneNumberForRegistration(
      this.state.number,
      this.state.code,
      this.state.codeCountry,
    );
    const number =
      formattedNumber.country +
      formattedNumber.area +
      formattedNumber.prefix +
      formattedNumber.line;
    registrationApi
      .verifyPhoneNumber(number)
      .then((response) => {
        const verificationStatus = response.data;
        if (verificationStatus.status === 3) {
          this.setState({
            invalidPhoneNumber: true,
          });
          return;
        } else if (verificationStatus.status === 0) {
          if (verificationStatus.current_carrier.network_type === 'mobile') {
            this.handleNext();
            return;
          } else {
            this.setState({
              notConnectedPhoneNumber: true,
            });
            return;
          }
        }
        analyticsEventLogger.log(AnalyticsEvent.REGISTER_PHONE_VERIFICATION_SUCCESS);
        this.handleNext();
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.REGISTER_PHONE_VERIFICATION_ERROR, {
          reason: error,
        });

        this.handleNext();
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  handleNextPersonalVerification = (_, submitWithPhoneVerification = true) => {
    if (submitWithPhoneVerification) {
      this.handlePhoneVerification();
    } else {
      this.handleNext();
    }
  };

  doSubmit = (formState) => {
    const { loading } = this.state;

    if (loading) return;

    this.setState({ loading: true });
    const formRequest = createAccountRequest(formState, this.state);

    registrationApi
      .register(formRequest)
      .then((value) => {
        authService
          .loginWithToken(value.data.token)
          .then(() => {
            analyticsEventLogger.log(AnalyticsEvent.REGISTER_SUCCESS);

            consumerApi
              .getPersonSummary()
              .then((result) => {
                addUserDataHeap({
                  id: result.data.id,
                  dateOfBirth: result.data.dob,
                  firstName: result.data.name.given,
                });
              })
              .catch((error) => {
                logger.error('Error getting person summary', error);
              });

            this.getNextFlow();
          })
          .catch((error) => {
            analyticsEventLogger.log(AnalyticsEvent.REGISTER_AUTH_ERROR, {
              reason: `${error}`,
            });

            notificationService.error(errorResolver.resolveAuthErrorDisplay(error));
            this.setState({
              registrationFailed: true,
              loading: false,
            });
          });
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.REGISTER_ERROR, {
          reason: error,
        });

        notificationService.error(errorResolver.resolveRegistrationErrorDisplay(error));
        this.setState({ registrationFailed: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  getNextFlow = () => {
    const { isAtLocation, appointmentId } = this.state;

    if (isAtLocation) {
      this.props.history.replace(QUINN_ROUTE);
    } else if (appointmentId) {
      this.props.history.replace(
        routeUtil.buildBookingRouteWithDraftAppointmentID(appointmentId, 'IN_PERSON'),
      );
    } else {
      this.props.history.replace(BOOKING_LIST_ROUTE);
    }
  };

  handleGoBackDialog = () => {
    this.setState({
      // activeStep: 0,
      notConnectedPhoneNumber: false,
      invalidPhoneNumber: false,
    });
  };

  handleContinueOnDialog = () => {
    this.setState((state, props) => ({
      activeStep: state.activeStep + 1,
      notConnectedPhoneNumber: false,
    }));
  };

  __getCorrectDob = (data, originalDob) => {
    let result;
    if (
      data?.dob === undefined ||
      data?.dob === '' ||
      (data?.dob === 'DATE_OF_BIRTH' && originalDob)
    ) {
      result = originalDob;
    } else {
      let extractedDate = data?.dob?.replace('-', '/');
      const dateRegex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;
      result = dateRegex.test(extractedDate) ? extractedDate : originalDob;
    }

    return result;
  };

  basicInformationBack = () => {
    this.setState((ps) => ({
      ...ps,
      activeStep: ps.activeStep - 1,
    }));
  };

  getStepContent = (step) => {
    const isLoading = this.state.loading || authService.isLoading();

    switch (step) {
      case 0:
        return (
          <BasicInformation
            {...this.state}
            handleBack={this.basicInformationBack}
            handleNext={this.handleNextPersonalVerification}
            onCodeChange={this.handleCodeChange}
            handleTextChange={this.handleTextChange}
            handleGenderChange={this.handleGenderChange}
          />
        );
      case 1:
        return (
          <Address
            {...this.state}
            isLoading={isLoading}
            handleBack={this.handleBack}
            doSubmit={(newState) => {
              this.setState(newState);
              this.handleNext();
            }}
            code={this.state.code}
            updateAddressChange={this.handleDataChange}
            additionalFields={[
              <FormControlLabel
                control={
                  <Checkbox
                    required
                    checked={this.state.acceptTerms}
                    onChange={this.handleCheckboxChange}
                    className={this.props.classes.checkBox}
                    name="acceptTerms"
                    color="primary"
                  />
                }
                label={
                  <React.Fragment>
                    <FormattedMessage
                      id="registration.user.consent"
                      defaultMessage="I acknowledge and consent to"
                    />
                    <br />
                    <FormattedMessage id="global.consent.link">
                      {(chunks) => (
                        <Link
                          rel="noopener noreferrer"
                          target="_blank"
                          href={chunks}
                          underline="hover"
                        >
                          <FormattedMessage
                            id="registration.user.tnc"
                            defaultMessage="The Terms of Use, Consent to Treatment, and Notice of Privacy Practices"
                          />
                        </Link>
                      )}
                    </FormattedMessage>
                  </React.Fragment>
                }
              />,
            ]}
          />
        );
      case 2:
        return (
          <EmergencyContact
            {...this.state}
            handleBack={this.handleBack}
            handleSubmit={(formState) => {
              this.doSubmit(formState);
            }}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  };

  render() {
    let { activeStep, invalidPhoneNumber, notConnectedPhoneNumber, email } = this.state;

    return (
      <>
        {this.getStepContent(activeStep)}
        <InvalidPhoneNumberModal open={invalidPhoneNumber} onGoBack={this.handleGoBackDialog} />
        <NotConnectedPhoneNumberModal
          open={notConnectedPhoneNumber}
          email={email}
          onContinue={this.handleContinueOnDialog}
          onGoBack={this.handleGoBackDialog}
        />
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(Registration));
