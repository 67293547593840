import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { IntlProvider } from 'react-intl';

import { Route, Switch, withRouter } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import withStyles from '@mui/styles/withStyles';

import './App.css';

import Login from '../Login';
import Secure from '../Secure';

import { FinalRoute, GuardedRoute, PrivateRoute } from '../../utils/protected.route';
import LinkHandler from '../Link';
import LocalNotifications from '../shared/Notifications';
import PostEncounter from '../Secure/VirtualClinic/PostEncounter';
import {
  INVALID_ROUTE,
  LINK_ROUTE,
  LOGIN_ROUTE,
  QUINN_TERMINATE_ROUTE,
  ROOT_ROUTE,
  VIRTUAL_CLINIC_POST_ENCOUNTER_ROUTE,
  KIOSK_ROUTE,
  WALKIN_ONLY,
  THANKYOU_ROUTE,
  PATIENT_REGISTRATION_ONLY_RESULT_ROUTE,
  EMPLOYER_OCC_ROUTE,
  PRE_AUTH_QUINN_ROUTE,
  PRE_AUTH_BOOKING_ROUTE,
  PRE_AUTH_URGENTCARE_ROUTING_ROUTE,
  OCCHEALTH_ROUTING_ROUTE,
  POST_BOOKING_ROUTE,
  APPOINTMENT_CHECKIN_CONFIRMATION,
  APPOINTMENT_CHECKIN_EXCEPTION,
  VIP_ROUTING_ROUTE,
  KIOSK_TEMPORARY,
  KIOSK_TEMPORARY_REMOVE,
} from '../../utils/route.name';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { isAfter, isBefore, isValid, parse, subYears } from 'date-fns';
import { messages } from '../../utils/intl/messages';
import Loading from '../shared/Loading';
import { globalBloc } from '../global.bloc';
import PostTerminate from '../Secure/Assistant/PostTerminate';
import { userInfoUtil } from '../../utils/user';
import { getThemeBasedOnTenant } from './themes';
import Invalid from '../Invalid';
import KioskAdmin from '../KioskAdmin';
import WalkinNotice from '../WalkinNotice';
import ThankYou from '../ThankYou';
import RegistrationOnlyComplete from '../RegistrationOnlyComplete';
import AssistantChat from '../../components/Secure/Assistant';
import { Booking } from '../Booking/Booking';
import { authService } from '../../utils/auth';
import Employer from '../BookingPaymentMethod/Employer';
import { uriStorage } from '../../utils/storage';
import { BottomNavigation, Typography } from '@mui/material';
import IdleTimer from 'react-idle-timer';
import { providerStorage } from '../../utils/provider.qs';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';
import { logger } from '../../utils/logging';
import PreAuthUrgentCareRouting from '../UrgentCareRouting/PreAuthUrgentCareRouting';
import OccHealthRouting from '../OccHealthRouting';
import RestartDialog from '../../shared-library/components/dialogs/RestartDialog';
import SessionExpiringDialog from '../../shared-library/components/dialogs/SessionExpiringDialog';
import PostBooking from '../Secure/PostBooking';
import { styled } from '@mui/styles';
import CheckinConfirmation from '../CheckinConfirmation';
import CheckinException from '../CheckinException';
import VipRouting from '../VipRouting';
import PreAuthChat from '../Secure/Assistant/PreAuthChat';
import KioskTemporary from '../KioskAdmin/KioskTemporary';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    width: '100%',
    maxHeight: '100%',
  },
};

const TIMEOUT = 60 * 15;
const KIOSK_TIMEOUT = 60 * 5;
const LEEWAY = 60;

const RestartButton = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.warning.main,
  textDecoration: 'underline',
  fontWeight: 'bold',
  textAlign: 'center',
  fontSize: '1.2rem',
}));

class App extends Component {
  globalStateSubsciption;

  constructor(props) {
    super(props);
    this.state = {
      initialising: true,
      countdownSeconds: LEEWAY,
      idle: false,
      open: false,
      logoutDialogOpen: false,
    };

    this.__watchGlobalState = this.__watchGlobalState.bind(this);
    this.idleTimer = null;
    this.countDownTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.onCountdown = this._countdown.bind(this);
    this.onCancelIdleTimeout = this._cancelIdleTimeout.bind(this);
    this.idleTimeout = providerStorage.isKiosk() ? KIOSK_TIMEOUT : TIMEOUT;
  }

  componentDidMount() {
    logger.debug('<App/> mounted');

    if (process.env.REACT_APP_HTTPS !== 'http') {
      window.location.protocol === 'http:' &&
        (window.location.href = window.location.href.replace(/^http:/, 'https:'));
    }

    //--> prevents hanging loading screen if page is reloaded in pre-auth state
    const url = window.location.href;
    if (url.includes('/pre-auth/')) {
      this.props.history.push(LOGIN_ROUTE);
    }

    this.globalStateSubsciption = globalBloc.subscribeToState(this.__watchGlobalState);
    this._isDateValid();
    this._isValidName();
  }

  componentWillUnmount() {
    logger.debug('<App/> unmounted');
    if (this.countDownTimer) {
      clearInterval(this.countDownTimer);
      this.countDownTimer = undefined;
    }
  }

  __watchGlobalState = (state) => {
    this.setState({
      ...state,
    });
  };

  _isDateValid = () => {
    ValidatorForm.addValidationRule('isDateValid', (value) => {
      const date = parse(value, 'MM/dd/yyyy', new Date());
      if (!isValid(date)) return false;

      const now = new Date();

      return isBefore(date, now) && isAfter(date, subYears(now, 130));
    });
  };

  _isValidName = () => {
    ValidatorForm.addValidationRule('isValidName', (value) => {
      return userInfoUtil.validName(value.trim());
    });
  };

  _onAction(e) {}

  _onActive(e) {}

  _onIdle(e) {
    if (!this.countDownTimer) {
      this.countDownTimer = setInterval(this.onCountdown, 1000);
    }
    this.setState({
      idle: true,
    });
    analyticsEventLogger.log(AnalyticsEvent.IDLE_SHOW, {});
  }

  _countdown = () => {
    const { countdownSeconds } = this.state;

    if (countdownSeconds < 0) {
      this.doLogout();
    } else {
      this.setState({
        countdownSeconds: countdownSeconds - 1,
      });
    }
  };

  _cancelIdleTimeout = () => {
    this.idleTimer.reset();
    clearInterval(this.countDownTimer);
    this.countDownTimer = undefined;
    this.setState({
      idle: false,
      countdownSeconds: LEEWAY,
    });
  };

  doLogout = () => {
    authService.logout().then(() => {
      uriStorage.clearPath();
      providerStorage.clearProvider();
      window.location = '/';
    });
  };

  cancelLogoutDialog = () => {
    this.setState({ logoutDialogOpen: false });
  };

  openLogoutDialog = () => {
    this.setState({ logoutDialogOpen: true });
  };

  handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        providerStorage.clearProvider();
        this.setState({ logoutDialogOpen: false });
        this.props.history.push('/');
      })
      .catch((error) => {
        console.error('Error signing out:', error);
        providerStorage.clearProvider();
        this.setState({ logoutDialogOpen: false });
        this.props.history.push('/');
      });
  };

  isPrimaryCareRegistrationOnly = () => {
    const url = window.location.href;
    // Extract the domain name
    const domain = new URL(url).hostname;
    return (
      process.env.REACT_APP_DH_PRIMARY_CARE_REGISTRATION_ONLY_DOMAIN &&
      process.env.REACT_APP_DH_PRIMARY_CARE_REGISTRATION_ONLY_DOMAIN.includes(domain)
    );
  };

  getAvailableRoutes = () => {
    const { orgSelected } = globalBloc.subject.value;
    const appointmentId = sessionStorage.getItem('appt');

    if (this.isPrimaryCareRegistrationOnly()) {
      sessionStorage.setItem('action', 'registration.only');
      sessionStorage.setItem('registrationType', 'PC');

      return (
        <Switch>
          <Route path={INVALID_ROUTE} component={Invalid} />
          <Route path={THANKYOU_ROUTE} component={ThankYou} />
          <Route path={LOGIN_ROUTE} component={Login} />
          <Route
            path={PATIENT_REGISTRATION_ONLY_RESULT_ROUTE}
            component={RegistrationOnlyComplete}
          />
          <PrivateRoute path={ROOT_ROUTE} component={Secure} />
        </Switch>
      );
    }

    return (
      <Switch>
        <GuardedRoute path={PRE_AUTH_QUINN_ROUTE} component={PreAuthChat} condition={orgSelected} />
        <GuardedRoute path={PRE_AUTH_BOOKING_ROUTE} component={Booking} condition={appointmentId} />
        <GuardedRoute
          path={PRE_AUTH_URGENTCARE_ROUTING_ROUTE}
          component={PreAuthUrgentCareRouting}
          condition={appointmentId}
        />

        <Route path={INVALID_ROUTE} component={Invalid} />
        <Route path={KIOSK_ROUTE} component={KioskAdmin} />
        <Route path={LINK_ROUTE} component={LinkHandler} />
        <Route path={LOGIN_ROUTE} component={Login} />
        <Route path={VIRTUAL_CLINIC_POST_ENCOUNTER_ROUTE} component={PostEncounter} />
        <Route path={QUINN_TERMINATE_ROUTE} component={PostTerminate} />
        <Route path={WALKIN_ONLY} component={WalkinNotice} />
        <Route path={OCCHEALTH_ROUTING_ROUTE} component={OccHealthRouting} />
        <Route path={PATIENT_REGISTRATION_ONLY_RESULT_ROUTE} component={RegistrationOnlyComplete} />
        <Route path={EMPLOYER_OCC_ROUTE} component={Employer} />

        <Route path={KIOSK_TEMPORARY} component={KioskTemporary} />
        <Route path={KIOSK_TEMPORARY_REMOVE} component={KioskTemporary} />

        <FinalRoute path={POST_BOOKING_ROUTE} component={PostBooking} condition={appointmentId} />
        <FinalRoute path={THANKYOU_ROUTE} component={ThankYou} condition={appointmentId} />
        <FinalRoute
          path={APPOINTMENT_CHECKIN_CONFIRMATION}
          component={CheckinConfirmation}
          condition={appointmentId}
        />
        <FinalRoute
          path={APPOINTMENT_CHECKIN_EXCEPTION}
          component={CheckinException}
          condition={true}
        />
        <FinalRoute path={VIP_ROUTING_ROUTE} component={VipRouting} condition={appointmentId} />

        <PrivateRoute path={ROOT_ROUTE} component={Secure} />
      </Switch>
    );
  };

  render() {
    const { classes } = this.props;
    const { initialising, idle, countdownSeconds, loading, logoutDialogOpen } = this.state;
    const { kioskRestart } = globalBloc.subject.value;

    if (initialising) {
      return (
        <>
          <Loading />
        </>
      );
    }

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={getThemeBasedOnTenant()}>
          <IntlProvider locale="en" messages={messages}>
            <div className={classes.root}>
              <CssBaseline />
              {this.getAvailableRoutes()}
              <IdleTimer
                ref={(ref) => {
                  this.idleTimer = ref;
                }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onAction={this.onAction}
                debounce={250}
                timeout={1000 * this.idleTimeout}
              />
              {!this.state.loading && providerStorage.isKiosk() && kioskRestart && (
                <BottomNavigation showLabels>
                  <RestartButton onClick={this.openLogoutDialog}>
                    Finished? Click here to complete and restart Kiosk session.
                  </RestartButton>
                </BottomNavigation>
              )}
              <SessionExpiringDialog
                idle={idle}
                onCancelIdleTimeout={this.onCancelIdleTimeout}
                countdownSeconds={countdownSeconds}
              />
              <RestartDialog
                logoutDialogOpen={logoutDialogOpen}
                handleLogout={this.handleLogout}
                cancelLogoutDialog={this.cancelLogoutDialog}
              />
            </div>
            <LocalNotifications />
          </IntlProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

export default withStyles(styles)(withRouter(App));
