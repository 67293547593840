import React, { useEffect, useState } from "react";
import {
  AppBar,
  Typography,
  Grid,
  Box,
  Button,
  CircularProgress,
  Divider,
} from "@mui/material";
import logo from "../../../../assets/logo.svg";
import { useMediaQueries } from "../../../../utils/hooks/useMediaQueries";
import QRCode from "react-qr-code";
import { providerStorage } from "../../../../utils/provider.qs";
import { administrationApi } from "../../../../utils/services/administration.api";
import { FormattedMessage } from "react-intl";
import PreDialog from "../../../shared/Dialog/PreDialog";

const orange = "#E50B14 !important";

const font = "Montserrat, sans-serif !important";

const baseFontColor = "#414042";

const gradientHeader = "linear-gradient(180deg, #eaf2f3, #abcccf)";

const gradientBG = "linear-gradient(180deg, #96bfc3, #2d8088)";

//==> FIRST SCREEN CHILD COMPONENT

export const CheckInOptions = ({ getInLineOnClick, checkInOnClick, firstWalkinSlot }) => {
  const [qrValue, setQrValue] = useState("");
  const [showPreDialog, setShowPreDialog] = useState(false);
  const currentProvider = providerStorage.getCurrentProvider();
  const mediaQuery = useMediaQueries();

  useEffect(() => {
    setShowPreDialog(true);
    administrationApi
      .getLocationToken(currentProvider)
      .then((response) => {
        const value = `${window.location.protocol}//${window.location.host}/link/qr/${currentProvider}/${response.data}`;
        localStorage.setItem("locationToken", response.data);
        setQrValue(value);
      })
      .catch(() => {
        const prevLocationToken = localStorage.getItem("locationToken") || ""; // if no prev token, use empty string
        const value = `${window.location.protocol}//${window.location.host}/link/qr/${currentProvider}/${prevLocationToken}`;
        setQrValue(value);
      });
  }, []);

  const handleClosePreDialog = () => {
    setShowPreDialog(false);
  };

  return (
    <Box sx={{ width: "100%", minHeight: "100vh" }}>
      <AppBar
        position='static'
        sx={{
          background: gradientHeader,
          boxShadow: "none",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          position: "relative",
        }}
      >
        <img
          src={logo}
          alt='logo-white'
          style={{
            width: mediaQuery.isSm ? "200px" : "300px",
            padding: "2rem",
          }}
        />

      </AppBar>
      <Box mt={3} p={3}>
        <Grid container direction='column' spacing={mediaQuery.isMd ? 1 : 3}>
          <Grid xs={12} item>
            <Typography
              align='center'
              sx={{
                fontSize: "2rem !important",
                fontWeight: "500",
                fontFamily: font,
                color: baseFontColor,
              }}
            >
              Please choose from the options below for your preferred check-in method.
            </Typography>
          </Grid>

          <Grid container display='flex' flexDirection='row' justifyContent='center' flexWrap='wrap' item spacing={2}>
            <Grid
              item
              gap={3}
              xs={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "3rem 3rem !important",
              }}
            >
              <Typography
              variant="h3"
                sx={{
                  color: orange,
                  // fontSize: "4rem !important",
                  fontWeight: "600",
                }}
                align='center'
              >
                Mobile Phone
              </Typography>
              <Typography
                align='center'
                sx={{
                  fontSize: "2rem !important",
                  fontWeight: "500",
                  fontFamily: font,
                  color: baseFontColor,
                }}
              >
                Scan the QR code with your mobile device to complete check-in on your phone.
              </Typography>
              {qrValue ? (
                <QRCode size={200} value={qrValue} />
              ) : (
                <Box
                  sx={{
                    paddingTop: "75px",
                  }}
                >
                  <CircularProgress
                    sx={{
                      "& .MuiCircularProgress-circle": {
                        color: `${orange}`,
                      },
                    }}
                    size={50}
                  />
                </Box>
              )}
            </Grid>
            <Grid
              item
              gap={3}
              xs={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "3rem 3rem !important",
              }}
            >
              <Typography
              variant="h3"
                sx={{
                  color: orange,
                  // fontSize: "4rem !important",
                  fontWeight: "600",
                }}
                align='center'
              >
                Continue on Kiosk
              </Typography>
              <Typography
                align='center'
                sx={{
                  fontSize: "2rem !important",
                  fontWeight: "500",
                  fontFamily: font,
                  color: baseFontColor,
                }}
              >
                Already Reserved Your Place Online? CLICK HERE to CHECK IN.
              </Typography>
              <Button
                onClick={checkInOnClick}
                variant='contained'
                sx={{
                  padding: mediaQuery.isMd ? null : ".61111em 1.22222em !important",
                  fontSize: mediaQuery.isMd ? "2rem" : "3rem",
                  backgroundColor: orange,
                  borderRadius: "20px !important",
                  // boxShadow: '0px 2px 10px 4px rgba(0, 0, 0, 0.25) !important',
                  fontFamily: font,
                }}
              >
                  CHECK IN
              </Button>
              <Divider sx={{ width: "100%" }} />
              <Typography
                align='center'
                sx={{
                  fontSize: "2rem !important",
                  fontWeight: "500",
                  fontFamily: font,
                  color: baseFontColor,
                }}
              >
                Walk-In Patients CLICK BELOW to GET IN LINE
              </Typography>
              <Button
                onClick={getInLineOnClick}
                disabled={!firstWalkinSlot ?? firstWalkinSlot?.slots.length === 0}
                variant='outlined'
                sx={{
                  padding: mediaQuery.isMd ? null : ".61111em 1.22222em !important",
                  fontSize: mediaQuery.isMd ? "2rem" : "3rem",
                  borderRadius: "20px !important",
                  fontFamily: font,
                }}
              >
                  GET IN LINE
              </Button>
              {firstWalkinSlot && firstWalkinSlot?.slots.length > 0 && <FormattedMessage
                id={`interaction.appointment.walkin${
                  firstWalkinSlot?.isTomorrow ? ".nextday" : ""
                }.confirmation.message3`}
                defaultMessage={`Next available time ${
                  firstWalkinSlot?.isTomorrow ? "tomorrow" : "today"
                }: `}
              >
                {txt =>
                  <Typography>
                    {txt}
                    &nbsp;
                    <span style={{color:"green" }}>
                    {firstWalkinSlot?.slots[0].display}
                  </span>
                  </Typography>
                }
              </FormattedMessage>}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <PreDialog showPreDialog={showPreDialog} handleClosePreDialog={handleClosePreDialog} />
    </Box>
  );
};

//==> SECOND SCREEN CHILD COMPONENT

const LetsGetStarted = ({ handleNextStep }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    setTimeout(() => {
      handleNextStep();
      setLoading(false);
    }, 1000);
  };

  return (
    <>
      <Grid
        container
        sx={{
          width: "100vw",
          height: "100vh",
          background: gradientBG,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Typography
          variant='h1'
          color='white'
          align='center'
          sx={{
            fontWeight: "600",
            fontSize: "8em !important",
            margin: "2rem",
            fontFamily: font,
            width: "80%",
          }}
        >
          Let's get you checked-in
        </Typography>
        <Button
          onClick={handleClick}
          disabled={loading}
          variant='contained'
          sx={{
            margin: "2rem",
            padding: ".61111em 1.22222em !important",
            fontSize: "3rem",
            backgroundColor: orange,
            borderRadius: "20px !important",
            boxShadow: "0px 2px 10px 4px rgba(0, 0, 0, 0.25) !important",
            fontFamily: font,
          }}
        >
          {loading ? <CircularProgress color='inherit' size='3rem' /> : "GET STARTED"}
        </Button>
      </Grid>
    </>
  );
};

//==> PARENT COMPONENT

const KioskWelcome = ({ getInLineOnClick, checkInOnClick, firstWalkinSlot }) => {
  const [step, setStep] = useState(0);

  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  return (
    <>
      {step === 0 && <LetsGetStarted handleNextStep={handleNextStep} />}
      {step === 1 &&
        <CheckInOptions firstWalkinSlot={firstWalkinSlot} getInLineOnClick={getInLineOnClick}
                        checkInOnClick={checkInOnClick} />}
    </>
  );
};

export default KioskWelcome;
