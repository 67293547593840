class ServiceUtil {
  friendlyName(service) {
    return new Promise((resolve, reject) => {
      setTimeout(function () {
        switch (service) {
          case 'ANY-UC':
          case 'INP-UC':
          case 'INW-UC':
            resolve('Urgent care visit');
            break;
          case 'ANY-OCC':
            resolve('Occupational medicine visit');
            break;
          default:
            if (service.includes('OCC')) {
              resolve('Occ Med');
            } else if (service.includes('VV') || service.includes('TL-')) {
              resolve('Virtual Visit');
            } else {
              resolve('Urgent care');
            }
        }
      }, 250);
    });
  }
  friendlyNameSync(service) {
    if (service === undefined) {
      return 'Urgent care visit';
    }

    if (typeof service !== 'string') {
      service = service.code;
    }

    switch (service) {
      case 'ANY-UC':
      case 'INP-UC':
      case 'INW-UC':
        return 'Urgent care visit';
      case 'ANY-OCC':
        return 'Occupational medicine visit';
      default:
        if (service.includes('TT-UC')) {
          return 'Travel Test';
        }
        if (service.includes('OCC')) {
          return 'Occ Med';
        } else if (service.includes('VV') || service.includes('TL-')) {
          return 'Virtual Visit';
        } else {
          return 'Urgent care';
        }
    }
  }

  extractCodeFromAppointment = (appointment) => appointment?.service;

  determinCheckinProcess = (appointment) => {
    const code = this.extractCodeFromAppointment(appointment);

    if (code) {
      if (code.includes('-OCC')) {
        return 'occhealth';
      }
      if (code.includes('TT-UC')) {
        return 'selfpay';
      }
      if (code.includes('DP-UC')) {
        return 'subscription';
      }
      if (code.includes('-UC')) {
        return 'standard';
      }
    }

    return 'manual';
  };

  isGetInLine = (service) => service === 'INW-UC';
}

export const serviceUtil = new ServiceUtil();

export const DEFAULT_SERVICE_CODE = 'INP-UC';
export const WALK_IN_SERVICE_CODE = 'INW-UC';
export const ANY_SERVICE_CODE = 'ANY-UC';
