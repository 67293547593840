import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import PageContainer from '../../common/PageContainer';
import Typography from '@mui/material/Typography';
import { authService } from '../../../utils/auth';
import { checkSession, setSession, uriStorage } from '../../../utils/storage';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { addMinutes, compareAsc, isAfter, parse } from 'date-fns';
import { ROOT_ROUTE, routeUtil, USER_INFORMATION_ROUTE } from '../../../utils/route.name';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { providerStorage } from '../../../utils/provider.qs';
import { appointmentApi } from '../../../utils/services/appointments.api';
import { dateUtil } from '../../../utils/date';
import { notificationService } from '../../../utils/notification';
import { ErrorMessage } from '../../../utils/error.resolver';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import DecodedButton, { DecodedSecondaryButton } from '../../common/DecodedButton';
import {
  DefaultDisconnectedPageFooter,
  ScrollableQuinnContainer,
} from '../../common/ScrollableContainer';
import { AppointmentCard } from '../../shared/AppointmentCard';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { logger } from '../../../utils/logging';
import { TextAlternate } from '../../shared/Typography';
import { globalBloc } from '../../global.bloc';
import { serviceUtil } from '../../../utils/service';
import { appointmentUtil } from '../../../utils/appointment';
import { AppointmentItemsContainer, styles } from './booking-list-styles';
import { preventGoingBack } from '../../../utils/preventGoingBack';
import { getInLineUtil } from '../../../utils/getInLineUtils';

class BookingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,

      appointments: [],
      dialogOpen: false,

      cancelReasonList: [],
      selectedReasonId: '',
      selectedReasonText: '',
    };

    analyticsEventLogger.log(AnalyticsEvent.BOOKING_LIST, {});
  }

  componentDidMount() {
    logger.debug('<BookingList/> mounted');

    sessionStorage.removeItem('task');
    sessionStorage.removeItem('appt');
    sessionStorage.removeItem('manage-appointments');

    // globalBloc.updateGlobalBloc({
    //   orgSelected: false,
    //   getInLine: false,
    //   walkinGetInLine: false,
    // });

    preventGoingBack();

    authService.getIdTokenResult().then((result) => {
      let isFullLogin = result?.claims?.scope?.some((scope) => scope === '*');
      this.setState({ isFullLogin: isFullLogin });
      this._currentLocations(isFullLogin);
    });
  }

  _currentLocations = (isFullLogin) => {
    appointmentApi.getAvailableProviders().then(
      (response) => {
        let providerLookup = {};
        response.data.items.forEach((_item) => (providerLookup[_item.id] = _item));
        this.setState({ providerLookup });
        this._getCurrentAppointment(isFullLogin);
      },
      (reason) => {
        notificationService.httpError(reason);
      },
    );
  };

  _getCurrentAppointment = (isFullLogin) => {
    const compareDate = dateUtil.addhours(new Date(), -8);

    appointmentApi
      .getAllFutureAppointments()
      .then((response) => this._filterUpcomingAppointments(response.items, compareDate))
      .then((filteredAppointments) => this._addQueueStatusToAppointments(filteredAppointments))
      .then((appointmentsWithQueueStatus) =>
        this._sortAppointmentsByDate(appointmentsWithQueueStatus),
      )
      .then((sortedAppointments) =>
        this._handleAppointmentRedirection(sortedAppointments, isFullLogin),
      )
      .catch((error) => this._handleAppointmentError(error));
  };

  _filterUpcomingAppointments = (appointments, compareDate) => {
    const filteredAppointments = appointments.filter((appointment) => {
      const startTime = dateUtil.parseDate(appointment.slot.start);
      const endTime = addMinutes(startTime, appointment.slot.duration);
      return isAfter(endTime, compareDate);
    });
    return Promise.resolve(filteredAppointments);
  };

  _addQueueStatusToAppointments = (appointments) => {
    const appointmentPromises = appointments.map((appointment) => {
      if (serviceUtil.isGetInLine(appointment.service.code)) {
        return appointmentApi
          .getAppointmentQueueStatus(appointment.id)
          .then((res) => {
            appointmentUtil.addGetInLineInformation(appointment, appointment.slot.start, res.data);
            return appointment;
          })
          .catch((error) => {
            appointment.originalStartTime = appointment.slot.start;
            return Promise.resolve(appointment);
          });
      } else {
        appointment.originalStartTime = appointment.slot.start;
        return Promise.resolve(appointment);
      }
    });
    return Promise.all(appointmentPromises);
  };

  _sortAppointmentsByDate = (appointments) => {
    const sortedAppointments = appointments.sort((appointment1, appointment2) => {
      const date1 = dateUtil.parseDate(appointment1.slot.start);
      const date2 = dateUtil.parseDate(appointment2.slot.start);
      return compareAsc(date1, date2);
    });
    return Promise.resolve(sortedAppointments);
  };

  _handleAppointmentRedirection = (appointmentList, isFullLogin) => {
    const currentValue = sessionStorage.getItem('booking-redirect');

    if (
      isFullLogin &&
      appointmentList.length === 0 &&
      currentValue !== 'done' &&
      providerStorage.hasProvider() &&
      checkSession('checkin', 'true')
    ) {
      this.setState({ loading: false });
      sessionStorage.setItem('booking-redirect', 'done');
      this.props.history.replace(routeUtil.buildAppointmentCheckinException('unknown'));
    } else if (isFullLogin && appointmentList.length === 1 && currentValue !== 'done') {
      sessionStorage.setItem('booking-redirect', 'done');
      const route = routeUtil.buildBookingStatusRouteWithAppointmentID(appointmentList[0].id);
      this.props.history.push(route);
    } else {
      this.setState({
        appointments: appointmentList,
        loading: false,
      });
    }
    return Promise.resolve();
  };

  _handleAppointmentError = (error) => {
    notificationService.error(
      'Unable to find your existing reservations' + ErrorMessage.CALL_SUPPORT,
    );
    console.error(error);

    this.setState({
      appointments: [],
      loading: false,
    });
  };

  _handleLogout = () => {
    authService.logout().then(() => {
      uriStorage.clearPath();
      providerStorage.clearProvider();
      window.location = ROOT_ROUTE;
    });
  };

  _handleNewReservation = () => {
    const { appointments } = this.state;

    setSession('task', 'new-reservation');

    if (getInLineUtil.isGetInLineQRLocation()) {
      globalBloc.updateGlobalBloc({ walkinGetInLine: true });
    }

    if (appointments.length === 0) {
      this.props.history.push(USER_INFORMATION_ROUTE);
    } else {
      this.setState({ dialogOpen: true });
    }
  };

  _renderConfirmationDialog = () => {
    const { history } = this.props;
    const { dialogOpen } = this.state;

    return (
      <Dialog
        maxWidth={'md'}
        open={dialogOpen}
        onClose={() => this.setState({ dialogOpen: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography variant="body2" component={'div'}>
            <FormattedMarkdown
              id={'booking.list.reserve.dialog.content'}
              defaultMessage={'Are you sure you want to make a new reservation?'}
            />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant={'contained'}
            color="secondary"
            onClick={() => this.setState({ dialogOpen: false })}
          >
            Cancel
          </Button>
          <Button
            variant={'contained'}
            color="primary"
            onClick={() => history.push(USER_INFORMATION_ROUTE)}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  getOpenButtonTextBasedOnAppointmentType = (appointment, appointmentType) => {
    if (appointmentType !== 'Virtual') {
      return 'Open';
    }
    const AppointmentDate = new Date(appointment.slot.start);
    const currentDate = new Date();
    if (currentDate < AppointmentDate) {
      return 'Check Device';
    }
    return 'Join Meeting';
  };

  _appointmentCardControls = (appointment) => {
    const { history } = this.props;

    let appointmentType = 'Virtual';
    let nextRoute = routeUtil.buildVirtualWaitingRoomRouteWithAppointmentID(appointment.id);
    if (appointment.type === 'IN_PERSON_WALK_IN' || appointment.type === 'IN_PERSON') {
      appointmentType = 'In-person';
      nextRoute = routeUtil.buildBookingStatusRouteWithAppointmentID(appointment.id);
    }

    if (appointment.status !== 'REQUESTED') {
      return (
        <Grid xs={12} item>
          <DecodedButton
            sx={{
              fontSize: '1.3em',
              minHeight: '30px',
            }}
            size="small"
            onClick={() => history.push(nextRoute, { showHandleBack: true })}
          >
            {this.getOpenButtonTextBasedOnAppointmentType(appointment, appointmentType)}
          </DecodedButton>
        </Grid>
      );
    } else {
      return (
        <Grid xs={12} item>
          <DecodedButton
            size="small"
            sx={{
              fontSize: '1.3em',
              minHeight: '30px',
            }}
            disabled={true}
          >
            Scheduling...
          </DecodedButton>
        </Grid>
      );
    }
  };

  _renderAppointmentItem = (appointment, organisation) => {
    const parsedTime = parse(appointment.slot.start, "yyyy-MM-dd'T'HH:mm:ssX", new Date());

    return (
      <AppointmentCard
        appointment={appointment}
        organisation={organisation}
        visitTime={dateUtil.fullFriendlyOutputExcludeYear(parsedTime)}
        controls={this._appointmentCardControls(appointment)}
      />
    );
  };

  render() {
    const { classes } = this.props;
    const { loading, appointments, providerLookup, isFullLogin } = this.state;

    const assistantMessage = loading
      ? {
          id: 'booking.list.bubble.loading',
          text: 'We are looking for your reservations.',
        }
      : {
          id: 'booking.list.bubble.success',
          text: 'Please check your upcoming reservations below.',
        };

    return (
      <PageContainer loading={loading}>
        <ScrollableQuinnContainer
          size={'large'}
          messageId={assistantMessage.id}
          message={assistantMessage.text}
        >
          <div className={classes.root}>
            {loading && (
              <div className={classes.progressBox}>
                <CircularProgress classes={{ circle: classes.progress }} />
              </div>
            )}

            {!loading && appointments.length !== 0 && (
              <AppointmentItemsContainer
                children={appointments.map((appointment) => (
                  <Grid key={appointment.id} item sm={12} md={6}>
                    {this._renderAppointmentItem(
                      appointment,
                      providerLookup[appointment.organisationId],
                    )}
                  </Grid>
                ))}
              />
            )}

            {!loading && appointments.length === 0 && (
              <>
                <TextAlternate sm primary>
                  <FormattedMarkdown
                    id={'booking.list.empty.message'}
                    defaultMessage={"You don't have any existing reservations."}
                  />
                </TextAlternate>

                <TextAlternate error>
                  <FormattedMarkdown
                    id={'booking.list.empty.message'}
                    defaultMessage={
                      'Please contact the clinic directly for reservations made outside of our automated reservation system.'
                    }
                  />
                </TextAlternate>
              </>
            )}
          </div>
        </ScrollableQuinnContainer>

        <DefaultDisconnectedPageFooter justifyContent="flex-start">
          <Grid item xs={12} sm={6}>
            <DecodedSecondaryButton
              disabled={loading}
              loading={loading}
              onClick={this._handleLogout}
            >
              Log Out
            </DecodedSecondaryButton>
          </Grid>
          {isFullLogin && (
            <Grid item xs={12} sm={6}>
              <DecodedButton
                disabled={loading}
                loading={loading}
                onClick={this._handleNewReservation}
              >
                New Reservation
              </DecodedButton>
            </Grid>
          )}
        </DefaultDisconnectedPageFooter>

        {this._renderConfirmationDialog()}
      </PageContainer>
    );
  }
}

export default withStyles(styles)(BookingList);
