import React from "react";
import { TextField, styled } from "@mui/material";

const StyledTextField = styled(TextField)(({ pincodefont }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
  },
  "& .MuiOutlinedInput-input": {
    textAlign: pincodefont ? "center" : null,
    fontSize: pincodefont ? "3.5rem" : "1.5rem",
  },
}));

const Container = styled("div")({
  position: "relative",
  transition: "all 0.3s",
  marginTop: "25px",
});

const DecodedTextField = (props) => {
  const { label, type, error, helperText, pinCodeFont = false } = props;

  const labelContent = Boolean(error) ? helperText : label;

  return (
    <Container>
      <StyledTextField
        {...props}
        autoComplete="off"
        type={type}
        pincodefont={pinCodeFont}
        fullWidth
        required
        variant="outlined"
        error={Boolean(error)}
        label={labelContent}
        helperText={null}
        InputLabelProps={{
          ...props.InputLabelProps,
          shrink: true,
        }}
      />
    </Container>
  );
};

export default DecodedTextField;
