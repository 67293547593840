import React from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Button,
  } from '@mui/material';

const SessionExpiringDialog = (props) => {

    const { idle, countdownSeconds, onCancelIdleTimeout } = props;
    
    return(
        <Dialog
          open={idle}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Session Expiring'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You have {countdownSeconds > 0 ? countdownSeconds : 0} second(s) to extend your
              session.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={countdownSeconds <= 0}
              onClick={onCancelIdleTimeout}
              variant="contained"
              color="primary"
              autoFocus
            >
              Extend
            </Button>
          </DialogActions>
        </Dialog>
    )
}

export default SessionExpiringDialog