import * as rxjs from 'rxjs';
import { appointmentApi } from '../../utils/services/appointments.api';
import { compareAsc, format, parse } from 'date-fns';
import { DATE_FORMAT } from '../../utils/user';
import { notificationService } from '../../utils/notification';
import { dateUtil } from '../../utils/date';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';
import { providerStorage } from '../../utils/provider.qs';
import { LOGIN_ROUTE, ROOT_ROUTE, routeUtil, THANKYOU_ROUTE } from '../../utils/route.name';
import { appointmentIntervalUtil } from '../../utils/appointment';
import { authService } from '../../utils/auth';
import { setSession } from '../../utils/storage';
import { globalBloc } from '../global.bloc';
import { remoteSteps, walkinSteps } from './utils/stepOrchestrator';
import { logger } from '../../utils/logging';
import { getInLineUtil } from '../../utils/getInLineUtils';

export class BookingBloc {
  constructor(appointmentId, appointmentType) {
    const { booking, getInLine, currentBookingStep } = globalBloc.subject.value;
    const { firstAvailableCapacity, firstAvailableDate, organisation = { id: '' } } = booking;
    const { id } = organisation;

    this.subject = new rxjs.BehaviorSubject({
      initialising: true,
      loadingData: true,
      isWalkin: providerStorage.isWalkin(),
      isAtLocation: providerStorage.hasProvider(),
      acuity: sessionStorage.getItem('acuity' + appointmentId) || 'non-urgent',
      appointmentId: appointmentId,
      appointment: undefined,
      appointmentType: appointmentType,
      booking: {
        selectedOrg: id,
        selectedOrgCapacity: firstAvailableCapacity,
        selectedDate: firstAvailableDate,
        ...booking,
      },
      calendarSummary: undefined,
      availableOrganisations: [],
      systemProperties: [],
      token: authService.getToken() || '',
      getInLine: getInLine,
      intervals: [],
      currentBookingStep: currentBookingStep,
    });

    this.events = new rxjs.Subject();

    this.__initialise(appointmentId);
  }

  __updateSubject = (value) => {
    const newState = {
      ...this.subject.value,
      ...value,
    };
    this.subject.next(newState);
  };

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  __initialise = (appointmentId) => {
    const { orgSelected, getInLine } = globalBloc.subject.value;
    const { isAtLocation, currentBookingStep } = this.subject.value;

    // to reroute if no appointmentId in params
    if (!appointmentId) {
      logger.error('No appointmentId in params');
      this.navigateTo(ROOT_ROUTE);
    }

    this.__updateSubject({ loadingData: true });

    appointmentApi
      .getAppointmentStatus(appointmentId)
      .then((value) => {
        const appointment = value.data;

        this.__updateSubject({ appointment: appointment });
        this.getAvailableOrganisations(appointment?.service, isAtLocation);

        if (orgSelected && currentBookingStep === 0) {
          return this.initialiseOrgSelected(getInLine, currentBookingStep);
        }

        if (appointment?.type === 'IN_PERSON_WALK_IN' && appointment?.status === 'RESERVED') {
          const route = routeUtil.buildBookingIdentityDocument(appointment.id);
          this.navigateTo(route);
        }

        this.__updateSubject({
          loadingData: false,
          initialising: false,
        });
      })
      .catch((error) => {
        notificationService.error(
          'Unable to get reservation status. Please try again and if the problem persists call the clinic.',
        );
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_ORGANISATION_LOAD_ERROR, {
          status: 'error',
          message: `${error}`,
        });

        this.__updateSubject({
          loadingData: false,
          initialising: false,
        });
      });
  };

  initialiseOrgSelected = (getInLine, currentBookingStep) => {
    this.__saveAppointmentProgress('org-selector')
      .then(() => {
        globalBloc.updateCurrentBookingStep(1);

        if (getInLine) {
          return this.initialiseGetInLine(currentBookingStep, getInLine);
        }

        this.__updateSubject({
          loadingData: false,
          initialising: false,
        });
      })
      .catch((error) => {
        if (currentBookingStep > 0) {
          globalBloc.updateCurrentBookingStep(0);
        }

        notificationService.error(
          'Unable to save reservation location. Please try again and if the problem persists call the clinic',
        );

        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_ORGANISATION_LOAD_ERROR, {
          status: 'error',
          message: `${error}`,
        });

        if (getInLine) {
          sessionStorage.removeItem('action');
          this.events.next({
            type: BookingBlocEvent.NAVIGATE_TO,
            data: { url: LOGIN_ROUTE },
          });
        }

        this.__updateSubject({
          loadingData: false,
          initialising: false,
        });
      });
  };

  initialiseGetInLine = (currentBookingStep, getInLine) => {
    this.__saveAppointmentProgress('time-selector')
      .then(() => {
        globalBloc.updateCurrentBookingStep(2);

        setSession('action', 'get_in_line_booking');

        if (!authService.isLoggedIn()) {
          logger.info('User not logged in, redirecting to login');
          this.navigateTo(LOGIN_ROUTE);
        }

        this.__updateSubject({
          loadingData: false,
          initialising: false,
        });
      })
      .catch((error) => {
        globalBloc.updateGlobalBloc({ loading: false });

        globalBloc.updateCurrentBookingStep(0);

        notificationService.error(
          'Unable to save reservation time slot. Please try again and if the problem persists call the clinic.',
        );

        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_ORGANISATION_LOAD_ERROR, {
          status: 'error',
          message: `${error}`,
        });

        this.__updateSubject({
          loadingData: false,
          initialising: false,
        });
      });
  };

  getAvailableOrganisations = (service, isAtLocation) => {
    appointmentApi
      .getAvailableOrganisations([service])
      .then((response) => {
        const getInLineLocations = getInLineUtil.enabledLocations();

        const organisations = response.data.items.map((org) => ({
          ...org,
          getInLineEnabled: getInLineLocations.includes(org.id),
        }));

        this.__updateSubject({
          loadingData: false,
          availableOrganisations: organisations.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
          ),
          steps: isAtLocation ? walkinSteps(this) : remoteSteps(this),
        });

        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_ORGANISATION_LOADED, {
          locations: `${organisations.length}`,
        });
      })
      .catch((reason) => {
        notificationService.error(
          'Error loading available organisations for appointment type. Please refresh. If the problem persists please contact the clinic.',
        );
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_ORGANISATION_LOADED, {
          status: 'error',
          message: `${reason}`,
        });
      });
  };

  navigateTo = (url) => {
    this.events.next({
      type: BookingBlocEvent.NAVIGATE_TO,
      data: {
        url: url,
      },
    });
  };

  clearWalkinOnly = () => {
    let { booking } = this.subject.value;
    booking.selectedSlot = '';
    this.__updateSubject({ walkinOnly: false, booking: booking });
  };

  setWalkinOnly = () => {
    let { booking } = this.subject.value;
    booking.selectedSlot = 'walkin';
    this.__updateSubject({ walkinOnly: true, booking: booking });
  };

  _constructScheduleData = (intervalData, selectedDate) =>
    appointmentIntervalUtil.constructScheduleData(intervalData, selectedDate);

  loadServiceSchedule = () => {
    const { appointment, booking, isWalkin } = this.subject.value;

    this.__updateSubject({
      loadingData: true,
      calendarSummary: undefined,
    });

    const now = new Date();
    const lastPeriod = new Date(new Date().setDate(now.getDate() + 2));

    let start = format(now, DATE_FORMAT);
    let end = format(lastPeriod, DATE_FORMAT);

    return appointmentApi
      .getServiceScheduleSummary(
        start,
        end,
        appointment.service,
        booking.selectedOrg,
        undefined,
        !isWalkin,
      )
      .then(
        (value) => {
          this.__updateSubject({
            calendarSummary: value.data,
            loadingData: false,
          });

          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_DATE_LOADED, {});
        },
        (reason) => {
          notificationService.error(
            'Error loading available times for appointment type. Please refresh. If the problem persists please contact the clinic.',
          );
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_DATE_LOADED, {
            status: 'error',
            message: `${reason}`,
          });
          this.__updateSubject({
            loadingData: false,
          });
        },
      );
  };

  setSelectedDate = (date) => {
    const { booking } = this.subject.value;

    let newBooking = { ...booking };
    newBooking.selectedDate = date;
    newBooking.availability = undefined;
    newBooking.reminderTime = undefined;

    this.__updateSubject({
      booking: newBooking,
    });
  };

  isTomorrow = () => {
    const { booking } = this.subject.value;
    const now = new Date();

    //==> Check if selectedDate is a Date object
    const date =
      booking.selectedDate instanceof Date ? booking.selectedDate : new Date(booking.selectedDate);

    return now.getDate() + 1 === date?.getDate();
  };

  bookingSelectedDate = () => {
    const { booking } = this.subject.value;
    return booking.selectedDate;
  };

  setReminderTime = (interval) => {
    const { booking } = this.subject.value;

    let newBooking = { ...booking };
    newBooking.reminderTime = interval;

    this.__updateSubject({
      booking: newBooking,
    });
  };

  bookingIntervals = (booking, walkinOnly) => {
    if (booking?.availability) {
      let intervals = [];

      const start = new Date(booking.selectedDate);
      start.setHours(0, 0, 0, 0);
      const end = new Date(booking.selectedDate);
      end.setHours(23, 59, 59, 999);

      // const now = dateUtil.addhours(new Date(), -1);
      const now = new Date();
      const cutoff = dateUtil.addhours(new Date(), -1);
      cutoff.setMinutes(59, 59);

      if (booking.availability.results.length > 0) {
        booking.availability.results[0].intervals.forEach((_interval) => {
          _interval.renderDate = dateUtil.parseDate(_interval.start);
          if (
            cutoff.getTime() < _interval.renderDate.getTime() &&
            dateUtil.encloses(_interval.renderDate, start, end) &&
            _interval.availableSlots &&
            _interval.availableSlots.length > 0
          ) {
            if (_interval.renderDate.getTime() < now) {
              _interval.availableSlots = _interval.availableSlots.filter(
                (_slot) => _slot.start.split(':')[1] > now.getMinutes(),
              );
              if (_interval.availableSlots.length > 0) {
                intervals.push(_interval);
              }
            } else {
              intervals.push(_interval);
            }
          }
        });

        intervals.sort((slot1, slot2) => {
          return compareAsc(slot1.renderDate, slot2.renderDate);
        });
      }
      analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_LOADED, {
        intervals: intervals.length,
      });
      if (intervals.length > 0) {
        if (walkinOnly) {
          this.clearWalkinOnly();
        }
      } else {
        this.setWalkinOnly();
      }
      this.__updateSubject({
        intervals: intervals,
      });
    } else {
      this.setWalkinOnly();
      analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_LOADED, {
        intervals: 0,
      });
    }
  };

  loadSelectedDayAvailability = () => {
    const { appointment, booking, isWalkin, walkinOnly } = this.subject.value;
    const { selectedDate, selectedOrg, doctor } = booking;

    this.clearWalkinOnly();

    this.__updateSubject({
      loadingData: true,
    });

    return appointmentApi
      .getAvailableAppointmentSchedule(
        selectedDate,
        selectedOrg || appointment.provider,
        appointment.service,
        doctor,
        !isWalkin,
      )
      .then((value) => {
        let newBooking = {
          ...booking,
          availability: value.data,
          reminderTimer: undefined,
        };

        this.__updateSubject({
          booking: newBooking,
          schedulingIntervals: this._constructScheduleData(
            value.data.results[0].intervals,
            selectedDate,
          ),
        });

        this.bookingIntervals(newBooking, walkinOnly);
      })
      .finally(() => {
        this.__updateSubject({
          loadingData: false,
        });
      });
  };

  loadAvailability = (start, end, organisationId, service, doctor, isWalkin) => {
    return appointmentIntervalUtil.loadAvailability(
      appointmentApi,
      start,
      end,
      organisationId,
      service,
      doctor,
      isWalkin,
    );
  };

  getInLineSelection = (org, slot) => {
    const { booking } = this.subject.value;

    let newBooking = {
      ...booking,
      selectedSlot: slot,
      reminderTime: undefined,
      selectedOrg: org,
      // selectedOrgCapacity: capacity,
      selectedDate: new Date(),
      availability: undefined,
      reminderTime: undefined,
    };

    this.__updateSubject({ booking: newBooking });
    this.initialiseOrgSelected(true, 0);
  };

  setBookingTime = (datetime) => {
    const { booking } = this.subject.value;

    let newBooking = {
      ...booking,
      selectedSlot: datetime,
      reminderTime: undefined,
    };

    this.__updateSubject({ booking: newBooking });
  };

  __saveAppointmentProgress = (step) => {
    const { appointmentId } = this.subject.value;

    const request = this.__createAppointmentRequestData(step);

    return appointmentApi
      .updateAppointment(appointmentId, request)
      .then((value) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_SELECT, {});
        if (step === 'org-selector') {
          globalBloc.updateGlobalBloc({ orgSelected: true });
        }
        return value;
      })
      .catch((reason) => {
        globalBloc.updateGlobalBloc({ orgSelected: false });
        throw new Error(reason);
      });
  };

  __createAppointmentRequestData = (step) => {
    const { appointment, booking, getInLine, isAtLocation } = this.subject.value;
    const { walkinGetInLine } = globalBloc.subject.value;
    let selectedStart;

    if (getInLine) {
      selectedStart = booking.firstAvailableDate; //?--> sets time for getInLine <--//
    } else {
      selectedStart = this.extractSlotStartDate(booking);
    }

    let participants = [
      {
        role: 'O_SRV_PROV',
        identifier: {
          code: 'id',
          system: 'decoded/party/organisation',
          value: booking.selectedOrg || appointment.provider,
        },
      },
    ];

    if (booking.doctor) {
      participants.push({
        role: 'S_PROVIDER',
        identifier: {
          value: booking.doctor,
        },
      });
    }

    let requestData;

    if (isAtLocation && !walkinGetInLine) {
      requestData = {
        service: {
          code: {
            value: appointment.service,
          },
          channel: {
            value: appointment.type,
          },
        },
        slot: {
          intervalStart: selectedStart,
        },
        reminder: booking.reminderTime || 60,
        participants: participants,
      };
    } else if (step === 'org-selector') {
      requestData = {
        command: 'set_service_provider',
        participants: participants,
      };
    } else if (step === 'time-selector') {
      requestData = {
        command: 'update_slot',
        start: selectedStart,
      };
    }

    return requestData;
  };

  __createAppointmentWalkinRequestData = () => {
    const { appointment, booking } = this.subject.value;

    let participants = [
      {
        role: 'O_SRV_PROV',
        identifier: {
          value: booking.selectedOrg,
        },
      },
    ];

    if (booking.doctor) {
      participants.push({
        role: 'S_PROVIDER',
        identifier: {
          value: booking.doctor,
        },
      });
    }

    return {
      service: {
        code: {
          value: appointment.service,
        },
        channel: {
          value: appointment.type,
        },
      },
      reminder: booking.reminderTime || 60,
      participants: participants,
    };
  };

  extractSlotStartDate(booking) {
    //TODO might need to remove if statement
    if (booking.availability) {
      const interval = booking.availability.results
        .flatMap((_result) => _result.intervals)
        .filter((_interval) => {
          if (!_interval.availableSlots) return false;
          if (_interval.start === booking.selectedSlot) return true;
          const arr = _interval.availableSlots.filter(
            (_slot) => _slot.slotId === booking.selectedSlot,
          );
          return arr.length > 0;
        })[0];

      const slot = interval.availableSlots;

      const selectedStart = dateUtil.parseDate(interval.start);
      if (slot.length > 0) {
        selectedStart.setMinutes(slot[0].start.split(':')[1]);
      }
      return selectedStart;
    } else {
      return undefined;
    }
  }

  confirmAppointment = async () => {
    const { appointment } = this.subject.value;
    const { getInLine, booking } = globalBloc.subject.value;

    await appointmentApi
      .remoteConfirmAppointment(appointment.id)
      .then((response) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_CONFIRM_SELECT, {
          appointmentId: appointment.id,
        });
        this.events.next({
          type: BookingBlocEvent.BOOKING_CONFIRMED,
          data: {},
        });
        return response;
      })
      .catch((reason) => {
        globalBloc.updateGlobalBloc({
          orgSelected: false,
          booking: { appointmentId: booking.appointmentId },
        });
        this.__updateSubject({
          loadingData: false,
        });
        if (getInLine) {
          notificationService.error(
            'Unable to get in line. Please try again or make a reservation.',
          );
        } else {
          notificationService.error(
            'Reservation time slot is unavailable. Please try a different location or choose a another time.',
          );
        }
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_CONFIRM_SELECT, {
          status: 'error',
          message: `${reason}`,
        });
        throw new Error(reason);
      });
  };

  setSelectedOrg = (org, capacity, date) => {
    const { booking } = this.subject.value;

    let newBooking = { ...booking };
    newBooking.selectedOrg = org;
    newBooking.selectedOrgCapacity = capacity;
    newBooking.selectedDate = date || new Date();
    newBooking.availability = undefined;
    newBooking.reminderTime = undefined;

    analyticsEventLogger.log(
      AnalyticsEvent.BOOKING_APPOINTMENT_BOOKING_APPOINTMENT_ORGANISATION_LOADED_SELECT,
      { organisation: org, capacity: `${capacity}` },
    );

    this.__updateSubject({
      booking: newBooking,
    });
  };

  //? v===ADDED TO CLEAR ORG WHEN USER GOES BACK TO AVOID ERROR===v

  clearSelectedOrg = () => {
    const { booking } = this.subject.value;

    let newBooking = { ...booking };
    newBooking.selectedOrg = undefined;
    newBooking.selectedOrgCapacity = undefined;
    newBooking.selectedDate = undefined;
    newBooking.availability = undefined;
    newBooking.reminderTime = undefined;

    this.__updateSubject({
      booking: newBooking,
    });
  };

  lockWalkinDetails = (start) => {
    const { booking, appointmentId, appointment } = this.subject.value;

    const iso = start.toISOString();
    const selectedSlot = parse(iso, "yyyy-MM-dd'T'HH:mm:ss.SSSX", new Date());

    let newBooking = { ...booking };
    newBooking.selectedSlot = selectedSlot;
    newBooking.reminderTime = 60;
    newBooking.locked = false;
    newBooking.selectedOrg = appointment.provider;

    this.__updateSubject({
      booking: newBooking,
    });

    const request = this.__createAppointmentWalkinRequestData();
    request.command = 'update_details';

    return appointmentApi.command(appointmentId, request);
  };

  lockedWalkinDetails = (error) => {
    const { booking } = this.subject.value;

    let newBooking = { ...booking };
    newBooking.locked = true;
    newBooking.error = error;

    this.__updateSubject({ booking: newBooking });
  };

  confirmWalkin = () => {
    const { booking, loadingData, appointmentId } = this.subject.value;

    if (loadingData || !booking.locked) {
      return new Promise((resolve) => resolve('stop'));
    }

    if (!booking.selectedSlot || booking.error) {
      this.events.next({
        type: BookingBlocEvent.NAVIGATE_TO,
        data: { url: THANKYOU_ROUTE },
      });
      return new Promise((resolve) => resolve('stop'));
    }

    sessionStorage.setItem('appt', appointmentId);

    this.__updateSubject({
      loadingData: true,
    });

    return this.confirmAppointment()
      .then(() => appointmentApi.getAppointmentQueueStatus(appointmentId))
      .then((res) => {
        const { waitTime } = res.data;
        return waitTime > 60
          ? routeUtil.buildPostBookingConfirmationRouteWithAppointmentID(appointmentId, 'IN_PERSON')
          : routeUtil.buildBookingIdentityDocument(appointmentId);
      })
      .then((destination) => {
        this.events.next({
          type: BookingBlocEvent.NAVIGATE_TO,
          data: { url: destination },
        });
        return new Promise((resolve) => resolve('stop'));
      });
  };

  estimateQueueStats = () => {
    const { availableOrganisations, booking, appointment } = this.subject.value;

    const target = availableOrganisations.filter((org) => org.id === appointment.provider);

    if (target.length > 0) {
      const clinic = target[0];
      this.setSelectedDate(new Date());
      this.loadSelectedDayAvailability().finally(() => {
        this.events.next({
          type: BookingBlocEvent.WALKIN_DETAILS_LOADED,
          data: {},
        });
      });
    } else {
      notificationService.error(
        'An error occurred trying to lookup the clinics information. Please try refresh or contact the clinic directly.',
      );
    }
  };

  isWalkin = () => {
    const { isWalkin } = this.subject.value;
    return isWalkin;
  };

  isVirtual = () => {
    const { appointment } = this.subject.value;
    return appointment.type === 'VIRTUAL';
  };

  showWalkinInstructions = () => {
    const { appointment } = this.subject.value;
    this.events.next({
      type: BookingBlocEvent.NAVIGATE_TO,
      data: {
        url: routeUtil.buildAWalkinOnlyInstructions(appointment.provider),
      },
    });
  };

  appointment = () => {
    return this.subject.value.appointment;
  };

  service = () => 'INP-UC';
  walkInService = () => 'INW-UC';

  makeReservationAvailable = () => {};
}

export class BookingBlocEvent {
  static INITIALISED = 'INITIALISED';
  static SWITCH_BOOKING_TYPE = 'SWITCH_BOOKING_TYPE';
  static DOCTOR_SELECTED = 'DOCTOR_SELECTED';
  static DATE_SELECTED = 'DATE_SELECTED';
  static TIME_SELECTED = 'TIME_SELECTED';
  static REMINDER_SELECTED = 'REMINDER_SELECTED';
  static BOOKING_CONFIRMED = 'BOOKING_CONFIRMED';

  static WALKIN_DETAILS_LOADED = 'WALKIN_DETAILS_LOADED';

  static NAVIGATE_TO = 'NAVIGATE_TO';
}
