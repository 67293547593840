import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { FormattedMarkdown } from '@decodedhealth/react-library';

const OnBackDialog = (props) => {
  const { openBackDialog, handleClose, handleRedirect, styles } = props;
  return (
    <Dialog
      maxWidth='md'
      open={openBackDialog}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title' className={styles.dialogTitle}>
        <Typography variant='h4' component='p'>
          <FormattedMarkdown
            id='dialog.cancel.title'
            defaultMessage='Leaving Booking Process'
          />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant='body2' component='p'>
            <FormattedMarkdown
              id='dialog.cancel.content'
              defaultMessage='You are about to leave the booking process and lose any reservation being made.'
            />
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          type='button'
          variant='contained'
          color='secondary'
          className={styles.dialogButton}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={styles.dialogButton}
          onClick={handleRedirect}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OnBackDialog;
