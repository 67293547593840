import React from 'react';
import DecodedComponent from '../../shared/DecodedComponent';
import { providerStorage } from '../../../utils/provider.qs';
import { authService } from '../../../utils/auth';
import { Bloc } from './bloc';
import { globalBloc } from '../../global.bloc';
import PageContainer from '../../common/PageContainer';
import ScrollableContainer, { PageContent, PageFooter } from '../../common/ScrollableContainer';
import KioskWelcome from './KioskWelcome/KioskWelcome';
import { withTheme } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { Checkbox, FormControlLabel, Link } from '@mui/material';
import { Box, Grid, Typography } from '@mui/material';
import { H2TextTitleContent, H2TextTitleSecondaryContent } from '../../shared/Typography';
import { DecodedButton, DecodedSubmitButton } from '../../common/DecodedButton';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { FormattedMessage } from 'react-intl';
import { logger } from '../../../utils/logging';
import QRCode from 'react-qr-code';
import Button from '@mui/material/Button';
import LandingOrgSelector from '../../Login/components/LandingOrgSelector/LandingOrgSelector';
import { GridItem, styles } from './styles';
import { dateUtil } from '../../../utils/date';
import { getInLineUtil } from '../../../utils/getInLineUtils';

class Welcome extends DecodedComponent {
  constructor(props) {
    super(props);

    const queryParams = new URLSearchParams(props.location.search);

    const isKiosk = providerStorage.isKiosk();
    const isWalkin = queryParams.has('provider') || providerStorage.isWalkin();
    const isLoggedIn = authService.getUser();

    this.bloc = new Bloc({
      isKiosk: isKiosk,
      isWalkin: isWalkin,
      isLoggedIn: isLoggedIn,
      showWalkin: isWalkin,
      onNext: this.props.handleNext,
      props: props,
    });

    this.state = {
      initialising: true,
      showAvailability: true,
      isWalkin: isWalkin,
      isKiosk: isKiosk,
      isLoggedIn: isLoggedIn,
    };
  }

  componentDidMount() {
    logger.debug('<Welcome/> mounted');
    super.componentDidMount();
    this.bloc.initialise();
    globalBloc.updateGlobalBloc({ kioskRestart: false });
  }

  getInLineOnClick = () => {
    if (getInLineUtil.isGetInLineQRLocation()) {
      globalBloc.updateGlobalBloc({ walkinGetInLine: true });
    }
    this.bloc.unsetCheckin();
    this.props.handleNext();
    if (this.state.firstWalkinSlot) {
      this.bloc.showWalkinTime();
    } else {
      this.bloc.hideWalkin();
    }
  };

  checkInOnClick = () => {
    this.bloc.setCheckin();
    this.props.handleNext();
  };

  render() {
    let {
      classes,
      acceptLocationTerm,
      handleNext,
      handleCheckboxChange,
      loading,
      location,
      theme,
    } = this.props;

    let {
      isWalkin,
      isKiosk,
      isLoggedIn,
      initialising,
      showAvailability,
      showWalkin,
      showWalkinTime,
      firstWalkinSlot,
      organisations,
    } = this.state;

    const queryParams = new URLSearchParams(location.search);

    const showCheckbox = !isKiosk && (location.search === '' || !queryParams.has('provider'));

    if (initialising) {
      return (
        <PageContainer isEnd={true} loading={true}>
          <ScrollableContainer applyFormat={true}></ScrollableContainer>
        </PageContainer>
      );
    }

    if (isKiosk) {
      return (
        <>
          <KioskWelcome
            handleNext={handleNext}
            getInLineOnClick={this.getInLineOnClick}
            checkInOnClick={this.checkInOnClick}
            firstWalkinSlot={firstWalkinSlot}
          />
        </>
      );
    }
    if (showWalkin) {
      return (
        <PageContainer isEnd={true} loading={false}>
          <ScrollableContainer applyFormat={true}>
            {this.__renderWalkinSelection(firstWalkinSlot, isKiosk)}
          </ScrollableContainer>
        </PageContainer>
      );
    }

    if (showWalkinTime) {
      return (
        <PageContainer isEnd={true} loading={false} onBack={() => this.bloc.showWalkin()}>
          <ScrollableContainer applyFormat={true}>
            {this.__renderWalkinTime(firstWalkinSlot)}
          </ScrollableContainer>
        </PageContainer>
      );
    }

    if (showAvailability && organisations.length !== 0) {
      return (
        <PageContainer
          isEnd={true}
          loading={false}
          onBack={
            !isWalkin
              ? undefined
              : () => {
                  this.bloc.showWalkin();
                }
          }
        >
          {this.__renderMap(organisations)}
        </PageContainer>
      );
    }
    return (
      <PageContainer isEnd={true} loading={loading}>
        <ScrollableContainer applyFormat={true}>
          <PageContent>
            <H2TextTitleContent className={isKiosk ? classes.kioskOpening : classes.opening}>
              <FormattedMarkdown
                id="welcome.bubble"
                defaultMessage="In just a few steps, we can help you find the medical care you need."
              />
            </H2TextTitleContent>
            {this.getStandardWelcome(classes)}
          </PageContent>
          <PageFooter>
            <ValidatorForm autoComplete="off" onSubmit={handleNext} className={classes.form}>
              {showCheckbox ? (
                <FormControlLabel
                  style={{ fontSize: '1.2em', textAlign: 'left' }}
                  control={
                    <Checkbox
                      required
                      checked={acceptLocationTerm}
                      onChange={handleCheckboxChange}
                      name="acceptLocationTerm"
                      color="primary"
                      className={classes.checkBox}
                    />
                  }
                  label={
                    <div className={classes.locationTerm}>
                      <FormattedMarkdown
                        id="welcome.location.confirmation"
                        defaultMessage="Please confirm that you will be physically located in the state of California at the time of your visit."
                      />
                    </div>
                  }
                />
              ) : null}

              <div className={classes.button}>
                <DecodedSubmitButton id={'lets-start-btn'}>Let's start!</DecodedSubmitButton>
              </div>

              <div className={classes.warning}>
                <Typography
                  variant="body1"
                  component={'div'}
                  sx={{
                    color: theme.palette.accent.main,
                  }}
                >
                  <FormattedMarkdown
                    id="global.instruction.emergency"
                    defaultMessage="In case of a medical emergency, call 911 immediately."
                  />
                </Typography>
              </div>
            </ValidatorForm>
          </PageFooter>
        </ScrollableContainer>
      </PageContainer>
    );
  }

  __renderWalkinSelection = (firstWalkinSlot, isKiosk) => {
    const handleWalkinSelection = () => {
      if (getInLineUtil.isGetInLineQRLocation()) {
        globalBloc.updateGlobalBloc({ walkinGetInLine: true });
      }
      this.bloc.unsetCheckin();
      if (firstWalkinSlot) {
        this.bloc.showWalkinTime();
      } else {
        this.bloc.hideWalkin();
      }
    };

    return (
      <Grid sx={{ paddingBottom: '24px' }} spacing={2} container>
        <Grid item xs={12}>
          <DecodedButton
            sx={{ paddingBottom: '20px', paddingTop: '20px' }}
            onClick={() => {
              this.bloc.setCheckin();
              this.props.handleNext();
            }}
          >
            Already Reserved Your Place Online?
            <br />
            CLICK HERE to CHECK IN.
          </DecodedButton>
        </Grid>
        <Grid item xs={12}>
          <DecodedButton
            sx={{ paddingBottom: '20px', paddingTop: '20px' }}
            onClick={handleWalkinSelection}
          >
            Walk-In Patients
            <br />
            CLICK HERE to GET IN LINE
          </DecodedButton>
        </Grid>
        <H2TextTitleSecondaryContent component={GridItem}>
          <FormattedMarkdown
            id="interaction.appointment.walkin.selection.alert"
            defaultMessage={`**If you are experiencing any of the following please see the Front Desk:**\n\n * **Breathing/Airway Distress**\n * **Current Chest Pain**\n * **Concern for Stroke**\n * **Active Bleeding**\n * **Severe Pain**\n * **Broken Bone or Fracture Concern**\n * **Seizure**\n * **Unable to Walk**\n`}
          />
        </H2TextTitleSecondaryContent>
        {isKiosk && (
          <Grid item xs={12}>
            <QRCode
              size={200}
              value={`${window.location.protocol}//${
                window.location.host
              }/?provider=${providerStorage.getCurrentProvider()}`}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  __renderWalkinTime = (firstWalkinSlot) => {
    const { walkinGetInLine } = globalBloc.subject.value;

    const waitTime = parseInt(sessionStorage.getItem('waitTime'), 10);

    return (
      <Grid sx={{ paddingBottom: '24px' }} spacing={2} container>
        <H2TextTitleContent component={GridItem}>
          {walkinGetInLine ? (
            <FormattedMessage
              id="interaction.appointment.get_in_line.wait"
              defaultMessage="The approximate wait time is: {waitTime}"
              values={{ waitTime: dateUtil.formatWaitTimeDisplay(waitTime) }}
            >
              {(txt) => <Typography sx={{ fontWeight: 'bold' }}>{txt}</Typography>}
            </FormattedMessage>
          ) : (
            <FormattedMessage
              id={'interaction.appointment.walkin'}
              defaultMessage={'The next approximate available time is {day} at: {nextSlotTime}'}
              values={{
                nextSlotTime: firstWalkinSlot.slots[0].display,
                day: firstWalkinSlot.isTomorrow ? 'tomorrow' : 'today',
              }}
            >
              {(txt) => <Typography sx={{ fontWeight: 'bold' }}>{txt}</Typography>}
            </FormattedMessage>
          )}
        </H2TextTitleContent>
        <Grid item xs={12}>
          <DecodedButton
            onClick={() => {
              globalBloc.updateGlobalBloc({
                getInLine: true,
                walkinGetInLine: getInLineUtil.isGetInLineQRLocation(),
                booking: {
                  ...globalBloc.subject.value.booking,
                  organisation: { id: this.bloc.walkinLocation() },
                },
              });
              this.props.handleNext();
            }}
          >
            CLICK HERE to GET IN LINE
          </DecodedButton>
        </Grid>
        <H2TextTitleSecondaryContent component={GridItem}>
          <FormattedMarkdown
            id="interaction.appointment.walkin.full.message2"
            defaultMessage="**Please go to the Front Desk if you are experiencing any of the following symptoms including but not limited to Chest Pain, Shortness of Breath, possible Stroke have an open Wound or Laceration, or a possible Broken Bone.**"
          />
        </H2TextTitleSecondaryContent>
      </Grid>
    );
  };

  __renderMap = (organisations) => {
    const { isWalkin } = this.state;

    if (isWalkin) {
      return (
        <ScrollableContainer applyFormat={true}>
          <Grid sx={{ paddingBottom: '24px' }} spacing={2} container>
            <Grid item direction="column">
              <H2TextTitleContent component={GridItem}>
                <FormattedMarkdown
                  id="interaction.appointment.walkin.full.message1"
                  defaultMessage={`**We have reached our patient capacity for the today. We apologize for the inconvenience.**`}
                />
              </H2TextTitleContent>
              <H2TextTitleSecondaryContent component={GridItem}>
                <FormattedMarkdown
                  id="interaction.appointment.walkin.full.message2"
                  defaultMessage={`**Please go to the Front Desk if you are experiencing any of the following symptoms including but not limited to Chest Pain, Shortness of Breath, possible Stroke have an open Wound or Laceration, or a possible Broken Bone.**`}
                />
              </H2TextTitleSecondaryContent>
            </Grid>
            {!this.state.isKiosk && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={() => {
                    providerStorage.clearProviderOnly(); //TODO WHY REMOVE?
                    window.location = '/';
                  }}
                >
                  Click Here to View Availability at Our Other Clinics
                </Button>
              </Grid>
            )}
          </Grid>
        </ScrollableContainer>
      );
    } else {
      return (
        <>
          <LandingOrgSelector
            organisations={organisations}
            isSelected={true}
            handleNavigation={this.props.handleNavigation}
          />
        </>
      );
    }
  };

  getStandardWelcome(classes) {
    return (
      <>
        <div>
          <Typography variant="body2" component={'div'} className={classes.confirmation}>
            <FormattedMessage id="welcome.instruction.note" defaultMessage=" " />

            <FormattedMessage id="welcome.instruction.link.ref">
              {(refChunks) =>
                refChunks[0] !== '_' ? (
                  <Link
                    rel="noopener noreferrer"
                    target="_blank"
                    href={refChunks}
                    underline="hover"
                  >
                    <FormattedMessage id="welcome.instruction.link.text">
                      {(text) => (text[0] !== '_' ? text : null)}
                    </FormattedMessage>
                  </Link>
                ) : null
              }
            </FormattedMessage>

            <FormattedMessage id="welcome.instruction.note.cont">
              {(text) => (text[0] !== '_' ? text : null)}
            </FormattedMessage>
          </Typography>
        </div>

        <FormattedMessage
          id="welcome.instruction.guide"
          defaultMessage="The process to book an urgent care visit takes about 5 minutes:"
        >
          {(text) =>
            text[0] !== ' ' &&
            text[0] !== '_' && (
              <React.Fragment>
                <div className={classes.instructionList}>
                  <Typography variant="body2" component={'div'}>
                    {text[0]}
                  </Typography>
                </div>

                <FormattedMessage
                  id="welcome.instruction.one"
                  defaultMessage="Complete a basic registration."
                >
                  {(text) =>
                    text[0] !== ' ' &&
                    text[0] !== '_' && (
                      <div className={classes.list}>
                        <Typography variant="body2" component={'div'}>
                          <span className={classes.order}>1</span>
                          {text[0]}
                        </Typography>
                      </div>
                    )
                  }
                </FormattedMessage>

                <FormattedMessage
                  id="welcome.instruction.two"
                  defaultMessage="Tell me why you’d like to be seen."
                >
                  {(text) =>
                    text[0] !== ' ' &&
                    text[0] !== '_' && (
                      <div className={classes.list}>
                        <Typography variant="body2" component={'div'}>
                          <span className={classes.order}>2</span>
                          {text[0]}
                        </Typography>
                      </div>
                    )
                  }
                </FormattedMessage>

                <FormattedMessage
                  id="welcome.instruction.three"
                  defaultMessage="Choose a visit location and time."
                >
                  {(text) =>
                    text[0] !== ' ' &&
                    text[0] !== '_' && (
                      <div className={classes.list}>
                        <Typography variant="body2" component={'div'}>
                          <span className={classes.order}>3</span>
                          {text[0]}
                        </Typography>
                      </div>
                    )
                  }
                </FormattedMessage>
              </React.Fragment>
            )
          }
        </FormattedMessage>
      </>
    );
  }
}

export default withTheme(withStyles(styles)(withRouter(Welcome)));
