import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
} from '@mui/material';

const RestartDialog = (props) => {
  const { logoutDialogOpen, handleLogout, cancelLogoutDialog, message= "Are you sure you want to end the Check-In process and Log Out?" } = props;
  return (
    <Dialog
      open={logoutDialogOpen}
      onClose={cancelLogoutDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {message}
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleLogout} variant="contained" color="error" autoFocus>
          YES
        </Button>
        <Button onClick={cancelLogoutDialog} variant="outlined" color="primary" autoFocus>
          NO
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RestartDialog;
