import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { BOOKING_LIST_ROUTE, routeUtil } from '../../../utils/route.name';
import AppointmentCancelDialog from '../../shared/AppointmentCancelDialog';
import { CancellationBloc } from '../../shared/AppointmentCancelDialog/cancellation.bloc';
import PageContainer from '../../common/PageContainer';
import { appointmentApi } from '../../../utils/services/appointments.api';
import DecodedButton from "../../common/DecodedButton";
import {
    DefaultDisconnectedPageFooter,
    ScrollableQuinnContainer
} from "../../common/ScrollableContainer";
import {AppointmentCard} from "../../shared/AppointmentCard";
import {notificationService} from "../../../utils/notification";
import {dateUtil} from "../../../utils/date";
import {serviceUtil} from "../../../utils/service";
import {appointmentUtil} from "../../../utils/appointment";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
}));

const BookingDetail = (props) => {
  const classes = useStyles();
  const { appointmentId, appointmentType } = props.match.params;
  const cancelAppointmentRef = React.useRef(new CancellationBloc());
  const [appointment, setAppointment] = React.useState(null);
  const [providerLookup, setProviderLookup] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    appointmentApi.getAvailableProviders().then(
        (response) => {
          let providerLookup = {};
          response.data.items.forEach((_item) => (providerLookup[_item.id] = _item));
          setProviderLookup(providerLookup);
          appointmentApi.getAppointmentStatus(appointmentId).then((res) => {
                const appointment = res.data;

                if(serviceUtil.isGetInLine(appointment.service.code)) {
                  appointmentApi.getAppointmentQueueStatus(appointmentId).then(res => {
                    appointmentUtil.addGetInLineInformation(appointment, appointment.slot.start, res.data);
                    setAppointment(appointment);
                    setLoading(false);
                  })
                } else {
                  appointment.originalStartTime = appointment.slot.start;
                  setAppointment(appointment);
                  setLoading(false);
                }
        },
        (reason) => {
          notificationService.httpError(reason);
        },
    );

    });
  }, [appointmentId]);

  const assistantMessage = loading
    ? {
        id: 'booking.detail.bubble.loading',
        text: 'We are looking for your reservation detail.',
      }
    : {
        id: 'booking.detail.bubble.success',
        text: 'How would you like to change your reservation.',
      };

  return (
    <PageContainer
      loading={loading}
      onBack={() => {
        props.history.push(BOOKING_LIST_ROUTE);
      }}
    >
      <ScrollableQuinnContainer
          size={'large'}
          messageId={assistantMessage.id}
          message={assistantMessage.text}
      >
      {appointment &&
          <AppointmentCard
              appointment={appointment}
              organisation={providerLookup[appointment.provider]}
              visitTime={dateUtil.fullFriendlyOutputExcludeYear(new Date(appointment.start))}
          />
      }

      <AppointmentCancelDialog
        selectedAppointment={appointmentId}
        bloc={cancelAppointmentRef.current}
        onCancelSuccess={() => {
          props.history.push(BOOKING_LIST_ROUTE);
        }}
      />
      </ScrollableQuinnContainer>

    <DefaultDisconnectedPageFooter alignItems={"left"} justifyContent={"left"}>
        <Grid container item justifyContent="center" xs={12} sm={6}>
            <DecodedButton onClick={() => {
                cancelAppointmentRef.current.setCancelDialogOpen(true);
            }}>Cancel</DecodedButton>
        </Grid>
    </DefaultDisconnectedPageFooter>

    </PageContainer>
  );
};

export default BookingDetail;
