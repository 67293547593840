import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  styled,
  useTheme,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import { AnalyticsEvent, analyticsEventLogger } from "../../../utils/events";
import {
  TextLargeStandard,
  TextMediumStandard,
  TextSmallStandard,
} from "../../shared/Typography";
import { dateUtil } from "../../../utils/date";
import { appointmentIntervalUtil } from "../../../utils/appointment";
import { providerUtil } from "../../../utils/provider";
import DecodedButton from "../../common/DecodedButton";
import { Loading, useWizardContext } from "../../../shared-library";

const StyledCard = styled(Card)(({ theme, isSelected }) => ({
  boxShadow: isSelected
    ? "0px 0px 5px 2px rgba(0, 128, 128, 0.5)"
    : "0px 1px 3px 1px rgba(0, 0, 0, 0.3)",
  width: "100%",
  minWidth: "100%",
  maxWidth: "100%",
  minHeight: "230px",
  margin: "0",
  backgroundColor: "#FFFFFF",
  borderRadius: "6px",
  cursor: "auto",
}));

const LoadingCard = () => {
  return (
    <Grid
      container
      item
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        minHeight: "230px",
      }}
    >
      <Loading message="loading location data" primary />
    </Grid>
  );
};

const GetInLineCard = (props) => {
  const { organisation, bloc, isSelected, setSelectedOrg } = props;

  const theme = useTheme();

  const { goStepById } = useWizardContext();

  const [walkinSlot, setWalkinSlot] = useState("Loading...");
  const [firstSlot, setFirstSlot] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const now = new Date();
    const tomorrow = dateUtil.nextStartOfDay(now);

    const walkInService = organisation.getInLineEnabled
      ? bloc.walkInService()
      : bloc.service();
    bloc
      .loadAvailability(
        now,
        tomorrow,
        organisation.id,
        walkInService,
        undefined,
        true
      )
      .then((schedulingIntervals) => {
        let firstWalkinSlot =
          appointmentIntervalUtil.findFirstAvailableSlotForDay(
            schedulingIntervals,
            now
          );
        setFirstSlot(firstWalkinSlot);
        if (firstWalkinSlot) {
          setWalkinSlot(`AVAILABLE`);
          analyticsEventLogger.log(
            AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_SUCCESS,
            {
              schedule: `walkinavailable ${firstWalkinSlot.slots[0].display}`,
            }
          );
        } else {
          setWalkinSlot("UNAVAILABLE");
          analyticsEventLogger.log(
            AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_SUCCESS,
            {
              schedule: "walkinfull",
            }
          );
        }
      })
      .catch((reason) => {
        setWalkinSlot("Unable to load");
        analyticsEventLogger.log(
          AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_ERROR,
          {
            error: reason,
            schedule: "walkin",
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [organisation]);

  const waitTimeMessage = (waitTime) => {
    if (waitTime === 0) {
      return "No wait time. Come in now!";
    } else if (waitTime) {
      return `Estimated wait time: ${dateUtil.formatWaitTimeDisplay(waitTime)}`;
    } else {
      return "Wait time is not available at this time.";
    }
  };

  const handleGetInLineAction = (e) => {
    if (walkinSlot !== "UNAVAILABLE") {
      bloc.getInLineSelection(organisation.id, firstSlot.start);
    }
    goStepById("confirmation");
  };

  const orgMouseOver = () => {
    if (!isSelected) {
      setSelectedOrg(organisation.id);
    }
  };

  return (
    <StyledCard onMouseEnter={orgMouseOver} variant="outlined">
      {loading ? (
        <LoadingCard />
      ) : (
        <>
          <CardHeader
            title={
              <>
                <TextMediumStandard
                  sx={{
                    fontWeight: "800 !important",
                    lineHeight: "18px",
                    color: "#5C5D5F",
                  }}
                >
                  {organisation.name}
                </TextMediumStandard>
              </>
            }
          />
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              padding: "0 16px 16px 16px",
            }}
          >
            <Box
              mb={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                minHeight: "36px",
              }}
            >
              <LocationOnIcon
                sx={{
                  fontSize: "18px",
                  color: theme.palette.accent.main,
                }}
                fontSize={"large"}
                color={"primary"}
              />

              <TextSmallStandard
                sx={{
                  color: "#5C5D5F",
                  paddingLeft: "10px",
                }}
              >
                {providerUtil.formatOrganisationAddress(organisation)}
              </TextSmallStandard>
            </Box>
            <Box
              mb={2}
              flexGrow={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop: "-12px",
              }}
            >
              <QueryBuilderIcon
                sx={{
                  fontSize: "18px",
                  marginTop: "5px",
                  color: theme.palette.accent.main,
                }}
                fontSize={"large"}
                color={"primary"}
              />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: "10px",
                }}
              >
                {walkinSlot === "UNAVAILABLE" ? (
                  <>
                    <TextLargeStandard
                      style={{
                        paddingBottom: 0,
                        lineHeight: "26px",
                        color: "#5C5D5F",
                      }}
                    >
                      Get in line not available at the moment for this location.
                    </TextLargeStandard>
                  </>
                ) : (
                  <>
                    <TextLargeStandard
                      style={{
                        paddingBottom: 0,
                        lineHeight: "26px",
                        color: "#5C5D5F",
                      }}
                    >
                      Get in line wait time:
                    </TextLargeStandard>
                    <br />

                    <TextMediumStandard
                      style={{
                        fontStyle: "normal",
                        fontWeight: "bold",
                        marginTop: "-20px",
                        lineHeight: "24px",
                        color:
                          walkinSlot === "UNAVAILABLE" ||
                          walkinSlot === "Loading..."
                            ? "red"
                            : "#2D8088",
                      }}
                    >
                      {/* {waitTime || "Loading"} */}
                      {waitTimeMessage(firstSlot.waitTimes)}
                    </TextMediumStandard>
                  </>
                )}
              </Box>
            </Box>
            {
              <DecodedButton onClick={handleGetInLineAction}>
                GET IN LINE
              </DecodedButton>
            }
          </CardContent>
        </>
      )}
    </StyledCard>
  );
};

export default GetInLineCard;
