import React from 'react';
import { Grid } from '@mui/material';
import { TextMediumStandard } from '../../../../shared/Typography';

const ReservationAvailability = ({reservationSlot}) => {
  return (
      <Grid container gap={1}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TextMediumStandard
            sx={{
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: '24px',
              color: reservationSlot === 'UNAVAILABLE' ? 'grey' : '#2D8088',
            }}
          >
            NEXT RESERVATION: {reservationSlot || 'Loading'}
          </TextMediumStandard>
        </Grid>
      </Grid>
  );
};

export default ReservationAvailability;
