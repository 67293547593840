import { appointmentApi } from '../../../utils/services/appointments.api';
import { notificationService } from '../../../utils/notification';
import { DecodedBloc } from '../../shared/DecodedComponent/bloc';
import { setSession, uriStorage } from '../../../utils/storage';
import { authService } from '../../../utils/auth';
import { providerStorage } from '../../../utils/provider.qs';
import { dateUtil } from '../../../utils/date';
import { ErrorMessage } from '../../../utils/error.resolver';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { differenceInMinutes, isSameDay } from 'date-fns';
import { providerUtil } from '../../../utils/provider';
import { RESCHEDULED, routeUtil } from '../../../utils/route.name';
import { serviceUtil } from '../../../utils/service';
import { appointmentUtil } from '../../../utils/appointment';

export class Bloc extends DecodedBloc {
  constructor(appointmentId) {
    super({
      appointmentId: appointmentId,
    });

    authService.getIdTokenResult().then((result) => {
      let isFullLogin = result?.claims?.scope?.some((scope) => scope === '*');
      this.__initialise(appointmentId, isFullLogin);
    });

    this.getInLineServiceType = 'INW-UC';
  }

  __handleCompleteAppointment = () => {
    this.__updateSubject({
      loading: false,
    });

    authService.logout().then(() => {
      uriStorage.clearPath();
      providerStorage.clearProvider();
    });
  };

  __initialise = (appointmentId, isFullLogin) => {
    this.__updateSubject({
      loading: true,
      inClinic: providerStorage.isWalkin(),
      isFullLogin: isFullLogin,
    });

    this._fetchAppointmentData(appointmentId)
      .then((data) => this._handleAppointmentData(data, isFullLogin))
      .catch((error) => this._handleError(error, appointmentId));
  };

  _fetchAppointmentData = (appointmentId) => {
    return this._fetchAppointmentStatus(appointmentId).then((results) => {
      const data = { ...results };

      if (data.service === this.getInLineServiceType) {
        return this._fetchQueueStatus(appointmentId)
          .then((queueStatusData) => {
            data.queuePosition = queueStatusData;
            return data;
          })
          .catch((error) => {
            return data;
          });
      }

      return data;
    });
  };

  _fetchAppointmentStatus = (appointmentId) => {
    return appointmentApi.getAppointmentStatus(appointmentId).then((response) => response.data);
  };

  _fetchQueueStatus = (appointmentId) => {
    return appointmentApi
      .getAppointmentQueueStatus(appointmentId)
      .then((response) => response.data);
  };

  _handleAppointmentData = (data) => {
    analyticsEventLogger.log(AnalyticsEvent.BOOKING_STATUS_RETRIEVAL_SUCCESS);

    if (data && data.status === 'COMPLETE') {
      this.__handleCompleteAppointment();
    }

    const newState = this._calculateNewState(data);

    this.__updateSubject({
      ...newState,
    });

    if (data && data.provider) {
      this._getProviderLocation(data.provider);
    }
  };

  _calculateNewState = (appointmentData) => {
    const waitTimeInMinutes = appointmentData.queuePosition
      ? appointmentData.queuePosition.waitTime
      : 0;
    const startTime = appointmentData
      ? dateUtil.addMinutes(new Date(appointmentData.start), waitTimeInMinutes)
      : new Date();

    const newState = {
      checkinSuccess: appointmentData ? ['WAITING'].includes(appointmentData.status) : false,
      checkinAvailable: isSameDay(new Date(), startTime) && this.subject.value.inClinic,
      queueNumber: appointmentData ? appointmentData.waitingRoomSummary.numberInQueue : '_',
      waitTime: differenceInMinutes(startTime, new Date()),
      startTime: startTime,
      timezone: appointmentData ? appointmentData.timezone : '',
      loading: false,
    };

    if (appointmentData) {
      if (serviceUtil.isGetInLine(appointmentData.service)) {
        appointmentUtil.addGetInLineInformation(
          appointmentData,
          appointmentData.start,
          appointmentData.queuePosition,
        );
      } else {
        appointmentData.originalStartTime = appointmentData.start;
      }
      newState.appointment = appointmentData;
    }

    return newState;
  };

  _handleError = (error, appointmentId) => {
    analyticsEventLogger.log(AnalyticsEvent.BOOKING_STATUS_RETRIEVAL_ERROR, {
      appointmentId: appointmentId,
      reason: error,
    });

    this.__updateSubject({
      checkinSuccess: false,
      waitTime: 'unknown',
      queueNumber: '_',
      checkinAvailable: false,
      loading: false,
    });

    notificationService.error('Error checking in for your visit. ' + ErrorMessage.CALL_SUPPORT);
  };

  _getProviderLocation = (providerId) => {
    this.__updateSubject({
      loading: true,
    });
    appointmentApi
      .getAvailableProviders()
      .then((response) => {
        const providers = response.data.items.filter((place) => place.id === providerId);

        let providerDetails = providers.map((place) => ({
          lat: place.contactInformation.address.geoLocation.latitude,
          lng: place.contactInformation.address.geoLocation.longitude,
        }));

        if (providerDetails.length === 1) {
          this.__updateSubject({
            provider: providers[0],
            providerDetails: providerDetails[0],
            providerContactNumber: providerUtil.formatOrganisationContactNumber(providers[0]),
          });
        }
      })
      .catch((error) => {
        notificationService.error(
          'Error finding the clinic location. ' + ErrorMessage.CALL_SUPPORT,
        );
      })
      .finally(() => {
        this.__updateSubject({
          loading: false,
        });
      });
  };

  rescheduleDraft = () => {
    const { appointment } = this.subject.value;

    appointmentApi.getDraftAppointment(appointment.id).then(
      (res) => {
        const location = res.headers.location.split(':');
        const uuid = location[location.length - 1];
        const url = routeUtil.buildBookingRouteWithDraftAppointmentID(uuid, RESCHEDULED);
        setSession('appt', uuid);
        uriStorage.setCurrentPath(url);

        this.__publishEvent(BlocEvent.NAVIGATE_TO, {
          url: url,
        });
      },
      (reason) => {
        notificationService.error(
          'There was an error trying to reschedule your reservation. Please try again.',
        );
      },
    );
  };
}

export class BlocEvent {
  static INITIALISED = 'INITIALISED';
  static NAVIGATE_TO = 'NAVIGATE_TO';
}
