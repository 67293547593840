export const styles = (theme) => ({
  grid: {
    flex: '1',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  checkBox: {
    transform: 'scale(2)',
    marginLeft: '1em',
    marginRight: '1em',
  },
  button: {
    width: '100%',
    padding: '3em',
    textAlign: 'center',
  },
});
