import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import LinearProgress from "@mui/material/LinearProgress";
import { Grid, styled } from "@mui/material";
import BackButton from "./BackButton";
import {
  getLogoBasedOnTenant,
  getMobileLogoBasedOnTenant,
} from "../../utils/logo";
import { PortalContainer, useIsMobile } from "../../shared-library";
import { useMediaQueries } from "../../utils/hooks/useMediaQueries";
import RestartDialog from "../../shared-library/components/dialogs/RestartDialog";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { LOGIN_ROUTE } from "../../utils/route.name";
import { authService } from "../../utils/auth";

const transparentBackground = "rgba(255,255,255,0.7)";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    position: "fixed",
    top: "0px",
    width: "100%",
    zIndex: "1500",
  },
}))(LinearProgress);

const WrapperMobileToolGridItem = styled(Grid)(({ onBack }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: onBack ? "space-between" : "flex-end",
  alignContent: "center",
  padding: "1em",
  backgroundColor: transparentBackground,
}));

const WrapperDesktopToolGridItem = styled(Grid)(({ theme, onBack }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: onBack ? "space-between" : "flex-end",
  alignContent: "center",
  padding: "1em 3em",
  backgroundColor: transparentBackground,
  [theme.breakpoints.down("md")]: {
    paddingLeft: "3em",
    paddingRight: "3em",
  },
}));

const WrapperGrid = styled(Grid)(({ theme }) => ({
  flex: "1",
  marginRight: "auto",
  padding: "0em",
  marginLeft: "0em",
  maxWidth: "100vw",
  height: "inherit",
  [theme.breakpoints.down("md")]: {
    marginLeft: "0em",
  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: "0em",
    paddingBottom: "8px",
  },
  [theme.breakpoints.down("xs")]: {
    marginLeft: "0em",
    paddingBottom: "8px",
  },
}));

const WrapperGridItem = styled(Grid)(({ theme }) => ({
  padding: "0 0 0 0",
  width: "auto",
  [theme.breakpoints.down("md")]: {
    padding: "0 0 0 0",
  },
}));

const MobileToolbar = ({ onBack }) => {
  const mediaQuery = useMediaQueries();
  const history = useHistory();

  const [open, setOpen] = useState(false);

  const onLogoutClick = () => {
    if (window.location.pathname === LOGIN_ROUTE) {
      sessionStorage.clear();
      window.location.reload();
    } else {
      setOpen(true);
    }
  };

  const handleLogout = () => {
    sessionStorage.clear();
    authService.logout();
    setOpen(false);
    history.push(LOGIN_ROUTE);
    window.location.reload();
  };

  return (
    <WrapperMobileToolGridItem onBack={onBack}>
      {onBack && <BackButton onClick={onBack} />}
      <img
        src={getMobileLogoBasedOnTenant()}
        alt="Logo"
        style={{ width: mediaQuery.isSm ? "60px" : null, cursor: "pointer" }}
        onClick={onLogoutClick}
      />
      <RestartDialog
        logoutDialogOpen={open}
        handleLogout={handleLogout}
        cancelLogoutDialog={() => setOpen(false)}
        message="Are you sure you want to return home?"
      />
    </WrapperMobileToolGridItem>
  );
};

const DesktopToolbar = ({ onBack }) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const onLogoutClick = () => {
    if (window.location.pathname === LOGIN_ROUTE) {
      sessionStorage.clear();
      window.location.reload();
    } else {
      setOpen(true);
    }
  };

  const handleLogout = () => {
    sessionStorage.clear();
    authService.logout();
    setOpen(false);
    history.push(LOGIN_ROUTE);
    window.location.reload();
  };

  return (
    <WrapperDesktopToolGridItem onBack={onBack}>
      {onBack && <BackButton onClick={onBack} />}
      <img
        src={getLogoBasedOnTenant()}
        alt="Logo"
        onClick={onLogoutClick}
        style={{ cursor: "pointer" }}
      />
      <RestartDialog
        logoutDialogOpen={open}
        handleLogout={handleLogout}
        cancelLogoutDialog={() => setOpen(false)}
        message="Are you sure you want to return home?"
      />
    </WrapperDesktopToolGridItem>
  );
};

const PageToolbar = ({ onBack }) => {
  const isMobile = useIsMobile();

  return (
    <>
      {isMobile ? (
        <MobileToolbar onBack={onBack} />
      ) : (
        <DesktopToolbar onBack={onBack} />
      )}
    </>
  );
};

const PageContainer = (props) => {
  const { children, onBack, loading, hideBack } = props;

  if (hideBack) {
    return (
      <>
        <PortalContainer />
        <WrapperGrid container direction="column" justifyContent="flex-start">
          {loading && <BorderLinearProgress />}
          <PageToolbar onBack={onBack} />
          <WrapperGridItem item container direction="column" flex="1">
            {children}
          </WrapperGridItem>
        </WrapperGrid>
      </>
    );
  }

  return (
    <>
      <PortalContainer />
      <WrapperGrid container direction="column" justifyContent="flex-start">
        {loading && <BorderLinearProgress />}
        <PageToolbar onBack={onBack} />
        <WrapperGridItem item container direction="column" flex="1">
          {children}
        </WrapperGridItem>
      </WrapperGrid>
    </>
  );
};

export default PageContainer;
