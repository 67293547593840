import * as rxjs from 'rxjs';
import { appointmentApi } from '../../../utils/services/appointments.api';
import { notificationService } from '../../../utils/notification';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';

export class CancellationBloc {
  constructor() {
    this.subject = new rxjs.BehaviorSubject({
      cancelDialogOpen: false,
    });

    this.events = new rxjs.Subject();
  }

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  setCancelDialogOpen = (value) => {
    this._updateSubject({ cancelDialogOpen: value });
  };

  _updateSubject = (newProps) => {
    const _current = this.subject.value;

    this.subject.next({
      ..._current,
      ...newProps,
    });
  };

  cancelAppointment = (appointmentId, requestData) => {
    this.events.next({
      type: CancellationBlocBlocEvent.APPOINTMENT_CANCELLING,
    });

    appointmentApi
      .cancelAppointment(appointmentId, requestData)
      .then((response) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_CANCELLATION_SUCCESS, {
          appointmentId: appointmentId,
        });

        this.events.next({
          type: CancellationBlocBlocEvent.APPOINTMENT_CANCELLED_SUCCESS,
        });
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_CANCELLATION_ERROR, {
          appointmentId: appointmentId,
          reason: error,
        });

        this.events.next({
          type: CancellationBlocBlocEvent.APPOINTMENT_CANCELLED_ERROR,
        });

        notificationService.error(
          'We were unable to cancel your reservation, Please cancel by calling the clinic directly.',
        );
      });
  };
}

export class CancellationBlocBlocEvent {
  static APPOINTMENT_CANCELLING = 'APPOINTMENT_CANCELLING';
  static APPOINTMENT_CANCELLED_SUCCESS = 'APPOINTMENT_CANCELLED_SUCCESS';
  static APPOINTMENT_CANCELLED_ERROR = 'APPOINTMENT_CANCELLED_ERROR';
}
