import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { providerStorage } from '../../../utils/provider.qs';

const kiosk = providerStorage.isKiosk();

export const H1TextTitle = styled(Typography, { variant: 'h1' })(({ theme, sx }) => ({
  ...sx,
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '3.33rem !important',
  lineHeight: '32px',
}));

export const H1TextTitleContent = styled(Typography, { variant: 'h1' })(({ theme, sx }) => ({
  ...sx,
  color: '#273A3E',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '3.33rem !important',
  lineHeight: '42px',
}));

export const H1TextTitleSecondary = styled(Typography, { variant: 'h1' })(({ theme, sx }) => ({
  ...sx,
  color: theme.palette.secondary.main,
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '3.33rem !important',
  lineHeight: '42px',
}));

export const ThankYouText = styled(Typography, { variant: 'h1' })(({ theme, sx }) => ({
  color: '#2D8088',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '3.33rem !important',
  lineHeight: '42px',
  textAlign: 'center',
}));

export const StaffNotifiedText = styled(Typography, { variant: 'h1' })(({ theme, sx }) => ({
  color: '#2D8088',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '3.33rem !important',
  lineHeight: '42px',
  textAlign: 'center',
}));

export const H2TextTitle = styled(Typography, { variant: 'h2' })(({ theme, sx }) => ({
  ...sx,
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: kiosk ? '1.85rem' : '1.65rem !important',
  lineHeight: '32px',
}));

export const H2TextTitleContent = styled(Typography, { variant: 'h1' })(({ theme, sx }) => ({
  ...sx,
  color: '#273A3E',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: kiosk ? '1.8rem !important' : '1.6rem !important',
  lineHeight: '28px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'center',
    fontSize: '1.4em !important',
    lineHeight: '1.8em',
  },
}));

export const H2TextTitleSecondaryContent = styled(Typography, { variant: 'h1' })(
  ({ theme, sx }) => ({
    ...sx,
    color: theme.palette.secondary.main,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px !important',
    lineHeight: '28px',
  }),
);

export const H2TextTitleSecondary = styled(Typography, { variant: 'h2' })(({ theme, sx }) => ({
  ...sx,
  color: theme.palette.secondary.main,
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '20px !important',
  lineHeight: '32px',
}));

//? v===NEW OTP TYPOGRAPHY===v

export const H1TextStepTitle = styled(Typography, { variant: 'h1' })(({ theme, isMobile, sx }) => ({
  ...sx,
  color: '#273A3E',
  fontFamily: 'inherit',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: isMobile ? '30px !important' : '42px !important',
  lineHeight: '50px',
  textAlign: 'left',
}));

export const TextStepSecondary = styled(Typography, { variant: 'h1' })(
  ({ theme, isMobile, sx }) => ({
    ...sx,
    color: '#2D8088',
    fontFamily: 'inherit',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: isMobile ? '16px !important' : '18px !important',
    lineHeight: '32px',
    textAlign: 'left',
  }),
);

export const SmallButtonText = styled(Typography, { variant: 'h1' })(({ theme, isMobile, sx }) => ({
  ...sx,
  color: '#2D8088',
  fontFamily: 'inherit',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: isMobile ? '10px !important' : '16px !important',
  lineHeight: '32px',
  textAlign: 'left',
}));

export const TextStandard = styled(Typography)(({ theme, isMobile, sx }) => ({
  ...sx,
  lineHeight: '18px',
}));

export const TextSmallStandard = styled(Typography)(({ theme, isMobile, sx }) => ({
  ...sx,
  fontSize: '12px !important',
  lineHeight: '18px',
}));

export const TextMediumStandard = styled(Typography)(({ theme, isMobile, sx }) => ({
  ...sx,
  fontSize: '16px !important',
}));

export const TextLargeStandard = styled(Typography)(({ theme, isMobile, sx }) => ({
  ...sx,
  fontSize: '18px !important',
}));

export const TextChatBubble = styled(Typography, { variant: 'body2' })(
  ({ theme, sx, sm, primary, error }) => ({
    fontSize: '1.7rem !important',
    ...sx,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'center',
      fontSize: '1.6em !important',
      lineHeight: '1.4em',
    },
    color: error ? theme.palette.error.main : primary ? theme.palette.primary.main : undefined,
  }),
);

export const TextAlternate = styled(Typography, { variant: 'body2' })(
  ({ theme, sx, sm, primary, error }) => ({
    fontSize: '1.7rem !important',
    ...sx,
    ...(sm && {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'center',
      },
    }),
    color: error ? theme.palette.error.main : primary ? theme.palette.primary.main : undefined,
  }),
);

export const TextH5Standard = styled(Typography, { variant: 'h5' })(
  ({ theme, orange, secondary, sx }) => ({
    ...sx,
    color: orange ? '#FF7700' : secondary ? theme.palette.secondary.main : undefined,
  }),
);

export const TextBody1Standard = styled(Typography, { variant: 'body1' })(
  ({ theme, orange, secondary, sx, md }) => ({
    ...sx,
    color: orange ? '#FF7700' : secondary ? theme.palette.secondary.main : undefined,
    fontSize: '2.2em !important',
    ...(md && {
      [theme.breakpoints.down('md')]: {
        fontSize: '1.8em',
        textAlign: 'center',
      },
    }),
  }),
);

export const TextBody2Standard = styled(Typography, { variant: 'body2' })(
  ({ theme, primary, secondary, md, sx }) => ({
    ...sx,
    color: primary
      ? theme.palette.primary.main
      : secondary
      ? theme.palette.secondary.main
      : undefined,
    fontSize: '1.7rem !important',
    ...(md && {
      [theme.breakpoints.down('md')]: {
        fontSize: '1.8em',
        textAlign: 'center',
      },
    }),
  }),
);

export const SpanText = styled('span')(({ theme, primary, secondary, md, sx }) => ({
  ...sx,
  color: primary ? theme.palette.primary.main : secondary ? theme.palette.secondary.main : null,
}));
