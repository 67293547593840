import { DecodedBloc } from '../shared/DecodedComponent/bloc';
import { appointmentApi } from '../../utils/services/appointments.api';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';
import { notificationService } from '../../utils/notification';
import { compareAsc, format, parse } from 'date-fns';
import { DATE_FORMAT } from '../../utils/user';
import { QUINN_SCHEDULED, routeUtil } from '../../utils/route.name';
import { logger } from '../../utils/logging';

export class Bloc extends DecodedBloc {
  appointmentApi;

  constructor(props) {
    super({ ...props });
    this.appointmentApi = props.appointmentApi;
  }

  initialise = () => {
    const { appointmentId, organisationId, isPreAuth } = this.subject.value;

    appointmentApi.getAppointmentStatus(appointmentId).then(
      (value) => {
        const appointment = value.data;

        this.__updateSubject({
          appointment: appointment,
        });

        if (organisationId) {
          this.__initialiseWalkin(appointment);
        } else if (isPreAuth) {
          this.__initialisePreAuthRemote(appointmentId);
        } else {
          this.__initialiseRemote(appointmentId); //TODO was appointment before might need to change back
        }
      },
      (reason) => {
        this.logAnalyticsEvent(AnalyticsEvent.QUINN_ROUTING, { error: reason });
        notificationService.error(
          'Unable to load available clinics. Please refresh to try again and if the problem persists please call the clinic.',
        );
      },
    );
  };

  __initialiseWalkin = (appointment) => {
    this.__walkinAvailable(appointment).then(
      (value) => {
        if (value) {
          this.__publishEvent(BlocEvent.NAVIGATE_TO, {
            url: routeUtil.buildBookingRouteWithDraftAppointmentID(appointment.id, QUINN_SCHEDULED),
          });
          this.logAnalyticsEvent(AnalyticsEvent.QUINN_ROUTING, { method: 'walkin' });
        } else {
          this.__publishEvent(BlocEvent.NAVIGATE_TO, {
            url: routeUtil.buildBookingRouteWithDraftAppointmentID(appointment.id, QUINN_SCHEDULED),
          });
          this.logAnalyticsEvent(AnalyticsEvent.QUINN_ROUTING, { method: 'walkintoremote' });
        }
      },
      (reason) => {
        this.__publishEvent(BlocEvent.NAVIGATE_TO, {
          url: routeUtil.buildBookingIdentityDocument(appointment.id),
        });
        this.logAnalyticsEvent(AnalyticsEvent.QUINN_ROUTING_ERROR, {
          method: 'walkin',
          error: reason,
        });
      },
    );
  };

  __initialiseRemote = (appointment) => {
    logger.debug('**__initialiseRemote**');
    this.__publishEvent(BlocEvent.NAVIGATE_TO, {
      url: routeUtil.buildBookingRouteWithDraftAppointmentID(appointment, QUINN_SCHEDULED),
    });
    this.logAnalyticsEvent(AnalyticsEvent.QUINN_ROUTING, { method: 'remote' });
  };

  __initialisePreAuthRemote = (appointment) => {
    logger.debug('**__initialise PRE AUTH Remote**');
    this.__publishEvent(BlocEvent.NAVIGATE_TO, {
      url: routeUtil.buildPreAuthBookingRouteWithDraftAppointmentID(appointment, QUINN_SCHEDULED),
    });
    this.logAnalyticsEvent(AnalyticsEvent.QUINN_ROUTING, { method: 'remote' });
  };

  __walkinAvailable = (appointment) => {
    const now = new Date();

    return appointmentApi
      .getAvailableAppointmentSchedule(
        now,
        appointment.provider,
        appointment.service,
        undefined,
        false,
      )
      .then(
        (value) => {
          let schedulingIntervals = this._constructScheduleData(
            value.data.results[0].intervals,
            now,
          ).sort((a, b) => compareAsc(a.parsedTime, b.parsedTime));

          let available = [];

          if (schedulingIntervals) {
            const start = new Date();
            start.setHours(0, 0, 0, 0);
            const end = new Date();
            end.setHours(23, 59, 59, 999);
            const now = new Date();
            end.setSeconds(0, 0);

            available = schedulingIntervals
              .filter((interval) => interval.start >= start && interval.start < end)
              .filter((interval) => interval.start >= now)
              .filter((interval) => interval.slots?.length > 0)
              .filter((interval) => interval.waitTimes < interval.capacity);
          }

          return available.length > 0;
        },
        (reason) => {
          this.logAnalyticsEvent(AnalyticsEvent.QUINN_ROUTING_ERROR, { error: reason });
          return true;
        },
      );
  };

  _constructScheduleData = (intervalData, selectedDate) => {
    const compareDate =
      typeof selectedDate === 'object' ? format(selectedDate, DATE_FORMAT) : selectedDate;

    const scheduleData = intervalData
      .map((interval) => {
        const scheduleIntervalHour = parse(interval.start, "yyyy-MM-dd'T'HH:mm:ssX", new Date());

        const hourDisplay = `${format(scheduleIntervalHour, 'h aa')}`;

        let slots = [];

        if (interval.availableSlots) {
          interval.availableSlots.forEach((_slot) => {
            slots.push({
              id: _slot.slotId,
              display: `${format(scheduleIntervalHour, 'hh')}:${_slot.start.split(':')[1]} ${format(
                scheduleIntervalHour,
                'aa',
              )}`,
              start: _slot.start,
            });
          });
        }

        const startDate = format(scheduleIntervalHour, DATE_FORMAT);

        return {
          start: scheduleIntervalHour,
          startDate: startDate,
          waitTimes: interval.current,
          capacity: interval.capacity,
          parsedTime: scheduleIntervalHour,
          hourDisplay: hourDisplay,
          slots: slots,
        };
      })
      .filter((interval) => {
        return interval.startDate === compareDate;
      });

    return scheduleData;
  };
}

export class Constants {}

export class BlocEvent {
  static NAVIGATE_TO = 'NAVIGATE_TO';
}
