import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  TextLargeStandard,
  TextMediumStandard,
} from "../../../../shared/Typography";
import DecodedButton from "../../../../common/DecodedButton";
import { dateUtil } from "../../../../../utils/date";
import { FormattedMessage } from "react-intl";
import { getInLineUtil } from "../../../../../utils/getInLineUtils";

const WalkInAvailabilityWrapper = ({ children }) => (
  <Grid container id="walk.in.availability">
    {children}
  </Grid>
);

const WalkInAvailability = (props) => {
  const {
    organisation,
    walkinSlot,
    getInLineAvailable = true,
    estimatedWaitTime,
  } = props;

  const getInLineEnabled = organisation.getInLineEnabled;
  const getInLineIsCutOff = getInLineUtil.isCutOff();

  const renderGetInLine = () => {
    return getInLineAvailable ? (
      estimatedWaitTime !== 0 ? (
        !getInLineIsCutOff && (
          <>
            <TextMediumStandard
              sx={{
                fontStyle: "normal",
                fontWeight: "bold",
                lineHeight: "24px",
              }}
            >
              ESTIMATED WAIT TIME: &nbsp;
              <span style={{ color: "#2D8088", fontSize: ".85em" }}>
                {walkinSlot === "CURRENTLY AVAILABLE"
                  ? dateUtil.formatWaitTimeDisplay(estimatedWaitTime)
                  : "Currently Unavailable."}
              </span>
            </TextMediumStandard>
          </>
        )
      ) : (
        <TextMediumStandard
          sx={{
            fontStyle: "normal",
            fontWeight: "bold",
            lineHeight: "24px",
          }}
          color="primary"
        >
          THERE IS CURRENTLY NO WAIT TO BE SEEN.
        </TextMediumStandard>
      )
    ) : (
      <FormattedMessage
        id={"landing.get_in_line.start"}
        values={{
          lineBreak: <br />,
          startTime: getInLineUtil.startTimeDisplay(),
        }}
        defaultMessage={
          "Virtual line will be available tomorrow at {startTime}"
        }
      >
        {(txt) => (
          <DecodedButton variant="contained" children={txt} disabled={true} />
        )}
      </FormattedMessage>
    );
  };

  return (
    <WalkInAvailabilityWrapper>
      <Grid item xs={12}>
        {getInLineEnabled ? (
          renderGetInLine()
        ) : (
          <TextMediumStandard
            sx={{
              fontStyle: "normal",
              fontWeight: "bold",
              lineHeight: "24px",
              color: walkinSlot === "UNAVAILABLE" ? "grey" : "#2D8088",
            }}
          >
            WALK-IN: {walkinSlot || "Loading"}
          </TextMediumStandard>
        )}
      </Grid>
    </WalkInAvailabilityWrapper>
  );
};

export default WalkInAvailability;
