import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Grid } from '@mui/material';
import { H1TextStepTitle, SmallButtonText } from '../../../shared/Typography';
import DecodedTextField from '../../../../utils/DecodedTextFIeld';
import InputMask from 'react-input-mask';
import { authService } from '../../../../utils/auth';
import { ROOT_ROUTE } from '../../../../utils/route.name';
import { AnalyticsEvent, analyticsEventLogger } from '../../../../utils/events';
import { errorResolver } from '../../../../utils/error.resolver';
import { notificationService } from '../../../../utils/notification';
import { LoginDataContext } from '../LoginDataProvider';
import { phoneUtil } from '../../../../utils/phone';
import { userInfoUtil } from '../../../../utils/user';
import { NextButton } from './NextButton';
import { useMediaQueries } from '../../../../utils/hooks/useMediaQueries';
import { TextButton } from '../../../shared/components/TextButton';
import { FormattedMessage } from 'react-intl';
import ScrollableContainer, {
  LoginDisconnectedPageFooter,
} from '../../../common/ScrollableContainer';
import PageContainer from '../../../common/PageContainer';
import { StyledPinCodeGrid } from './StyledGrid'

const PinCode = (props) => {
  const { code, codeCountry } = props;

  const [disablePin, setDisablePin] = useState(true);

  const { loginData, setLoginData } = useContext(LoginDataContext);

  const mediaQuery = useMediaQueries();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLoginData((prevState) => ({ ...prevState, [name]: value }));
    if (name === 'pin') {
      const processedPin = value.replace(/-/g, '');

      const isValidPin = /^[0-9]{6}$/.test(processedPin);
      setDisablePin(!isValidPin);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    doPinSubmit();
  };

  const createAuthRequest = (data) => {
    const processedPin = data.replace(/-/g, '');

    return {
      pin: processedPin,
      name: {
        given: loginData.firstName,
        family: loginData.lastName,
      },
      dob: userInfoUtil.formatDate(loginData.dateOfBirth),
      number: loginData.number
        ? phoneUtil.formatPhoneNumberForRegistration(
            loginData.number,
            props.code,
            props.codeCountry,
          )
        : null,
      email: loginData.email,
    };
  };

  const doPinSubmit = (data) => {
    let { setLoadingStatus } = props;

    authService
      .confirmVerificationCode(createAuthRequest(loginData.pin))
      .then((token) => {
        authService
          .loginWithToken(token)
          .then((user) => {
            analyticsEventLogger.log(AnalyticsEvent.AUTH_OTP_SUCCESS);

            setLoginData((prevData) => ({ ...prevData, verificationSuccess: true }));

            analyticsEventLogger.setUser(user.uid);
          })
          .catch((error) => {
            analyticsEventLogger.log(AnalyticsEvent.AUTH_OTP_ERROR, {
              reason: `${error}`,
            });
            notificationService.error(errorResolver.resolveAuthErrorDisplay(error));
            setLoadingStatus(false);
          });
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.AUTH_OTP_ERROR, {
          reason: `${error}`,
        });
        setLoadingStatus(false);
        notificationService.error(errorResolver.resolveAuthErrorDisplay(error));
      })
      .finally(() => {
        sessionStorage.removeItem('currentPath');
      });
  };

  if (loginData.verificationSuccess) {
    return (
      <Redirect
        to={{
          pathname: ROOT_ROUTE,
          state: {
            loginData,
            code,
            codeCountry,
            addressCountry: codeCountry,
          },
        }}
      />
    );
  }

  return (
    <PageContainer>
      <form onSubmit={onSubmit} style={{ display: 'contents' }}>
        <ScrollableContainer>
          <Grid container sx={{ padding: mediaQuery.isSm ? '20px' : '80px' }}>
            <Grid item>
              <FormattedMessage
                id="login.patient.otp"
                defaultMessage="Please enter the pin code texted to the mobile number provided"
              >
                {(txt) => (
                  <H1TextStepTitle isMobile={mediaQuery.isMobileLandscape || mediaQuery.isSm}>
                    {txt}
                  </H1TextStepTitle>
                )}
              </FormattedMessage>
            </Grid>
            <Grid
              container
              sx={{
                width: '75%',
                '@media (max-width:600px)': {
                  width: '100%',
                },
                display: mediaQuery.isMobileLandscape && 'flex',
                flexDirection: mediaQuery.isMobileLandscape && 'row',
                justifyContent: mediaQuery.isMobileLandscape && 'flex-start',
                alignItems: mediaQuery.isMobileLandscape && 'flex-end',
                flexWrap: mediaQuery.isMobileLandscape && 'nowrap',
              }}
            >
              <StyledPinCodeGrid item xs={12} sm={6} md={6} my="50px">
                <InputMask mask="9-9-9-9-9-9" alwaysShowMask onChange={handleInputChange}>
                  {(inputProps) => (
                    <DecodedTextField
                      {...inputProps}
                      pinCodeFont={true}
                      type="tel"
                      name="pin"
                      variant="outlined"
                      fullWidth
                      
                    />
                  )}
                </InputMask>
                <TextButton>
                  <SmallButtonText
                    onClick={() => props.setIndex((prevIndex) => (prevIndex - 1) % 7)}
                  >
                    Resend pin code?
                  </SmallButtonText>
                </TextButton>
              </StyledPinCodeGrid>
            </Grid>
          </Grid>
        </ScrollableContainer>
        <LoginDisconnectedPageFooter>
          <NextButton label="NEXT" disabled={disablePin} />
        </LoginDisconnectedPageFooter>
      </form>
    </PageContainer>
  );
};

export default PinCode;
