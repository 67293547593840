import { Grid } from '@mui/material';

export const GridItem = (props) => <Grid {...props} item />;

export const styles = (theme) => ({
  grid: {
    flex: '1',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textItem: {
    flex: '1',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    textAlign: 'left',
    paddingTop: '3em',
    width: '100%',
    flex: '1',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '2em',
    },
  },
  amount: {
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '5.6em',
    lineHeight: '1.214',
    color: '#0F0F0F',
    marginBottom: '0.44643em',
  },
  confirmation: {
    width: '100%',
    lineHeight: '1.75',
    color: '#575757',
    paddingTop: '0.5em',
    textAlign: 'center',
    marginBottom: '1em',
    fontWeight: '500',
    fontSize: '2em',
    [theme.breakpoints.down('sm')]: {
      fontSize: 'unset',
    },
  },
  opening: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    width: '100%',
    lineHeight: '1.75',
    paddingTop: '0.5em',
    textAlign: 'left',
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },
  kioskOpening: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    lineHeight: '1.75',
    paddingTop: '0.5em',
    textAlign: 'left',
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },
  instructionList: {
    width: '100%',
    lineHeight: '1.75',
    fontWeight: '500',
    color: '#575757',
    paddingTop: '2.8em',
    paddingBottom: '2.2em',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0em',
      paddingBottom: '0.5em',
    },
  },
  list: {
    width: '100%',
    lineHeight: '1.75',
    fontWeight: '500',
    color: '#575757',
    paddingTop: '0.5em',
    textAlign: 'left',
  },
  order: {
    fontSize: '1.5em',
    color: theme.palette.primary.main,
    paddingRight: '0.8em',
    fontWeight: '600',
  },
  warning: {
    width: '100%',
    lineHeight: '1.75',
    color: '#1A0C00',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  button: {
    width: '100%',
    paddingTop: '2.5em',
    paddingBottom: '2.5em',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      paddingTop: '1.5em',
      paddingBottom: '1.5em',
    },
  },
  checkBox: {
    transform: 'scale(1.5)',
    marginRight: '1em',
    marginLeft: '0.5em',
  },
  locationTerm: {
    color: theme.palette.primary.main,
  },
});
