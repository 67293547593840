import { CardHeader, Stack, styled, Typography } from "@mui/material";
import { providerUtil } from "../../../../../utils/provider";

const StyledOrgName = styled(Typography)({
  fontWeight: "800 !important",
  lineHeight: "18px",
  color: "#5C5D5F",
});

const StyledOrgAddress = styled(Typography)({
  fontSize: ".7em !important",
  color: "#5C5D5F",
});

const CardTitle = ({ organisation }) => {
  return (
    <CardHeader
      sx={{ padding: "1.5em 1.5em 0 1.5em" }}
      title={
        <Stack spacing={1}>
          <StyledOrgName variant="h5">{organisation.name}</StyledOrgName>
          <StyledOrgAddress>
            {providerUtil.formatOrganisationAddress(organisation)}
          </StyledOrgAddress>
        </Stack>
      }
    />
  );
};

export default CardTitle;
