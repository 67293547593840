import { appointmentIntervalUtil } from '../../../../utils/appointment';
import { dateUtil } from '../../../../utils/date';
import { AnalyticsEvent, analyticsEventLogger } from '../../../../utils/events';
import { appointmentApi } from '../../../../utils/services/appointments.api';
import { providerStorage } from '../../../../utils/provider.qs';
import { DecodedBloc } from '../../../shared/DecodedComponent/bloc';
import {
  ANY_SERVICE_CODE,
  DEFAULT_SERVICE_CODE,
  WALK_IN_SERVICE_CODE,
} from '../../../../utils/service';

export class LandingOrgSelectorBloc extends DecodedBloc {
  constructor(options) {
    super(options);

    this.__updateSubject({
      isWalkin: providerStorage.isWalkin(),
      now: new Date(),
      tomorrow: dateUtil.nextStartOfDay(new Date()),
      reservationSlot: 'Loading...',
      walkinSlot: 'Loading...',
      firstAvailableDate: new Date(),
      firstAvailableCapacity: 'not set',
      walkinFirstAvailableCapacity: 'not set',
      estimatedWaitTime: null,
      patientsInLine: null,
      getInLineAvailable: false,
      firstWalkinSlot: null,
      availability: null,
      loading: true,
    });
  }

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  //--> api call for walkin availability
  getInLineSchedule = (id) => {
    const { now, tomorrow } = this.subject.value;

    this.getInLineAvailability(now, tomorrow, id, this.walkInService(), undefined, true)
      .then((schedulingIntervals) => {
        this.__updateSubject({ availability: schedulingIntervals });

        let firstWalkinSlot = appointmentIntervalUtil.findFirstAvailableSlotForDay(
          schedulingIntervals.intervals,
          now,
        );

        if (firstWalkinSlot) {
          this.__updateSubject({
            estimatedWaitTime: schedulingIntervals.currentWaitTime,
            patientsInLine: schedulingIntervals?.nextPlaceInQueue - 1,
            getInLineAvailable: true,
            firstWalkinSlot: firstWalkinSlot,
          });

          this.__updateSubject({ walkinSlot: `CURRENTLY AVAILABLE` });

          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_SUCCESS, {
            schedule: `walkinavailable ${firstWalkinSlot.slots[0].display}`,
          });
        } else {
          this.__updateSubject({
            estimatedWaitTime: null,
            patientsInLine: null,
            getInLineAvailable: false,
            walkinSlot: 'UNAVAILABLE',
          });
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_SUCCESS, {
            schedule: 'walkinfull',
          });
        }
      })
      .catch((reason) => {
        this.__updateSubject({
          estimatedWaitTime: null,
          patientsInLine: null,
          getInLineAvailable: false,
          walkinSlot: 'Unable to load',
        });

        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_ERROR, {
          error: reason,
          schedule: 'walkin',
        });
      })
      .finally(() => {
        this.__updateSubject({ loading: false });
      });
  };

  getWalkInSchedule = (id) => {
    const { now, tomorrow } = this.subject.value;

    this.loadAvailability(now, tomorrow, id, ANY_SERVICE_CODE, undefined, true)
      .then((schedulingIntervals) => {
        // this.__updateSubject({ availability: schedulingIntervals });

        let firstWalkInSlot = appointmentIntervalUtil.findFirstAvailableSlotForDay(
          schedulingIntervals,
          now,
        );
        if (firstWalkInSlot) {
          this.__updateSubject({
            walkinSlot: `CURRENTLY AVAILABLE`,
            estimatedWaitTime: schedulingIntervals.currentWaitTime,
            getInLineAvailable: false,
            firstWalkinSlot: firstWalkInSlot,
          });

          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_SUCCESS, {
            schedule: `walkinavailable ${firstWalkInSlot.slots[0].display}`,
          });
        } else {
          this.__updateSubject({
            estimatedWaitTime: null,
            patientsInLine: null,
            getInLineAvailable: false,
            walkinSlot: 'UNAVAILABLE',
          });
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_SUCCESS, {
            schedule: 'walkinfull',
          });
        }
      })
      .catch((reason) => {
        this.__updateSubject({
          estimatedWaitTime: null,
          patientsInLine: null,
          getInLineAvailable: false,
          walkinSlot: 'Unable to load',
        });

        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_ERROR, {
          error: reason,
          schedule: 'walkin',
        });
      })
      .finally(() => {
        this.__updateSubject({ loading: false });
      });
  };

  //--> api call for non walkin availability
  reservationSchedule = (id) => {
    const { now, tomorrow } = this.subject.value;

    this.loadAvailability(now, tomorrow, id, this.service(), undefined, false)
      .then((schedulingIntervals) => {
        let firstSlot = appointmentIntervalUtil.findFirstAvailableSlotForDay(
          schedulingIntervals,
          now,
        );

        if (firstSlot) {
          this.makeReservationAvailable();
          this.__updateSubject({
            firstAvailableDate: now,
            firstAvailableCapacity: '',
            reservationSlot: `TODAY ${dateUtil.formatTimeDisplay(firstSlot.start)}`,
          });

          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_SUCCESS, {
            schedule: `${firstSlot.hourDisplay}`,
          });
        } else {
          firstSlot = appointmentIntervalUtil.findFirstAvailableSlotForDay(
            schedulingIntervals,
            tomorrow,
          );

          if (firstSlot) {
            this.makeReservationAvailable();
            this.__updateSubject({
              firstAvailableDate: tomorrow,
              firstAvailableCapacity: '',
              reservationSlot: `TOMORROW ${dateUtil.formatTimeDisplay(firstSlot.start)}`,
            });
            analyticsEventLogger.log(
              AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_SUCCESS,
              { schedule: `TOMORROW ${firstSlot.hourDisplay}` },
            );
          } else {
            this.__updateSubject({
              firstAvailableDate: tomorrow,
              firstAvailableCapacity: 'full',
              reservationSlot: `UNAVAILABLE`,
            });

            analyticsEventLogger.log(
              AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_SUCCESS,
              { schedule: 'full' },
            );
          }
        }
      })
      .catch((reason) => {
        this.__updateSubject({ reservationSlot: 'Unable to load' });
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_ERROR, {
          error: reason,
        });
        this.makeReservationAvailable();
      })
      .finally(() => {
        this.__updateSubject({ loading: false });
      });
  };

  service = () => DEFAULT_SERVICE_CODE;
  walkInService = () => WALK_IN_SERVICE_CODE;

  isWalkin = () => this.subject.value.isWalkin;

  loadAvailability = (start, end, organisationId, service, doctor, isWalkin) => {
    return appointmentIntervalUtil.loadAvailability(
      appointmentApi,
      start,
      end,
      organisationId,
      service,
      doctor,
      isWalkin,
    );
  };

  getInLineAvailability = (start, end, organisationId, service, doctor, isWalkin) => {
    return appointmentApi
      .getAvailableAppointmentScheduleBetweenAnonymous(
        start,
        end,
        organisationId,
        service,
        doctor,
        !isWalkin,
      )
      .then(
        (value) => {
          return value.data.results[0];
        },
        (reason) => {
          return reason;
        },
      );
  };

  makeReservationAvailable = () => {
    this.__updateSubject({ reservationAvailable: true });
  };

  hideAvailability = () => {
    this.__updateSubject({ showAvailability: false });
  };
}

export class LandingOrgCardBlocEvent {
  static INITIALISED = 'INITIALISED';

  static NAVIGATE_TO = 'NAVIGATE_TO';
}
