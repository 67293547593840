import * as React from "react";
import { Card, CardActions, CardHeader, Grid } from "@mui/material";
import { providerUtil } from "../../../utils/provider";
import { serviceUtil } from "../../../utils/service";
import {
  AddressText,
  YouAreInLineText,
  StyledCardContent,
  useStyles,
  AppointmentInfoText,
} from "./appointment-card-styles";
import { dateUtil } from "../../../utils/date";
import { logger } from "../../../utils/logging";

const GetInLineAppointmentCard = (props) => {
  const { appointment, organisation, controls } = props;

  const classes = useStyles();

  const serviceName = serviceUtil.friendlyNameSync(appointment.service);

  const appointmentType =
    appointment.type === "VIRTUAL"
      ? "Virtual"
      : providerUtil.formatOrganisationAddress(organisation);

  logger.info("appointment", appointment);

  const waitTimeMessage = () => {
    if (appointment.estimatedWaitTime === 0) {
      return "No wait time. Come in now!";
    } else if (appointment.estimatedWaitTime) {
      return `Estimated wait time: ${dateUtil.formatWaitTimeDisplay(
        appointment.estimatedWaitTime
      )}`;
    } else {
      return "Wait time is not available at this time.";
    }
  };

  const checkInTime = () => {
    if (!appointment.originalStartTime) {
      return "Check-in time is not available at this time. Please check back later or contact the clinic.";
    }
    
    return `Check-in: ${dateUtil.friendlyTimeDateDisplay(
      new Date(
        dateUtil.calculateAdjustedTime(
          new Date(appointment.originalStartTime),
          appointment.estimatedWaitTime
        )
      )
    )}`;
  };

  return (
    <Card className={classes.card} variant="outlined">
      <CardHeader
        classes={{
          title: classes.cardHeaderTitle,
          subheader: classes.cardHeaderTitleSubtitle,
        }}
        className={classes.cardHeader}
        title={serviceName}
        subheader={`at ${organisation?.name}`}
      />
      <StyledCardContent
        children={
          <Grid container gap={1}>
            <Grid item>
              <AddressText variant="body2">{appointmentType}</AddressText>
            </Grid>
            <Grid item>
              <YouAreInLineText>YOU ARE IN LINE.</YouAreInLineText>
            </Grid>
            <Grid item>
              <AppointmentInfoText>{checkInTime()}</AppointmentInfoText>
            </Grid>
            <Grid item>
              <AppointmentInfoText>{waitTimeMessage()}</AppointmentInfoText>
            </Grid>

            <Grid item>
              <AppointmentInfoText
                variant="body2"
                fontWeight="400 !important"
                color="error"
              >
                Please arrive by your check-in time to avoid losing your place
                in line.
              </AppointmentInfoText>
            </Grid>
          </Grid>
        }
      />

      {controls && <CardActions>{controls}</CardActions>}
    </Card>
  );
};

export default GetInLineAppointmentCard;
