import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { Redirect, Switch, withRouter } from 'react-router-dom';
import { authService } from '../../utils/auth';
import { PrivateRoute } from '../../utils/protected.route';
import { secureEventService } from './service/secure.event.service';
import IdleTimer from 'react-idle-timer';
import VirtualClinic from './VirtualClinic';
import Account from './Account';
import { checkSession, uriStorage } from '../../utils/storage';
import PaymentCheck from './PaymentCheck';
import {
  ACCOUNT_PAYMENT_METHOD_ROUTE,
  BOOKING_STATUS_ROUTE,
  BOOKING_CHECKIN_ROUTE,
  BOOKING_CONFIRMATION_ROUTE,
  BOOKING_DETAILS_ROUTE,
  BOOKING_LIST_ROUTE,
  BOOKING_ROUTE,
  QUINN_ROUTE,
  REGISTRATION_ROUTE,
  routeUtil,
  VIRTUAL_CLINIC_ROUTE,
  BOOKING_PAYMENT_METHODS_ROUTE,
  BOOKING_PAYMENT_ROUTE,
  USER_INFORMATION_ROUTE,
  BOOKING_IDENTITY_DOCUMENT,
  URGENTCARE_ROUTING_ROUTE,
  VIP_ROUTING_ROUTE,
  PATIENT_INFORMATION_ROUTE,
} from '../../utils/route.name';
import { providerStorage } from '../../utils/provider.qs';
import Loading from '../shared/Loading';
import Registration from '../Registration';
import { consumerApi } from '../../utils/services/consumers.api';
import BookingList from './BookingList';
import { globalBloc } from '../global.bloc';
import AssistantChat from './Assistant';
import BookingDetails from './BookingDetail';
import { Booking } from '../Booking/Booking';
import BookingPaymentMethod from '../BookingPaymentMethod';
import Payment from '../Payment';
import BookingStatus from './BookingStatus';
import BookingCheckin from './BookingCheckin';
import UserUpdate from './UserUpdate';
import IdentityDocument from './IdentityDocument';
import UrgentCareRouting from '../UrgentCareRouting';
import VipRouting from '../VipRouting';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';
import PatientInformation from '../PatientInformation';
import { logger } from '../../utils/logging';
import { addUserDataHeap } from '../../utils/heap/heapUtils';
import secureStyles from './secureStyles';
import { KIOSK_TIMEOUT, LEEWAY, TIMEOUT } from './utils';
import SessionExpiringDialog from '../../shared-library/components/dialogs/SessionExpiringDialog';

class Secure extends React.Component {
  subscription;

  constructor(props) {
    super(props);

    this.idleTimer = null;
    this.countDownTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.onCountdown = this._countdown.bind(this);
    this.onCancelIdleTimeout = this._cancelIdleTimeout.bind(this);
    this.idleTimeout = providerStorage.isKiosk() ? KIOSK_TIMEOUT : TIMEOUT;

    this.state = {
      loading: true,
      quinnChat: globalBloc.quinnChat(),
      countdownSeconds: LEEWAY,
      idle: false,
      open: false,
      manageAppointments: checkSession('action', 'manage-appointments'),
    };
  }

  componentDidMount() {
    logger.debug('<Secure/> mounted');

    this.subscription = secureEventService.registerStateCallback(this._handleEvent);

    // to keep booking process alive at refresh
    const currentPath = uriStorage.getCurrentPath();
    // if (currentPath?.trim().startsWith('/booking')) {
    //   this.props.history.push(currentPath);
    //   return;
    // }

    uriStorage.setUpStorage();

    consumerApi
      .getPersonSummary()
      .then((user) => {
        if (user?.data) {
          this._handleUserLogin(user.data);
        } else {
          this._handleUserNotFound();
        }
      })
      .catch(() => {
        this._handleUserNotFound();
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  _handleUserLogin(user) {
    const { history, location } = this.props;
    const { manageAppointments } = this.state;
    const { orgSelected, getInLine, walkinGetInLine } = globalBloc.subject.value;

    addUserDataHeap({
      id: user.id,
      dateOfBirth: user.dob,
      firstName: user.name.given,
    });

    if (sessionStorage.getItem('action') === 'registration.only') {
      this._handleRegistrationOnly();
    } else if (location.search.includes('preserve=true')) {
      this.setState({ loading: false });
    } else if (orgSelected || getInLine || walkinGetInLine) {
      history.replace(USER_INFORMATION_ROUTE);
    } else {
      history.replace(BOOKING_LIST_ROUTE);
    }
  }

  _handleUserNotFound() {
    const { history, location } = this.props;

    if (sessionStorage.getItem('action') === 'registration.only') {
      history.replace(PATIENT_INFORMATION_ROUTE);
    } else if (sessionStorage.getItem('checkin') === 'true') {
      history.replace(routeUtil.buildAppointmentCheckinException('unknown'));
    } else {
      history.replace(REGISTRATION_ROUTE, location.state);
    }
  }

  _handleRegistrationOnly() {
    const { history } = this.props;
    const registrationType = sessionStorage.getItem('registrationType');

    if (registrationType === 'PC' || registrationType === 'UC') {
      history.replace(PATIENT_INFORMATION_ROUTE);
    } else {
      history.replace(routeUtil.buildRegistrationOnlyResult('exception'));
    }
  }

  componentWillUnmount() {
    logger.debug('<Secure/> unmounted');

    if (this.countDownTimer) {
      clearInterval(this.countDownTimer);
      this.countDownTimer = undefined;
    }
    secureEventService.stopRemotePublishing();
    this.subscription.unsubscribe();
  }

  _onAction(e) {}

  _onActive(e) {}

  _onIdle(e) {
    if (!this.countDownTimer) {
      this.countDownTimer = setInterval(this.onCountdown, 1000);
    }
    this.setState({
      idle: true,
    });
    analyticsEventLogger.log(AnalyticsEvent.IDLE_SHOW, {});
  }

  _countdown = () => {
    const { countdownSeconds } = this.state;

    if (countdownSeconds < 0) {
      this.doLogout();
    } else {
      this.setState({
        countdownSeconds: countdownSeconds - 1,
      });
    }
  };

  _cancelIdleTimeout = () => {
    this.idleTimer.reset();
    clearInterval(this.countDownTimer);
    this.countDownTimer = undefined;
    this.setState({
      idle: false,
      countdownSeconds: LEEWAY,
    });
  };

  _handleEvent = (event) => {};

  doLogout = () => {
    authService.logout().then(() => {
      uriStorage.clearPath();
      providerStorage.clearProvider();
      window.location = '/';
    });
  };

  openLogoutDialog = () => {
    this.setState({ logoutDialogOpen: true });
  };

  cancelLogoutDialog = () => {
    this.setState({ logoutDialogOpen: false });
  };

  handleResize = () => {
    this.setState({ viewportWidth: window.innerWidth });
  };

  isPrimaryCareRegistrationOnly = () => {
    const url = window.location.href;
    // Extract the domain name
    const domain = new URL(url).hostname;
    return (
      process.env.REACT_APP_DH_PRIMARY_CARE_REGISTRATION_ONLY_DOMAIN &&
      process.env.REACT_APP_DH_PRIMARY_CARE_REGISTRATION_ONLY_DOMAIN.includes(domain)
    );
  };

  render() {
    const { classes } = this.props;

    const { idle, countdownSeconds, loading } = this.state;

    if (sessionStorage.getItem('currentProvider')) {
      if (loading) {
        return <Loading />;
      } else if (this.props.history.location.pathname === '/') {
        logger.info('Secure -> render -> /booking/list');
        return <Redirect to={BOOKING_LIST_ROUTE} />;
      }
    }

    let routes;
    if (this.isPrimaryCareRegistrationOnly()) {
      routes = (
        <>
          <PrivateRoute path={PATIENT_INFORMATION_ROUTE} component={PatientInformation} />
        </>
      );
    } else {
      routes = (
        <>
          <PrivateRoute path={URGENTCARE_ROUTING_ROUTE} component={UrgentCareRouting} />
          <PrivateRoute path={REGISTRATION_ROUTE} component={Registration} />
          <PrivateRoute path={PATIENT_INFORMATION_ROUTE} component={PatientInformation} />
          <PrivateRoute path={VIRTUAL_CLINIC_ROUTE} component={VirtualClinic} />
          <PrivateRoute path={QUINN_ROUTE} component={AssistantChat} />
          <PrivateRoute path={USER_INFORMATION_ROUTE} component={UserUpdate} />
          <PrivateRoute path={BOOKING_LIST_ROUTE} component={BookingList} />
          <PrivateRoute path={BOOKING_PAYMENT_METHODS_ROUTE} component={BookingPaymentMethod} />
          <PrivateRoute path={BOOKING_PAYMENT_ROUTE} component={Payment} />
          <PrivateRoute path={BOOKING_STATUS_ROUTE} component={BookingStatus} />
          <PrivateRoute path={BOOKING_CHECKIN_ROUTE} component={BookingCheckin} />
          <PrivateRoute path={BOOKING_CONFIRMATION_ROUTE} component={PaymentCheck} />
          <PrivateRoute path={BOOKING_ROUTE} component={Booking} />
          <PrivateRoute path={BOOKING_DETAILS_ROUTE} component={BookingDetails} />
          <PrivateRoute path={BOOKING_IDENTITY_DOCUMENT} component={IdentityDocument} />
          <PrivateRoute path={ACCOUNT_PAYMENT_METHOD_ROUTE} component={Account} />
        </>
      );
    }

    return (
      <div className={classes.root}>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * this.idleTimeout}
        />
        <Switch>{routes}</Switch>
        <SessionExpiringDialog
          idle={idle}
          onCancelIdleTimeout={this.onCancelIdleTimeout}
          countdownSeconds={countdownSeconds}
        />
      </div>
    );
  }
}

export default withStyles(secureStyles)(withRouter(Secure));
