import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import DecodedButton from "../../common/DecodedButton";
import { SpanText } from "../Typography";

const PreDialog = (props) => {
  const { showPreDialog, handleClosePreDialog } = props;

  const bulletPoints = [
    "Breathing/Airway Distress",
    "Current Chest Pain",
    "Concern for Stroke",
    "Active Bleeding",
    "Severe Pain",
    "Broken Bone or Fracture Concern",
    "Seizure",
    "Unable to Walk",
  ];

  return (
    <Dialog
      open={showPreDialog}
      onClose={handleClosePreDialog}
      data-testid="pre-dialog"
    >
      <DialogContent>
        <DialogContentText
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SpanText
            sx={{
              color: "red",
              fontWeight: "bold",
            }}
          >
            EMERGENCY PATIENTS
          </SpanText>
          <SpanText>
            At Duke City Urgent Care we can evaluate you for the following
            EMERGENT symptoms, if you are experiencing any of the symptoms
            listed below, please walk-in to any of our clinics and go directly
            to the Front desk. If you are experiencing any of these symptoms
            after-hours, please go to the closest emergency treatment center
            immediately.
          </SpanText>
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
            }}
          >
            {bulletPoints.map((item) => (
              <SpanText sx={{ fontWeight: "bold" }}>• {item}</SpanText>
            ))}
          </span>
          <SpanText>If none of these apply please continue.</SpanText>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <DecodedButton onClick={handleClosePreDialog} color="primary">
          Continue
        </DecodedButton>
      </DialogActions>
    </Dialog>
  );
};

export default PreDialog;
